import {
  IonButton,
  IonInput,
  IonItem,
  IonList,
  IonDatetime,
  IonDatetimeButton,
  IonModal,
  IonLabel,
  IonSelect,
  IonSelectOption,
  IonCheckbox,
  IonToggle,
} from "@ionic/react";
import React from "react";
import { useEvent } from "../../hooks/useEvent";

import { useHistory } from "react-router";
import { formatISO } from "date-fns";
import { Input } from "../ui/Input";
import { Button } from "../ui/Button";

const CreateEvent = () => {
  /* Hooks */
  const history = useHistory();
  const { updateEvent, event, eventInfo, hasSubmitted, validateEvent } =
    useEvent();

  /* Helpers */
  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    updateEvent({ key: name, value: value });
  };

  const handleDateChange = (key: any, e: CustomEvent) => {
    updateEvent({ key, value: e.detail.value });
  };

  const handleSelectChange = (key: any, e: CustomEvent) => {
    updateEvent({ key, value: e.detail.value });
  };

  const handleEnableAddressCheckboxChange = (event: any) => {
    const { checked } = event.detail;
    const name = event.target.name;

    if (name === "enable_address_date" && !checked) {
      updateEvent({
        key: "address_available_date_time",
        value: formatISO(new Date()),
      });
    }

    updateEvent({ key: name, value: checked });
  };

  const getInputType = (info: any, key: any, errorMessage: any) => {
    /* Datepicker */
    if ((info as any)?.[key].type === "date") {
      return (
        <div
          className={`bg-white-100 rounded-lg border border-black-10 pt-3 pb-5 px-3 w-full mb-4 ${
            (info as any)?.[key].className
          }`}
        >
          <IonLabel position="stacked">{(info as any)?.[key].label}</IonLabel>
          <div className="flex justify-start mt-1">
            <IonDatetimeButton
              datetime={`datetime-${(info as any)?.[key].label}`}
            ></IonDatetimeButton>
          </div>
          <IonModal keepContentsMounted={true}>
            <IonDatetime
              id={`datetime-${(info as any)?.[key].label}`}
              min={formatISO(new Date())}
              value={(info as any)?.[key].value}
              hourCycle="h12"
              onIonChange={(e: any) => handleDateChange(key, e)}
            >
              <span slot="title">Select address availaility</span>
            </IonDatetime>
          </IonModal>
          {errorMessage}
        </div>
      );
    } else if ((info as any)?.[key].type === "select") {
      /* Select */
      return (
        <div
          className={`bg-white-100 rounded-lg border border-black-10 !pt-2 !pb-2 px-3 w-full ${
            (info as any)?.[key].className
          }`}
        >
          <IonSelect
            aria-label={(info as any)?.[key]?.label}
            placeholder={(info as any)?.[key]?.label}
            value={(info as any)?.[key]?.value}
            label={(info as any)?.[key]?.label}
            labelPlacement="stacked"
            onIonChange={(e) => handleSelectChange(key, e)}
          >
            {(info as any)?.[key]?.options.map(
              (option: any, optionIndex: any) => (
                <IonSelectOption value={option.key} key={optionIndex}>
                  {option.value}
                </IonSelectOption>
              )
            )}
          </IonSelect>
          {errorMessage}
        </div>
      );
    } else if ((info as any)?.[key].type === "checkbox") {
      /* Toggle */
      return (
        <div
          className={`bg-white-100 rounded-lg border border-black-10 py-5 px-3 w-full mb-4 ${
            (info as any)?.[key].value ? "rounded-b-none" : ""
          }`}
        >
          <IonToggle
            justify="space-between"
            color="dark"
            name={key}
            checked={(info as any)?.[key].value}
            onIonChange={handleEnableAddressCheckboxChange}
            className="w-full"
          >
            {(info as any)?.[key]?.label}
          </IonToggle>
          {errorMessage}
        </div>
      );
    } else {
      /* Regular Input */
      return (
        <Input
          label={(info as any)?.[key]?.label}
          placeholder={(info as any)?.[key].label}
          value={(info as any)?.[key].value}
          name={key}
          onIonInput={handleInputChange}
          type={(info as any)?.[key].type}
          className={(info as any)?.[key].className}
          errorMessage={errorMessage}
        ></Input>
      );
    }
  };

  /* We only want to show the enable_address_before field if the checkbox is selected */
  return (
    <>
      {Object.keys(eventInfo).map((key: any, index: any) => (
        <div className="block" key={index}>
          {(key !== "address_available_date_time" ||
            eventInfo.enable_address_date.value) && (
            <>
              {getInputType(
                eventInfo,
                key,
                !(eventInfo as any)?.[key]?.valid &&
                  (eventInfo as any)?.[key]?.required &&
                  hasSubmitted && (
                    <div className="text-xs text-red-100">
                      Please enter a valid {(eventInfo as any)?.[key]?.label}
                    </div>
                  )
              )}
            </>
          )}
        </div>
      ))}
      <Button
        color="black"
        onClick={() => {
          const isValid = validateEvent();
          if (isValid) {
            history.push("/create/queue");
            ("/create/event");
          }
        }}
        text="Continue"
      />
    </>
  );
};

export default CreateEvent;
