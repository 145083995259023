import React from "react";
import { IonPage } from "@ionic/react";
import AccountScreen from "../../components/account/Account";

const Settings: React.FC = () => {
  return (
    <IonPage>
      <AccountScreen />
    </IonPage>
  );
};

export default Settings;
