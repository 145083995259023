import React from "react";
import { Capacitor } from "@capacitor/core";
import { IonContent, IonPage } from "@ionic/react";
import { TextCallout } from "../components/ui/TextCallout";
import { Button } from "../components/ui/Button";
import { useHistory } from "react-router";
import { useTranslation } from "../TranslationContext";

const isWeb = Capacitor.getPlatform() === "web";

const NotFound: React.FC = () => {
  /* Translations */
  const { t }: any = useTranslation();

  const history = useHistory();
  return (
    <IonPage>
      <IonContent fullscreen className="ion-padding-page">
        <div className="w-full h-full flex flex-col justify-center items-center">
          <TextCallout
            title={t("404.title")}
            description={t("404.description")}
            className="!bg-transparent text-center"
            titleClassName="!text-base"
          />
          <Button
            color="gray"
            text={t("404.button")}
            onClick={() => {
              if (isWeb) {
                window.location.href = process.env.REACT_APP_BASE_URL;
              } else {
                history.push("/");
              }
            }}
          />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default NotFound;
