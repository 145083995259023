import { supabase } from "../supabaseClient";

async function lookupAddressGeo(eventData: any) {
  const { data, error } = await supabase.functions.invoke(
    "maps/address-geo-lookup",
    {
      body: {
        address: `${eventData.address_line_1} ${eventData.address_line_2} ${eventData.city} ${eventData.state} ${eventData.postal_code}`,
      },
    }
  );

  if (error) {
    console.error("Error fetching user:", error);
    throw error;
  }

  return data;
}

async function createAuthUser(email: string, createdBy: string) {
  const { data, error } = await supabase.functions.invoke("createUser/email", {
    body: {
      email,
      createdBy,
    },
  });

  if (error) {
    console.error("Error creating user:", error);
    throw error;
  }

  return data;
}

export const edgeService = {
  lookupAddressGeo,
  createAuthUser,
};
