import { useEffect, useRef } from "react";
import { supabase } from "../../supabaseClient";
import { spotService } from "../../service/spotService";
import { useIonViewDidLeave } from "@ionic/react";

const useSpotChanges = (
  queueId: string,
  isCreatedByMe: boolean,
  setSpots: any
) => {
  const subscription = useRef<any>(null);

  useEffect(() => {
    if (queueId?.length && isCreatedByMe !== null) {
      subscription.current = supabase
        .channel("spot_changes")
        .on(
          "postgres_changes",
          {
            event: "INSERT",
            schema: "public",
            table: "spot",
            filter: `queue_id=eq.${queueId}`,
          },
          async (payload) => {
            let newSpot = payload?.new;

            /* If the queue was created by the user, fetch the attendee info*/
            if (isCreatedByMe) {
              const userRes = await spotService.getUserBySpot(
                newSpot?.purchased_by
              );
              newSpot = { ...newSpot, user: userRes };
            }

            setSpots((prevSpots: any) => {
              if (prevSpots.find((spot: any) => spot.id === newSpot?.id)) {
                return prevSpots;
              }
              return [...prevSpots, newSpot];
            });
          }
        )
        .on(
          "postgres_changes",
          {
            event: "UPDATE",
            schema: "public",
            table: "spot",
            filter: `queue_id=eq.${queueId}`,
          },
          (payload) => {
            const updatedSpot = payload?.new;
            setSpots((prevSpots: any) => {
              return prevSpots
                .map((spot: any) => {
                  if (spot.id === updatedSpot?.id) {
                    return updatedSpot;
                  }
                  return spot;
                })
                ?.filter((spot: any) => spot?.active === true);
            });
          }
        )
        .subscribe();
    }
  }, [queueId, isCreatedByMe]);

  useIonViewDidLeave(() => {
    subscription?.current?.unsubscribe();
  });
};

export default useSpotChanges;
