import React from "react";

type TextProps = {
  text: any;
  color: string;
  className?: string;
  size?: string;
};

export const Text = ({
  text,
  color,
  size = "base",
  className = "",
}: TextProps) => {
  const getDefaultColor = () => {
    const defaultColor = "text-black-100";

    switch (color) {
      case "black":
        return defaultColor;
      case "gray":
        return "text-black-60";
      case "green":
        return "text-green-100";
      default:
        return defaultColor;
    }
  };
  return (
    <div className={`text-${size} ${getDefaultColor()} ${className || ""}`}>
      {text}
    </div>
  );
};
