import {
  IonContent,
  IonFooter,
  IonIcon,
  IonSelect,
  IonSelectOption,
  useIonToast,
} from "@ionic/react";
import React, { useState } from "react";
import { useCompany } from "../../hooks/useCompany";
import { useAccount } from "../../hooks/useAccount";
import { useHistory } from "react-router";
import { Input } from "../ui/Input";
import { Header } from "../ui/Header";
import { Button } from "../ui/Button";
import { Text } from "../ui/Text";
import { Heading } from "../ui/Heading";
import { cashOutline, peopleOutline } from "ionicons/icons";

const Instructions: React.FC = () => {
  /* Hooks */
  const history = useHistory();

  /* Helpers */
  const handleSubmit = async () => {
    history.push("/events/my");
  };

  const Card = ({ title, description, icon, className }: any) => {
    return (
      <div
        className={`bg-white-100 rounded-2xl p-4 pt-5 pb-6 shadow-[0_1px_2px_-0px_rgba(0,0,0,0.1)] ${className}`}
      >
        <div className="grid grid-cols-[20px,auto] items-center gap-3">
          <IonIcon icon={icon} className="my-1.5 w-[20px] h-[20px]" />
          <Heading
            text={title}
            color="black"
            size="lg"
            className={`font-semibold text-base`}
          />
        </div>
        <div className="grid grid-cols-[20px,auto] items-center gap-3">
          <div></div>
          <Text text={description} color="gray" size={`sm`} />
        </div>
      </div>
    );
  };

  return (
    <>
      <Header showBackButton={true} progressPct={90} type="ONBOARDING" />
      <IonContent className="ion-padding-page">
        <Heading
          text="A few more things"
          color="black"
          size="lg"
          className={`text-center mb-4`}
        />
        {/* Managing Users */}
        <Card
          icon={peopleOutline}
          title="Managing Users"
          description="If you want to allow employees to manage your events, you can add them via email any time in your account settings page."
        />
        {/* Accepting Payments */}
        <Card
          icon={cashOutline}
          title="Accepting Payments"
          description="We partner with Stripe to accept payments. If you want to start charging customers, head to the account settings page after signing up to set up your Stripe account."
          className="mt-5"
        />
      </IonContent>
      <IonFooter>
        <div className="w-full text-black-100 flex justify-end items-center p-4 bg-appBg">
          <Button
            color="black"
            onClick={() => handleSubmit()}
            text="Get Started"
          />
        </div>
      </IonFooter>
    </>
  );
};

export default Instructions;
