import React from "react";
import { IonContent, IonPage } from "@ionic/react";
import CreateQueueForm from "../components/event/CreateQueue";
import { Header } from "../components/ui/Header";

const CreateQueue: React.FC = () => {
  return (
    <IonPage>
      <Header
        title="Create Sessions"
        showBackButton={true}
        backButtonHref="/create/event"
      />
      <IonContent fullscreen className="ion-padding-page">
        <CreateQueueForm />
      </IonContent>
    </IonPage>
  );
};

export default CreateQueue;
