import React from "react";
import { IonPage } from "@ionic/react";
import Info from "../components/onboarding/Info";

const OnboardingInfo: React.FC = () => {
  return (
    <IonPage>
      <Info />
    </IonPage>
  );
};

export default OnboardingInfo;
