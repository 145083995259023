import { IonContent, IonIcon, useIonViewDidLeave } from "@ionic/react";
import { Capacitor } from "@capacitor/core";
import { Browser } from "@capacitor/browser";
import React, { useEffect, useState } from "react";
import { useAccount } from "../../hooks/useAccount";
import { useCore } from "../../hooks/useCore";
import { useHistory } from "react-router";
import { userService } from "../../service/userService";
import { stripeService } from "../../service/stripeService";
import { useQuery } from "react-query";
import { Text } from "../ui/Text";
import { Button } from "../ui/Button";
import { Header } from "../ui/Header";
import { useAppSelector } from "../../redux/hooks";
import smile from "../../assets/smile.svg";
import { chevronForwardOutline, openOutline } from "ionicons/icons";
import { useTranslation } from "../../TranslationContext";
import { useCompany } from "../../hooks/useCompany";
import { formatPhoneNumber } from "../../utils";

const isWeb = Capacitor.getPlatform() === "web";

const Account: React.FC = () => {
  /* Translations */
  const { t }: any = useTranslation();

  /* Redux */
  const { user, activeCompany, userCompanies } = useAppSelector((state) => ({
    user: state.core.user,
    activeCompany: state.company.activeCompany,
    userCompanies: state.company.userCompanies,
  }));

  /* Hooks */
  const [createAccountLoading, setCreateAccountLoading] = useState(false);
  const [createLoginLoading, setCreateLoginLoading] = useState(false);
  const { resetCore } = useCore();
  const { accountInfo, session, fetchAccount } = useAccount();
  const { companyInfo } = useCompany();
  const history = useHistory();

  /* React Query */
  const {
    data: stripeAccountRes,
    refetch: refetchStripeAccountRes,
    isLoading: stripeAccountResLoading,
  } = useQuery(
    [session?.user?.id, accountInfo?.stripe_connect_id?.value],
    async () => {
      return await stripeService.getAccount(
        accountInfo?.stripe_connect_id?.value
      );
    },
    {
      enabled:
        session?.user?.id &&
        !!accountInfo?.stripe_connect_id?.value &&
        !user?.stripe_connect_setup_complete,
      staleTime: 1000 * 60 * 1,
      retry: 0,
    }
  );

  /*
    If they haven't finished, but already created a stripe account,
    just generate a new account link. Otherwise create a new account AND link 
  */
  const handleCreateStripeAccount = async () => {
    let createAccountRes;
    setCreateAccountLoading(true);

    if (accountInfo?.stripe_connect_id?.value) {
      createAccountRes = await stripeService.getAccountLink(
        accountInfo?.stripe_connect_id?.value
      );
    } else {
      createAccountRes = await stripeService.createAccount({
        email: accountInfo?.email?.value,
        userId: session?.user?.id,
        // We don't ask for business type (yet) so just commenting this out
        // individual: {
        //   first_name: accountInfo?.first_name?.value,
        //   last_name: accountInfo?.last_name?.value,
        //   phone: formatPhoneNumber(accountInfo?.phone?.value),
        //   address: {
        //     postal_code: accountInfo?.postal_code?.value,
        //   },
        // },
        // company: {
        //   name: companyInfo?.name?.value,
        //   phone: formatPhoneNumber(accountInfo?.phone?.value),
        //   address: {
        //     line1: companyInfo?.address_line_1?.value,
        //     line2: companyInfo?.address_line_2?.value,
        //     city: companyInfo?.city?.value,
        //     state: companyInfo?.state?.value,
        //     postal_code: companyInfo?.postal_code?.value,
        //   },
        // },
      });
    }

    if (createAccountRes?.stripeAccountLink?.url) {
      if (isWeb) {
        window.location.href = createAccountRes?.stripeAccountLink?.url;
      } else {
        await Browser.open({ url: createAccountRes?.stripeAccountLink?.url });
      }
    }
    setCreateAccountLoading(false);
  };

  const handleCreateStripeLogin = async () => {
    setCreateLoginLoading(true);
    // Open a new window immediately with a about:blank URL (otherwise safari will block it as a popup)
    let newWindow;
    if (Capacitor.getPlatform() !== "web") {
      newWindow = window.open("about:blank", "_blank");
    }

    const createLoginRes = await stripeService.generateConnectLoginLink(
      accountInfo?.stripe_connect_id?.value
    );
    if (createLoginRes?.url) {
      if (isWeb && newWindow) {
        newWindow.location.href = createLoginRes?.url;
      } else {
        await Browser.open({ url: createLoginRes?.url });
      }
    }
    setCreateLoginLoading(false);
  };

  /* 
    On page load, if the user has finished setting up stripe but it hasn't been marked as complete,
    then do so. 
  */
  useEffect(() => {
    if (
      stripeAccountRes?.payouts_enabled &&
      user?.stripe_connect_id &&
      !user?.stripe_connect_setup_complete
    ) {
      stripeService.confirmStripeSetup(user?.stripe_connect_id);
    }
  }, [stripeAccountRes, user, user?.stripe_connect_id]);

  /*
    For Native: handle browser events so we can refresh the data when
    the user is done with the browser flow
  */
  const [browserActive, setBrowserActive] = useState(null);
  useEffect(() => {
    if (!isWeb) {
      Browser.addListener("browserPageLoaded", async () => {
        setBrowserActive(true);
      });
      Browser.addListener("browserFinished", async () => {
        setBrowserActive(false);
      });
    }
  }, []);

  /* 
    The real use case here is if the user finishes their stripe setup.
    We'll want to refresh the button on browser close. 
  */
  useEffect(() => {
    if (browserActive === false && !isWeb) {
      setBrowserActive(null);
      if (!user?.stripe_connect_setup_complete) {
        fetchAccount();
      }
    }
  }, [browserActive]);

  useIonViewDidLeave(() => {
    Browser.removeAllListeners();
    setBrowserActive(null);
  });

  const signOut = async () => {
    const logoutRes = await userService.signUserOut();

    if (logoutRes.success) {
      resetCore();
    }
  };

  /* Button */
  const ButtonRow = ({
    index,
    length,
    title,
    description,
    icon,
    onClick,
  }: any) => {
    return (
      <div
        className={`${
          index < length - 1 ? "border-b border-b-black-10" : ""
        } py-4 flex justify-between items-center cursor-pointer`}
        onClick={onClick}
      >
        <div>
          <Text
            size="base"
            color="black"
            text={title}
            className="font-semibold"
          />
          <Text size="base" color="gray" text={description} />
        </div>
        <div className="ion-icon-color-override">
          <IonIcon icon={icon} />
        </div>
      </div>
    );
  };

  const menuItems = [
    {
      title: t("account.home.settingsTitle"),
      description: t("account.home.settingsDescription"),
      icon: chevronForwardOutline,
      onClick: () => history.push("/account/settings"),
    },
    ...(userCompanies?.length
      ? [
          {
            title: t("account.home.companyTitle"),
            description: t("account.home.companyDescription"),
            icon: chevronForwardOutline,
            onClick: () => history.push("/account/company"),
          },
          {
            title: t("account.home.usersTitle"),
            description: t("account.home.usersDescription"),
            icon: chevronForwardOutline,
            onClick: () => history.push("/account/manage-users"),
          },
          {
            title: t("account.home.payments.title"),
            description:
              stripeAccountResLoading ||
              (!accountInfo?.stripe_connect_id?.value &&
                !accountInfo?.email?.value)
                ? t("account.home.payments.loading")
                : stripeAccountRes?.payouts_enabled ||
                  user?.stripe_connect_setup_complete
                ? t("account.home.payments.viewDashboard")
                : createAccountLoading
                ? t("account.home.payments.loading")
                : accountInfo?.stripe_connect_id?.value
                ? t("account.home.payments.continue")
                : t("account.home.payments.start"),
            icon: openOutline,
            onClick:
              stripeAccountRes?.payouts_enabled ||
              user?.stripe_connect_setup_complete
                ? handleCreateStripeLogin
                : handleCreateStripeAccount,
            disabled: createLoginLoading || createAccountLoading,
          },
        ]
      : []),
    ...(!userCompanies?.length
      ? [
          {
            title: t("account.home.createCompanyTitle"),
            description: t("account.home.createCompanyDescription"),
            icon: chevronForwardOutline,
            onClick: () => history.push("/account/company"),
          },
        ]
      : []),
  ];

  return (
    <>
      <Header title={""} />
      <IonContent className="ion-padding-page">
        {/* Info */}
        <div className="flex flex-col items-center gap-2">
          <div className="bg-black-10 flex items-center justify-center h-20 w-20 rounded-full">
            <img src={smile} alt="smile" className="h-10 w-10 mt-1" />
          </div>
          <Text
            size="lg"
            color="black"
            text={`${user?.first_name} ${user?.last_name}`}
            className="font-semibold"
          />
          <Text
            size="sm"
            color="gray"
            text={`${user?.email}`}
            className="-mt-3"
          />
        </div>
        {/* Menu  */}
        <div className="my-4">
          {menuItems.map((item, index) => (
            <ButtonRow
              key={index}
              index={index}
              length={menuItems.length}
              title={item.title}
              description={item.description}
              icon={item.icon}
              onClick={(e: any) => {
                if (item.disabled) {
                  e.preventDefault();
                  return;
                }
                item.onClick();
              }}
            />
          ))}
        </div>
        <Button
          color="gray"
          onClick={signOut}
          text={t("account.home.signOut")}
          className="mt-2"
        />
      </IonContent>
    </>
  );
};

export default Account;
