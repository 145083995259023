import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  LinkAuthenticationElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { Button } from "../ui/Button";
import { useTranslation } from "../../TranslationContext";

export default function CheckoutForm({
  user,
  existingClientSecret,
  clientSecret,
  setIsPaymentModalOpen,
}: any) {
  /* Translations */
  const { t }: any = useTranslation();

  const stripe = useStripe();
  const elements = useElements();

  const [email, setEmail] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  /*
    Auto-fill these fields since we already have them on the user's profile 
  */
  useEffect(() => {
    if (user?.email || user?.postal_code) {
      setEmail(user.email);
      setPostalCode(user.postal_code);
    }
  }, [user?.email, user?.postal_code]);

  /*
    Handle showing payment status messages. The success one will probably never really show since we don't show
    the modal after the redirect, but it's here just in case.
  */
  useEffect(() => {
    if (!stripe) {
      return;
    }

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage(t("event.payment.success"));
          break;
        case "processing":
          setMessage(t("event.payment.processing"));
          break;
        case "requires_payment_method":
          // setMessage(t("event.payment.requiresPaymentMethod"));
          break;
        default:
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    /* Disable until stripe.js has loaded */
    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: window.location.href,
      },
    });

    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage(t("event.payment.unexpectedError"));
    }

    setIsLoading(false);
  };

  const paymentElementOptions: any = {
    layout: "tabs",
    defaultValues: {
      billingDetails: {
        email,
        address: {
          postal_code: postalCode,
        },
      },
    },
  };

  return existingClientSecret ? (
    <div>{message}</div>
  ) : (
    <form id="payment-form" onSubmit={handleSubmit} className="stripe-form">
      <LinkAuthenticationElement
        id="link-authentication-element"
        onChange={(e: any) => setEmail(e?.target?.value)}
      />
      <PaymentElement id="payment-element" options={paymentElementOptions} />
      <button
        disabled={isLoading || !stripe || !elements}
        id="submit"
        className={`stripe-submit mt-4 font-semibold py-5 cursor-pointer w-full inline-flex items-center bg-black-100 text-white-100 border-black-100 justify-center rounded-lg text-sm ${
          isLoading ? "opacity-50 cursor-not-allowed pointer-events-none	" : ""
        }`}
      >
        <span id="button-text">
          {isLoading ? (
            <div className="spinner" id="spinner"></div>
          ) : (
            t("event.payment.payNow")
          )}
        </span>
      </button>
      {!isLoading && (
        <Button
          color="gray"
          text={t("event.payment.cancel")}
          onClick={() => setIsPaymentModalOpen(false)}
          className="mt-3"
        />
      )}
      {/* Show any error or success messages */}
      {message && (
        <div id="payment-message" className="mt-4">
          {message}
        </div>
      )}
    </form>
  );
}
