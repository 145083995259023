export default {
  core: {
    qrCodeSaved: "Código QR guardado",
  },
  login: {
    invalidEmail:
      "Por favor, introduce una dirección de correo electrónico válida",
    invalidLoginCode:
      "Por favor, introduce un código de inicio de sesión de 6 dígitos válido",
    login: "Iniciar sesión",
    email: "Dirección de correo electrónico",
    emailPlaceholder: "nombre@ejemplo.com",
    sendingLoginCode: "Enviando código de inicio de sesión",
    loginWithEmailCode: "Iniciar sesión con código de correo electrónico",
    checkYourEmail: "Revisa tu correo electrónico",
    weSentCodeTo: "Enviamos un código a",
    yourCode: "Tu código",
    noCode: "¿No recibiste un código?",
    resendCode: "Haz clic aquí para intentarlo de nuevo",
    cancel: "Cancelar",
    verifying: "Verificando",
    verify: "Verificar",
    bySigningUp: "Al presionar verificar, aceptas nuestra",
    privacyPolicy: "Política de Privacidad",
    and: "y",
    termsOfService: "Términos y Condiciones",
  },
  404: {
    title: "Página no encontrada",
    description:
      "Haz clic en el botón de abajo para volver a la página principal",
    button: "Ir a inicio",
  },
  pages: {
    attendingEvents: "Tus entradas",
    event: "Evento",
    queue: "Sesión",
    redeem: "Escanear",
    scan: "Escanear",
    share: "Compartir",
  },
  events: {
    explore: "Explorar",
    searchPlaceholder: "Buscar por código postal",
    myEvents: "Mis eventos",
    createEvent: "Crear un evento",
    loading: "Cargando...",
    loadMore: "Cargar más",
    noResults: "Sin resultados",
    endOfList: "Has llegado al final de la lista",
    noEvent: {
      segment1: "No has",
      segment2: "creado",
      segment3: "registrado para",
      segment4: "ningún evento",
      description1: "Una vez que crees tu primer evento, aparecerá aquí.",
      description2: "Una vez que empieces a reservar entradas, las verás aquí.",
    },
    findNearMe: "Encuentra eventos cerca de mí",
    list: {
      earned: "ganado",
      spot: "Lugar",
      free: "Gratis",
      today: "Hoy",
      upcoming: "Próximos",
      past: "Pasados",
    },
  },
  event: {
    earned: "ganado",
    free: "Gratis",
    earnedCap: "Ganado",
    sessionsTitle: "Sesiones",
    defaultSessionTitle: "Sesión 1",
    shareEvent: "Compartir este evento",
    share: {
      linkCopied: "Enlace copiado al portapapeles",
      copyFailed: "No se pudo copiar el enlace: ",
      shareEvent: "Compartir este evento",
      shareEventDescription:
        "Copia el enlace de abajo o toca el código QR para descargar y deja que la gente escanee el código para ver este evento.",
      copyButton: "Copiar enlace al evento",
      backToEvents: "Ver mis entradas",
    },
    payment: {
      success: "¡Pago exitoso!",
      processing: "Tu pago está en proceso.",
      requiresPaymentMethod:
        "Tu pago no fue exitoso, por favor intenta de nuevo.",
      unexpectedError:
        "Ocurrió un error inesperado, por favor intenta de nuevo.",
      payNow: "Pagar ahora",
      cancel: "Cancelar",
    },
    redeem: {
      success: {
        title: "¡Escaneo exitoso!",
        segment1: "Te registraste el",
        segment2: "a las",
      },
      backToEvent: "Ver página del evento",
    },
  },
  queue: {
    freeCap: "Gratis",
    spotsReserved: "Lugares reservados",
    removeReservationPrompt: "¿Eliminar la reserva?",
    removeReservationMessage: `Presiona OK para eliminar tu reserva para este lugar`,
    cancel: "Cancelar",
    ok: "Aceptar",
    scannerNotAvailable: "Escáner no disponible en la web",
    openQrScanner: "Abrir escáner de código QR",
    openQrTicket: "Abrir entrada de código QR",
    scan: "escanear",
    redeem: "canjear",
    signInToReserveSpot: "Iniciar sesión para reservar un lugar",
    removeReservation: "Eliminar reserva",
    reserveSpot: {
      segment1: "Reservar lugar",
      segment2: "para",
      segment3: "Gratis",
    },
    alreadyCheckedInTitle: "Ya te registraste en este evento",
    alreadyCheckedInDescription: "Te registraste el",
    earnedCap: "Ganado",
    paymentSuccess: "Pago exitoso",
    paymentCancelled: "Pago cancelado",
    tapToOpenTicket: `Toca el botón de arriba para abrir tu entrada`,
    tryAgain: "Por favor, inténtalo de nuevo.",
    attendeesTitle: "Asistentes",
    spot: "Lugar",
    pricingChart: {
      dayOfEvent: "Día del evento",
      hrsBefore: "24 horas antes del evento",
      daysBefore: "Días o más antes del evento",
      days: "Días",
      day: "Día",
      before: "Antes del evento",
      pricingGuide: "Guía de precios",
      free: "Gratis",
    },
  },
  onboarding: {
    info: {
      errorSaving: "Hubo un error al guardar tu cuenta",
      tellUsAboutYourself: "Cuéntanos sobre ti",
      first_name: "Nombre",
      last_name: "Apellido",
      email: "Correo electrónico",
      phone: "Teléfono",
      city: "Ciudad",
      state: "Estado",
      postal_code: "Código postal",
      enterValid: "Por favor, introduce un",
      addCompanyTitle: "¿Quieres organizar tus propios eventos?",
      addCompanyDescription:
        "Haz clic aquí para agregar la información de tu empresa y organizar tus propios eventos.",
      creating: "Creando cuenta...",
      continue: "Continuar",
    },
  },
  account: {
    home: {
      settingsTitle: "Configuración de la cuenta",
      settingsDescription: "Gestiona tu cuenta",
      companyTitle: "Gestiona la información de la empresa",
      companyDescription: "Cambia el nombre o la dirección de tu empresa",
      usersTitle: "Gestionar usuarios",
      usersDescription: "Añadir o quitar acceso para usuarios autorizados",
      payments: {
        title: "Gestionar pagos",
        loading: "Cargando información de Stripe",
        viewDashboard: "Ver tu panel de Stripe",
        continue: "Continuar configurando tu cuenta de Stripe",
        start: "Comienza a aceptar pagos con Stripe",
      },
      createCompanyTitle: "Crear una empresa",
      createCompanyDescription: "Organiza tus propios eventos",
      signOut: "Cerrar sesión",
    },
    info: {
      errorSaving: "Hubo un error al guardar tu cuenta",
      title: "Cuenta",
      enterValid: "Por favor, introduce un",
      areYouSureTitle: "¿Estás seguro de que quieres eliminar tu cuenta?",
      areYouSureMessage: `Esto también eliminará todos tus eventos y lugares. Si deseas eliminar permanentemente tu cuenta, por favor contáctanos.`,
      cancel: "Cancelar",
      deactivate: "Borrar",
      deactivateAccount: "Borrar cuenta",
      saving: "Guardando...",
      save: "Guardar",
      first_name: "Nombre",
      last_name: "Apellido",
      email: "Correo electrónico",
      phone: "Teléfono",
      city: "Ciudad",
      state: "Estado",
      postal_code: "Código postal",
    },
  },
};
