import React from "react";
import { Heading } from "./Heading";
import { Text } from "./Text";

type TextCalloutProps = {
  title: string;
  description: string;
  showBoxShadow?: boolean;
  className?: string;
  titleClassName?: string;
};

export const TextCallout = ({
  title,
  description,
  showBoxShadow,
  className,
  titleClassName,
}: TextCalloutProps) => {
  const getContainerStyles = () => {
    let styles = "bg-white-100 rounded-2xl p-4 pt-5 pb-6";
    if (showBoxShadow) styles += " shadow-[0_1px_2px_-0px_rgba(0,0,0,0.1)]";
    if (className) styles += ` ${className}`;
    return styles;
  };

  return (
    <div className={getContainerStyles()}>
      <Heading
        text={title}
        color="black"
        size="lg"
        className={`mb-1 ${titleClassName}`}
      />
      <Text text={description} color="gray" />
    </div>
  );
};
