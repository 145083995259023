import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { formatISO } from "date-fns";

interface Input {
  label: string;
  value: string | number;
  type?: string;
  options?: any[];
  valid: boolean;
  required?: boolean;
  autocomplete?: string;
  className?: string;
}

interface Queue {
  description: Input;
  description_spanish?: Input;
  start_date_time: Input;
  num_spots: Input;
  max_spot_cost: Input;
  spot_increment: Input;
}

interface QueueState {
  info: Queue[];
  isLoading: boolean;
  error: string | null;
  hasSubmitted: boolean;
}

const initialState: QueueState = {
  info: [
    {
      description: {
        label: "Queue Description",
        value: "",
        type: "text",
        valid: false,
        required: true,
        className: "mb-4",
      },
      start_date_time: {
        label: "Start Date",
        value: formatISO(new Date().getTime() + 60 * 60 * 1000),
        type: "date",
        valid: false,
        required: true,
        className: "mb-4",
      },
      num_spots: {
        label: "Number of Spots",
        value: "",
        type: "number",
        valid: false,
        required: true,
        className: "mb-4",
      },
      max_spot_cost: {
        label: "Maximum Cost",
        value: "",
        type: "number",
        valid: false,
        required: true,
        className: "rounded-b-none border-b-0",
      },
      spot_increment: {
        label: "Spot Increment",
        value: "",
        type: "number",
        valid: false,
        required: true,
        className: "rounded-t-none mb-4",
      },
    },
  ],
  isLoading: false,
  error: null,
  hasSubmitted: false,
};

export const queue = createSlice({
  name: "queue",
  initialState,
  reducers: {
    setInitialStateInfo: (state, action: PayloadAction<Queue[]>) => {
      state.info = action.payload;
    },
    updateQueue: (
      state,
      action: PayloadAction<{
        queueIndex: number;
        key: keyof Queue;
        value: string;
      }>
    ) => {
      state.info[action.payload.queueIndex][action.payload.key].value =
        action.payload.value;
    },

    addQueue: (state, action: PayloadAction<Queue>) => {
      state.info.push(action.payload || initialState.info[0]);
    },
    removeQueue: (state, action: PayloadAction<number>) => {
      state.info.splice(action.payload, 1);
    },
    setQueue: (state, action: PayloadAction<Queue[]>) => {
      state.info = action.payload;
    },
    resetQueue: (state) => {
      /* Immer makes us set these individually, the reset doesn't work otherwise */
      state.info = initialState.info;
      state.isLoading = false;
      state.error = null;
      state.hasSubmitted = false;
    },
    setHasSubmitted: (state, action: PayloadAction<boolean>) => {
      state.hasSubmitted = action.payload;
    },
  },
});

export const {
  setInitialStateInfo,
  updateQueue,
  resetQueue,
  addQueue,
  removeQueue,
  setQueue,
  setHasSubmitted,
} = queue.actions;

export default queue.reducer;
