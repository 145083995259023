import validateFields from "../../utils/validation";
import { setEvent } from "../event";
import { setAccount } from "../account";
import { setQueue } from "../queue";
import { setCompany } from "../company";

export const createValidationMiddleware =
  (storeAPI: any) => (next: any) => (action: any) => {
    /* Need to look @ next to get the current state */
    const result = next(action);
    const state = storeAPI.getState();

    /* Handle validations on the event, queue, and account forms */
    if (action.type === "event/updateEvent") {
      const updatedInfo = validateFields(state.event.info);
      storeAPI.dispatch(setEvent(updatedInfo));
    } else if (action.type === "queue/updateQueue") {
      /* Queues are in a list, so we map them instead of just passing straight to validation*/
      const updatedInfo = state.queue.info.map(validateFields);
      storeAPI.dispatch(setQueue(updatedInfo));
    } else if (action.type === "account/updateAccount") {
      const updatedInfo = validateFields(state.account.info);
      storeAPI.dispatch(setAccount(updatedInfo));
    } else if (action.type === "company/updateCompany") {
      const updatedInfo = validateFields(state.company.info);
      storeAPI.dispatch(setCompany(updatedInfo));
    }

    return result;
  };
