import {
  IonIcon,
  IonModal,
  useIonViewDidEnter,
  useIonViewDidLeave,
} from "@ionic/react";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import ReactDOM from "react-dom";
import {
  BarcodeScanner,
  SupportedFormat,
} from "@capacitor-community/barcode-scanner";
import { spotService } from "../../service/spotService";
import { useParams } from "react-router";
import { scanService } from "../../service/scanService";
import { useQuery } from "react-query";
import { EventCard } from "../ui/EventCard";
import { Text } from "../ui/Text";
import { Loader } from "../core/Loader";
import { convertStripePriceToDollars, getAddressDisplay } from "../../utils";
import { SpotCallout } from "../ui/SpotCallout";
import { TextCallout } from "../ui/TextCallout";
import { format } from "date-fns";
import { closeOutline } from "ionicons/icons";
import { useTranslation } from "../../TranslationContext";

interface Props {
  data?: any;
  isCurrentPageActive?: boolean;
}

type Params = {
  eventId: string;
  queueId: string;
};

/* Portals must be instantiated outside of the component */
const portalDiv = document.createElement("div");
portalDiv.setAttribute("id", "portal-div");
portalDiv.classList.add(
  "absolute",
  "w-16",
  "h-16",
  "bg-black-100",
  "!z-50",
  "bottom-12",
  "mx-auto",
  "left-0",
  "right-0",
  "rounded-full",
  "flex",
  "justify-center",
  "items-center",
  "cursor-pointer",
  "leading-3",
  "hidden"
);
document.documentElement.appendChild(portalDiv);

const Seller: React.FC<Props> = ({ isCurrentPageActive }) => {
  /* Translations */
  const { languageCode }: any = useTranslation();

  /* Hooks */
  const [scanData, setScanData] = useState(null);
  const [scanResult, setScanResult] = useState<any>({
    message: "",
    valid: false,
    spot: {},
  });
  const [event, setEvent] = useState<any>({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { eventId, queueId } = useParams<Params>();
  const history = useHistory();

  /* Refs */
  const modal = useRef<HTMLIonModalElement>(null);

  /* React Query */
  const { data: scanResultRes, isLoading: isScanResultResLoading } = useQuery(
    ["scanResultRes"],
    () => async () => {
      if (scanData !== null) {
        setScanResult({ message: "", valid: false });
        const url = new URL(scanData);
        const userId = url.searchParams.get("userId");
        const queryParamEventId = url.searchParams.get("eventId");
        const queryParamQueueId = url.searchParams.get("queueId");

        if (eventId === queryParamEventId && queueId === queryParamQueueId) {
          const spotRes = await spotService.validateSpot(
            userId,
            queueId,
            languageCode
          );
          const spot = spotRes?.[0];
          if (spot) {
            const hasBeenScanned = await scanService.getScansByUserBySpot(
              userId,
              spot.id
            );
            if (hasBeenScanned?.length) {
              setScanResult({
                message: `Code is valid, but was already scanned on: ${new Date(
                  hasBeenScanned?.[0]?.updated_at
                )?.toLocaleString()}`,
                valid: false,
                spot: {},
              });
            } else {
              setScanResult({
                message: "Code is valid",
                valid: true,
                spot,
              });
              /* Mark as scanned in the database */
              await scanService.createScanRecord(userId, spot.id);
            }
          }

          /* Set the event card info as well */
          setEvent({
            title: spot?.queue?.event?.title,
            description: spot?.queue?.description,
            user: {
              company_name: spot?.queue?.event?.created_by?.company_name,
            },
            queue: spot?.queue,
          });
        } else {
          setScanResult({
            message: "This is not a valid code for this event",
            valid: false,
            spot: {},
          });
        }
        setIsModalOpen(true);
      }
    },
    { enabled: scanData !== null && !isModalOpen && isCurrentPageActive }
  );

  /* Constants */
  const addressDisplay = getAddressDisplay(event?.address_available_date_time);

  const scannerViewRef = useRef(null);

  const startScan = async () => {
    /* Always check the camera permissions */
    await BarcodeScanner.checkPermission({ force: true });

    /* startScanning lets us continuously scan */
    await BarcodeScanner.startScanning(
      {
        targetedFormats: [SupportedFormat.QR_CODE],
      },
      (result) => {
        if (result.hasContent) {
          setScanData(result?.content);
          BarcodeScanner.pauseScanning();
        }
      }
    );
  };

  /* Ionic lifecylce hooks */
  /* 
    When we open this view, we always want to start the camera, and when we leave, we always
    want to make sure it turns off
  */
  useIonViewDidEnter(() => {
    startScan();
  });

  useIonViewDidLeave(() => {
    BarcodeScanner.stopScan();
  });

  /*
    This works without this code, but just in case Ionic changes the way they handle modals, its here.
    The bar code scanner lib is a bit odd, the camera completely overtakes the screen
  */
  useEffect(() => {
    if (!isModalOpen) {
      portalDiv.classList.remove("hidden");
      document.querySelector("body").classList.add("scanner-active");
      BarcodeScanner.hideBackground();
    } else if (isCurrentPageActive) {
      portalDiv.classList.add("hidden");
      document.querySelector("body").classList.remove("scanner-active");
      BarcodeScanner.showBackground();
    }
  }, [isModalOpen, isCurrentPageActive]);

  if (!isCurrentPageActive) return <Loader className="min-h-[200px]" />;
  return (
    <div className="block">
      {/* TOP  */}
      {scanResult?.message?.length ? (
        <div>
          <IonModal
            ref={modal}
            initialBreakpoint={1}
            breakpoints={[0, 1]}
            isOpen={isModalOpen}
            onDidDismiss={() => {
              setScanData(null);
              setIsModalOpen(false);
              BarcodeScanner.resumeScanning();
            }}
          >
            <div className="p-4 pt-6 grid gap-1">
              {/* PERSON INFO  */}
              {scanResult.valid && (
                <>
                  <SpotCallout
                    line1={`${scanResult?.spot?.purchased_by?.first_name} ${scanResult?.spot?.purchased_by?.last_name}`}
                    line2={
                      <div className="flex items-center">
                        <span className="text-green-100">
                          {scanResult?.spot?.stripe_amount_total
                            ? `$${convertStripePriceToDollars(
                                scanResult?.spot?.stripe_amount_total
                              )}`
                            : "Free"}
                        </span>
                        <span className="text-xs mx-0.5">•</span>
                        <span>
                          {scanResult?.spot?.purchased_by?.email ||
                            scanResult?.spot?.purchased_by?.phone}
                        </span>
                      </div>
                    }
                    number={scanResult?.spot?.derived_spot_num}
                    className="!rounded-t-2xl"
                  />

                  {/* EVENT INFO  */}
                  <EventCard
                    line1={event?.queue?.description}
                    line2={event?.title}
                    company={event?.user?.company_name}
                    date={
                      event?.queue?.start_date_time &&
                      format(
                        new Date(event?.queue?.start_date_time),
                        "EEE M/d·h:mm a"
                      )
                    }
                    className="!rounded-t-none !rounded-b-2xl"
                  />
                </>
              )}
              {/* After scan  */}
              {scanResult?.message?.length && !scanResult.valid && (
                <TextCallout
                  title="Invalid Scan"
                  description={scanResult.message}
                  showBoxShadow
                  className="rounded-sm rounded-t-2xl"
                  titleClassName="text-red-100"
                />
              )}
              <Text
                color="gray"
                text="Swipe down to scan again"
                className="text-center mt-2"
              />
            </div>
          </IonModal>
        </div>
      ) : null}
      {/* Close button  */}
      {ReactDOM.createPortal(
        <div>
          <div
            onClick={() => {
              document.querySelector("body").classList.remove("scanner-active");
              portalDiv.classList.add("hidden");
              BarcodeScanner.showBackground();
              history.goBack();
            }}
          >
            <IonIcon
              icon={closeOutline}
              color="light"
              className="ion-icon-scanner-override"
            />
          </div>
        </div>,
        portalDiv
      )}
    </div>
  );
};

export default Seller;
