import { get } from "http";
import { supabase } from "../supabaseClient";

/*
    Get user by id
    @params 
    userId: string
*/
async function getUserById(userId: string) {
  const { data: user, error } = await supabase
    .from("user")
    .select(
      `*,
    user_companies:user_company!user_id (
      company:company_id(
        id,
        name,
        created_by,
        supports_spanish
      )
    )`
    )
    .eq("id", userId)
    .filter("user_companies.active", "eq", true)
    .maybeSingle();

  if (error) {
    console.error("Error fetching user:", error);
    throw error;
  }

  return user;
}

async function getUserInfoById(userId: string) {
  const { data: user, error } = await supabase
    .from("user")
    .select("*")
    .eq("id", userId)
    .maybeSingle();

  if (error) {
    console.error("Error fetching user:", error);
    throw error;
  }

  return user;
}

/*
    Get user session
*/
async function getUserSession() {
  const { data: session, error } = await supabase.auth.getSession();

  if (error) {
    console.error("Error fetching user session:", error);
    throw error;
  }

  return session;
}

/* Sign a user out */
async function signUserOut() {
  const { error } = await supabase.auth.signOut();

  if (error) {
    console.error("Error signing user out:", error);
    throw error;
  }
  return { success: true };
}

async function insertUser(sessionUserId: string, user: any) {
  const { data, error } = await supabase.from("user").upsert([
    {
      id: sessionUserId,
      ...user,
      created_by: sessionUserId,
    },
  ]);

  /* 
      fetching single chained to the previous query isn't working, so doing a fresh query.
      This isn't performant, but it's only called once per user, ever. 
    */
  const { data: userRes, error: userError } = await supabase
    .from("user")
    .select("*")
    .eq("id", sessionUserId)
    .eq("active", true)
    .maybeSingle();

  if (error) {
    console.error("Error fetching user:", error);
    throw error;
  }

  return userRes;
}

async function updateUser(sessionUserId: string, user: any) {
  const { data, error } = await supabase
    .from("user")
    .update(user)
    .eq("id", sessionUserId);

  if (error) {
    console.error("Error updating user2:", error);
    throw error;
  }

  return data;
}

async function markUserAsDeleted(sessionUserId: string) {
  const { data, error } = await supabase
    .from("user")
    .update({ active: false })
    .eq("id", sessionUserId);

  if (error) {
    console.error("Error updating user:", error);
    throw error;
  }

  return data;
}

async function markUserAsActive(sessionUserId: string) {
  const { data, error } = await supabase
    .from("user")
    .update({ active: true })
    .eq("id", sessionUserId);

  if (error) {
    console.error("Error updating user:", error);
    throw error;
  }

  return data;
}

export const userService = {
  getUserById,
  updateUser,
  getUserInfoById,
  getUserSession,
  insertUser,
  signUserOut,
  markUserAsDeleted,
  markUserAsActive,
};
