import React from "react";
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonHeader,
  IonIcon,
  IonSearchbar,
  IonToolbar,
} from "@ionic/react";
import { Heading } from "./Heading";
import { add } from "ionicons/icons";
import logoIconSm from "../../assets/logo-icon-sm.svg";

type HeaderProps = {
  type?: "DEFAULT" | "SEARCH" | "DISPLAY" | "ONBOARDING";
  title?: any;
  showBackButton?: boolean;
  backButtonText?: string;
  backButtonHref?: string;
  actionLabel?: string | JSX.Element;
  actionHandler?: () => void;
  className?: string;
  searchHandler?: (arg: any) => void;
  searchClearHandler?: () => void;
  searchValue?: string;
  searchPlaceholder?: string;
  progressPct?: any;
};

export const Header = ({
  type = "DEFAULT",
  title,
  showBackButton,
  backButtonText = "",
  backButtonHref = "/",
  actionLabel = "",
  actionHandler,
  className,
  searchHandler,
  searchClearHandler,
  searchValue,
  searchPlaceholder,
  progressPct = "0",
}: HeaderProps) => {
  const getHeaderType = () => {
    /* Display Headers */
    if (type === "DISPLAY") {
      return (
        <div
          className={`ion-padding flex items-center justify-between ${className}`}
        >
          <Heading color="black" size="2xl" text={title} />
          {actionHandler !== undefined && (
            <IonButton
              className="header-icon-button-override"
              size="small"
              onClick={actionHandler}
            >
              <IonIcon
                aria-hidden="true"
                aria-label={`${actionLabel}`}
                icon={add}
                className="header-icon-override"
              />
            </IonButton>
          )}
        </div>
      );
    } else if (type === "SEARCH") {
      /* Search Headers */
      if (
        searchHandler === undefined ||
        searchClearHandler === undefined ||
        searchValue === undefined
      ) {
        return <></>;
      }
      return (
        <div className="ion-padding search-container">
          <div className="rounded-md border border-black-10 p-3 pb-0 bg-white-100">
            <IonSearchbar
              debounce={1000}
              onIonInput={(ev) => searchHandler(ev)}
              onIonClear={searchClearHandler}
              value={searchValue}
              placeholder={searchPlaceholder}
              className="p-0 header-searchbar-override"
            ></IonSearchbar>
          </div>
        </div>
      );
    } else if (type === "ONBOARDING") {
      return (
        <>
          <div className="p-2 flex items-center justify-between">
            <IonButtons slot="start" className="min-w-[24px]">
              {(showBackButton || !!backButtonText?.length) && (
                <IonBackButton
                  className="ion-back-button-override"
                  defaultHref={backButtonHref}
                  text={backButtonText}
                />
              )}
            </IonButtons>

            <div className="text-lg font-semibold tracking-[-0.25px]">
              <img src={logoIconSm} alt="logo" className="h-9 w-9" />
            </div>
            <IonButtons slot="end" className="min-w-[24px]"></IonButtons>
          </div>
          <div className="h-1 bg-black-10 my-4 mx-[16px] w-[calc(100%-32px)] rounded-full">
            <div
              className="h-full bg-gold-100 rounded-full"
              style={{ width: `${progressPct}%` }}
            ></div>
          </div>
        </>
      );
    } else {
      /* Default Headers */
      return (
        <div className="p-2 flex items-center justify-between">
          <IonButtons slot="start" className="min-w-[24px]">
            {(showBackButton || !!backButtonText?.length) &&
              type === "DEFAULT" && (
                <IonBackButton
                  className="ion-back-button-override"
                  defaultHref={backButtonHref}
                  text={backButtonText}
                />
              )}
          </IonButtons>

          <div className="text-lg font-semibold tracking-[-0.25px]">
            {title}
          </div>

          <IonButtons slot="end" className="min-w-[24px]">
            {actionHandler !== undefined && actionLabel !== undefined && (
              <IonButton
                onClick={actionHandler}
                className="header-default-button-override"
              >
                {actionLabel}
              </IonButton>
            )}
          </IonButtons>
        </div>
      );
    }
  };

  return (
    <IonHeader collapse="condense">
      <IonToolbar>{getHeaderType()}</IonToolbar>
    </IonHeader>
  );
};
