import {
  IonContent,
  IonFooter,
  IonSelect,
  IonSelectOption,
  IonToggle,
  useIonToast,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useCompany } from "../../hooks/useCompany";
import { useHistory } from "react-router";
import { Input } from "../ui/Input";
import { Header } from "../ui/Header";
import { Button } from "../ui/Button";

const Company: React.FC = () => {
  /* Hooks */
  const history = useHistory();
  const [present] = useIonToast();
  const [isLoading, setIsLoading] = useState(false);

  const {
    companyInfo,
    updateCompany,
    updateCompanyCall,
    createCompany,
    validateCompany,
    userCompanies,
    hasSubmitted,
    setHasSubmitted,
  } = useCompany();

  /* Helpers */
  const handleSubmit = async () => {
    /* Since we're on the onboarding, we'll need to create the user, then the account*/
    try {
      validateCompany(async () => {
        setIsLoading(true);
        if (!userCompanies?.[0]?.company?.id) {
          await createCompany();
        } else {
          await updateCompanyCall();
        }
        setIsLoading(false);
        setHasSubmitted(false);
        history.push("/account");
      });
    } catch (error) {
      setIsLoading(false);
      console.log("Error:", error);
      present({
        message: "There was an error saving your account",
        duration: 3000,
        position: "bottom",
      });
    }
  };

  const handleInputChange = (key: any, event: any) => {
    const { value } = event.target;
    updateCompany({ key, value });
  };

  const handleSelectChange = (key: any, e: CustomEvent) => {
    updateCompany({ key, value: e.detail.value });
  };

  const handleLanguageToggle = (event: any) => {
    const { checked } = event.detail;
    const name = event.target.name;

    updateCompany({ key: name, value: checked });
  };

  const getInputType = (info: any, key: any, errorMessage: any) => {
    if ((info as any)?.[key].type === "select") {
      /* Select */
      return (
        <div
          className={`bg-white-100 rounded-lg border border-black-10 !pt-2 !pb-2 px-3 w-full ${
            (info as any)?.[key].className
          }`}
        >
          <IonSelect
            aria-label={(info as any)?.[key]?.label}
            placeholder={(info as any)?.[key]?.label}
            value={(info as any)?.[key]?.value}
            label={(info as any)?.[key]?.label}
            labelPlacement="stacked"
            onIonChange={(e) => handleSelectChange(key, e)}
          >
            {(info as any)?.[key]?.options.map(
              (option: any, optionIndex: any) => (
                <IonSelectOption value={option.key} key={optionIndex}>
                  {option.value}
                </IonSelectOption>
              )
            )}
          </IonSelect>
          {errorMessage}
        </div>
      );
    } else if ((info as any)?.[key].type === "checkbox") {
      /* Toggle */
      return (
        <div
          className={`bg-white-100 rounded-lg border border-black-10 py-5 px-3 w-full mb-4`}
        >
          <IonToggle
            justify="space-between"
            color="dark"
            name={key}
            checked={(info as any)?.[key].value}
            onIonChange={handleLanguageToggle}
            className="w-full"
          >
            {(info as any)?.[key]?.label}
          </IonToggle>
          {errorMessage}
        </div>
      );
    } else {
      /* Regular Input */
      return (
        <Input
          label={(info as any)?.[key]?.label}
          placeholder={(info as any)?.[key].label}
          value={(info as any)?.[key].value}
          name={key}
          onIonInput={(e) => handleInputChange(key, e)}
          type={(info as any)?.[key].type}
          className={(info as any)?.[key].className}
          errorMessage={errorMessage}
        ></Input>
      );
    }
  };

  return (
    <>
      <Header
        title={"Company"}
        showBackButton={true}
        backButtonHref="/account"
      />
      <IonContent className="ion-padding-page">
        <div className="mt-2">
          {Object.keys(companyInfo).map(
            (key, index) =>
              !(companyInfo as any)?.[key as any].hidden && (
                <div className="block" key={index}>
                  <div>
                    {getInputType(
                      companyInfo,
                      key,
                      !(companyInfo as any)?.[key]?.valid &&
                        (companyInfo as any)?.[key]?.required &&
                        hasSubmitted && (
                          <div className="text-xs text-red-100">
                            Please enter a valid{" "}
                            {(companyInfo as any)?.[key]?.label}
                          </div>
                        )
                    )}
                  </div>
                </div>
              )
          )}
        </div>
      </IonContent>
      <IonFooter>
        <div className="w-full text-black-100 flex justify-end items-center p-4 bg-appBg">
          <Button
            color="black"
            onClick={() => handleSubmit()}
            text={isLoading ? "Saving..." : "Save"}
            disabled={isLoading}
          />
        </div>
      </IonFooter>
    </>
  );
};

export default Company;
