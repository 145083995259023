import React from "react";
import { IonContent, IonPage } from "@ionic/react";
import AccountCompany from "../../components/account/Company";

const Company: React.FC = () => {
  return (
    <IonPage>
      <AccountCompany />
    </IonPage>
  );
};

export default Company;
