import { setSession, resetCore } from "../redux/core";
import { useAppDispatch } from "../redux/hooks";

export const useCore = () => {
  /* Redux */
  const dispatch = useAppDispatch();

  return {
    setSession: (session: string) => dispatch(setSession(session)),
    resetCore: () => dispatch(resetCore()),
  };
};
