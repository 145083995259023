import { useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { companyService } from "../service/companyService";
import {
  setCompany,
  fetchCompanyStart,
  fetchCompanySuccess,
  fetchCompanyError,
  updateCompany,
  setHasSubmitted,
  setActiveCompany,
  setUserCompanies,
} from "../redux/company";
import { useIonViewDidEnter } from "@ionic/react";
import validateFields from "../utils/validation";
export const useCompany = () => {
  /* Redux */
  const dispatch = useAppDispatch();
  const {
    session,
    user,
    companyInfo,
    isLoading,
    error,
    lastFetchTimestamp,
    hasSubmitted,
    activeCompany,
    userCompanies,
  } = useAppSelector((state) => ({
    session: state.core.session,
    user: state.core.user,
    companyInfo: state.company.info,
    isLoading: state.company.isLoading,
    error: state.company.error,
    lastFetchTimestamp: state.company.lastFetchTimestamp,
    hasSubmitted: state.company.hasSubmitted,
    activeCompany: state.company.activeCompany,
    userCompanies: state.company.userCompanies,
  }));

  /* Refs */
  const stateRef = useRef<any>();

  /* 
    This is a bit weird, but we don't have access to hook data in ionic
    lifecycle hooks -- we can keep it forever cached in a ref, though.
*/
  stateRef.current = {
    session,
    user,
    companyInfo,
    isLoading,
    error,
    lastFetchTimestamp,
  };

  /* Helpers */
  const fetchCompany = async () => {
    const currentTime = new Date().getTime();
    dispatch(fetchCompanyStart());
    try {
      if (user?.id && userCompanies?.length) {
        const companyRes = await companyService.getCompanyById(
          userCompanies?.[0]?.company?.id
        );
        const updatedCompanyInfo = Object.keys(companyInfo).reduce(
          (acc: any, key) => {
            acc[key] = { ...(companyInfo as any)[key], value: companyRes[key] };
            if (acc[key].value === null) {
              acc[key].value = "";
            }
            return acc;
          },
          {}
        );
        const validatedUpdatedCompanyInfo = validateFields(updatedCompanyInfo);
        dispatch(setCompany(validatedUpdatedCompanyInfo));
        dispatch(fetchCompanySuccess());
      } else {
        dispatch(fetchCompanyError(error));
      }
    } catch (error: any) {
      dispatch(fetchCompanyError(error));
    }
  };

  /* Every time the view loads */

  /* Refetch company data if its been 5 minutes since the last fetch */
  useIonViewDidEnter(() => {
    const lastFetched = stateRef.current.lastFetchTimestamp;
    const currentTime = new Date().getTime();

    const fiveMinutes = 1000 * 60 * 5;
    if (currentTime - lastFetched >= fiveMinutes) {
      fetchCompany();
    }
  });

  /* The first time the component mounts, once the user object is populated, fetch the data*/
  useEffect(() => {
    if (user?.id) {
      fetchCompany();
    }
  }, [user]);

  const updateCompanyCall = async () => {
    dispatch(setHasSubmitted(true));
    /* Check for validity first */
    const invalidFields = Object.keys(companyInfo).filter(
      (key) =>
        (companyInfo as any)[key].valid === false &&
        !(companyInfo as any)[key].hidden
    );
    if (!invalidFields.length) {
      dispatch(fetchCompanyStart());
      try {
        const companyData = Object.keys(companyInfo).reduce((acc: any, key) => {
          acc[key] = (companyInfo as any)[key].value;
          return acc;
        }, {});
        const companyRes = await companyService.updateCompany(
          userCompanies?.[0]?.company?.id,
          companyData
        );

        /* If the company is updated, we need to update the activeCompany value as well*/
        if (companyRes) {
          dispatch(setActiveCompany(companyRes));
        }
        dispatch(fetchCompanySuccess());
      } catch (error: any) {
        dispatch(fetchCompanyError(error));
      }
    }
  };

  const createCompany = async (onSuccess?: any) => {
    dispatch(setHasSubmitted(true));

    /* Check for validity first */
    const invalidFields = Object.keys(companyInfo).filter(
      (key) =>
        (companyInfo as any)[key].valid === false &&
        !(companyInfo as any)[key].hidden
    );

    if (!invalidFields.length) {
      dispatch(fetchCompanyStart());
      try {
        const companyData = Object.keys(companyInfo).reduce(
          (acc: any, key: any) => {
            acc[key] = (companyInfo as any)[key].value;
            return acc;
          },
          {}
        );
        const companyRes = await companyService.insertCompany(
          session.user.id,
          companyData
        );
        // if we just created one, we can safely assume we can set the active AND userCopanies to the first one
        dispatch(
          setActiveCompany({
            id: companyRes?.[0]?.id,
            name: companyRes?.[0]?.name,
            supports_spanish: companyRes?.[0]?.supports_spanish,
          })
        );
        dispatch(
          setUserCompanies([
            {
              company: {
                id: companyRes?.[0]?.id,
                name: companyRes?.[0]?.name,
                created_by: session.user.id,
              },
            },
          ])
        );
        dispatch(fetchCompanySuccess());
        /* This is only for the onboarding flow */
        if (onSuccess) {
          setTimeout(() => {
            onSuccess();
          }, 1000);
        }
      } catch (error: any) {
        dispatch(fetchCompanyError(error));
      }
    }
  };

  const validateCompany = (onSuccess?: any) => {
    dispatch(setHasSubmitted(true));

    /* Check for validity first */
    const invalidFields = Object.keys(companyInfo).filter(
      (key) =>
        (companyInfo as any)[key].valid === false &&
        !(companyInfo as any)[key].hidden
    );

    if (!invalidFields.length) {
      onSuccess();
    }
  };

  return {
    user,
    companyInfo,
    setCompany: (info: any) => dispatch(setCompany(info)),
    setActiveCompany: (company: any) => dispatch(setActiveCompany(company)),
    isLoading,
    error,
    createCompany,
    updateCompanyCall,
    updateCompany: (info: any) => dispatch(updateCompany(info)),
    hasSubmitted,
    setHasSubmitted: (hasSubmitted: boolean) =>
      dispatch(setHasSubmitted(hasSubmitted)),
    validateCompany,
    activeCompany,
    userCompanies,
  };
};
