import { createSlice, current } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

const initialState: any = {
  session: null,
  user: {},
  hasFetchedUser: false,
};

export const core = createSlice({
  name: "core",
  initialState,
  reducers: {
    setSession: (state, action: PayloadAction<any>) => {
      state.session = action.payload;
    },
    setUser: (state, action: PayloadAction<any>) => {
      state.user = action.payload;
    },
    setHasFetchedUser: (state, action: PayloadAction<boolean>) => {
      state.hasFetchedUser = action.payload;
    },

    resetCore: (state) => {
      state.session = null;
      state.user = {};
    },
  },
});

export const { setSession, setUser, resetCore, setHasFetchedUser } =
  core.actions;

export default core.reducer;
