import { supabase } from "../supabaseClient";

/*
  If we ever add address here, we need to move to a CASE in an RPC function 
*/
async function validateSpot(
  userId: string,
  queueId: string,
  languageCode = "en"
) {
  const { data: spot, error } = await supabase.rpc("validate_spot", {
    queue_id_arg: queueId,
    user_id_arg: userId,
    language_code_param: languageCode,
  });

  if (error) {
    console.error("Error validating spot at queue:", error);
    throw error;
  }

  return spot;
}

async function getSpotById(spotId: string) {
  const { data: spot, error } = await supabase
    .from("spot")
    .select("*")
    .eq("id", spotId)
    .eq("active", true);

  if (error) {
    console.error("Error fetching spot by id:", error);
    throw error;
  }

  return spot;
}

async function getAttendeesByQueue(
  queueId: string,
  didUserCreateEvent: boolean,
  userId?: string
) {
  /* We only want to include user details if you're fetching as admin */
  let spotSelectStatement = "*";
  if (didUserCreateEvent) {
    spotSelectStatement +=
      ", user:purchased_by (id, first_name, last_name, email)";
  }

  const { data: spots, error } = await supabase
    .from("spot")
    .select(spotSelectStatement)
    .eq("queue_id", queueId)
    .eq("active", true)
    .order("created_at", { ascending: true });

  if (error) {
    console.error("Error fetching spots by queue:", error);
    throw error;
  }

  /*
    Retrieve the scans for the spots.
    If you're an admin, you can see all scans.
    If you're a user, you can only see your own scans.
  */
  const spotIds = spots.map((spot: any) => spot.id);

  let scanQuery = supabase.from("scan").select("*").in("spot_id", spotIds);
  if (userId && !didUserCreateEvent) {
    scanQuery = scanQuery.eq("user_id", userId);
  }

  const { data: scans, error: scanError } = await scanQuery;

  if (scanError) {
    console.error("Error fetching scans for spots:", scanError);
    throw scanError;
  }

  // Attach the scans to the corresponding spots
  const spotsWithScans = spots.map((spot: any) => {
    return {
      ...spot,
      scans: scans.filter((scan) => scan.spot_id === spot.id),
    };
  });

  return spotsWithScans;
}

async function getUserBySpot(userId: string) {
  const { data: user, error } = await supabase
    .from("user")
    .select("id, first_name, last_name, email")
    .eq("id", userId)
    .eq("active", true)
    .maybeSingle();

  if (error) {
    console.error("Error user by spot id:", error);
    throw error;
  }

  return user;
}

async function reserveSpot(
  userId: string,
  queueId: string,
  num_spots: number,
  currentSpotPrice = 0
) {
  // Check if the maximum number of spots has been reached
  const { count: reservedCount, error: countError } = await supabase
    .from("spot")
    .select("*", { count: "exact", head: true })
    .eq("queue_id", queueId)
    .eq("active", true);

  if (countError) {
    console.error("Error retrieving spot count:", countError);
    throw countError;
  }

  if (reservedCount >= num_spots) {
    throw new Error("Maximum number of spots reached for this queue.");
  }

  /*
    Our whole unreserve flow is heavily dependent on this being null by default if there is no spot.
    Otherwise it will immediately remove a free reservation for anyone. Probably a better way to do this, but it works
    great for now, just gotta be mindful of this
  */
  let stripePrice = null;

  // Create a new spot with the user tied to it.
  if (
    currentSpotPrice !== 0 &&
    currentSpotPrice !== null &&
    currentSpotPrice !== undefined
  ) {
    stripePrice = currentSpotPrice * 100;
  }
  const { data, error } = await supabase
    .from("spot")
    .insert({
      queue_id: queueId,
      purchased_by: userId,
      ...(stripePrice !== null && { stripe_amount_total: stripePrice }),
    })
    .select("*");

  if (error) {
    console.error("Error reserving spot:", error);
    throw error;
  }

  return data;
}

async function removeReservation(spotId: string) {
  const { data, error } = await supabase
    .from("spot")
    .update({ active: false })
    .eq("id", spotId)
    .select("*");

  if (error) {
    console.error("Error removing reservation:", error);
    throw error;
  }

  return data;
}

export const spotService = {
  getUserBySpot,
  getSpotById,
  reserveSpot,
  removeReservation,
  getAttendeesByQueue,
  validateSpot,
};
