import { IonButton, useIonToast } from "@ionic/react";
import React, { useRef } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { eventService } from "../../service/eventService";
import { useQuery } from "react-query";
import { Loader } from "../core/Loader";
import { EventCard } from "../ui/EventCard";
import { Button } from "../ui/Button";
import { formatDateRange, getAddressDisplay } from "../../utils";
import { QrCode } from "../ui/QrCode";
import { TextCallout } from "../ui/TextCallout";
import { useAppSelector } from "../../redux/hooks";
import { useTranslation } from "../../TranslationContext";

interface Props {
  data?: any;
  isCurrentPageActive?: boolean;
}

type Params = {
  eventId: string;
};

const Share: React.FC<Props> = ({ isCurrentPageActive }) => {
  /* Translations */
  const { t, languageCode }: any = useTranslation();

  /* Redux */
  const { user } = useAppSelector((state) => ({ user: state.core.user }));

  /* Refs */
  const qrRef = useRef();

  /* Hooks */
  const { eventId } = useParams<Params>();
  const history = useHistory();
  const location: any = useLocation();
  const [presentToast] = useIonToast();

  /* React Query */
  const {
    data: event,
    refetch: refetchEvent,
    isLoading: isEventLoading,
  } = useQuery(
    ["event", eventId, user?.id],
    () => eventService.getEventById(eventId, user?.id, languageCode),
    {
      enabled: isCurrentPageActive && !!eventId?.length && !!user?.id?.length,
      staleTime: 1000 * 10,
    }
  );

  /* Helpers */

  const copyToClipboard = (link: any) => {
    navigator.clipboard.writeText(link).then(
      function () {
        presentToast({
          message: t("event.share.linkCopied"),
          duration: 2000,
          position: "bottom",
          cssClass: "success-toast",
        });
      },
      function (err) {
        console.error(t("event.share.copyFailed"), err);
      }
    );
  };

  /* Constants */
  const addressDisplay = getAddressDisplay(event?.address_available_date_time);

  if (isEventLoading || !isCurrentPageActive)
    return <Loader className="min-h-[200px]" />;

  return (
    <>
      <div className="grid gap-1">
        <EventCard
          line1={event?.title}
          company={event?.user?.company_name}
          date={formatDateRange(event?.queues)}
          address={{
            line1: event?.address_line_1,
            line2: event?.address_line_2,
            city_state_postal: `${event?.city}, ${event?.state} ${event?.postal_code}`,
            address_display_message: addressDisplay.displayText,
          }}
          showAddress={addressDisplay?.showAddress}
          className="!rounded-b-none"
        />
        <TextCallout
          title={t("event.share.shareEvent")}
          description={t("event.share.shareEventDescription")}
          showBoxShadow
          className="!rounded-none"
        />
        <Button
          color="gray"
          text={t("event.share.copyButton")}
          onClick={() =>
            copyToClipboard(
              `${process.env.REACT_APP_BASE_URL}/event/${event.id}`
            )
          }
          className="!rounded-none"
        />

        <QrCode
          url={`${process.env.REACT_APP_BASE_URL}/event/${event.id}`}
          className="!rounded-b-2xl"
        />

        {location?.state?.disableBack && (
          <Button
            color="black"
            text={t("event.share.backToEvents")}
            onClick={() => history.push("/events/attending")}
            className="mt-1"
          />
        )}
      </div>
    </>
  );
};

export default Share;
