import React from "react";

type HeadingProps = {
  text: string | "black" | "gray";
  color: string;
  size: string | "2xl" | "lg";
  className?: string;
};

export const Heading = ({ text, color, size, className }: HeadingProps) => {
  const getDefaultColor = () => {
    const defaultColor = "text-black-100";
    switch (color) {
      case "black":
        return defaultColor;
      case "gray":
        return "text-black-60";
      default:
        return defaultColor;
    }
  };

  const getTextSize = () => {
    const defaultSize = "text-2xl";
    if (size === "lg") {
      return `text-lg`;
    } else {
      return defaultSize;
    }
  };
  return (
    <div
      className={`font-semibold ${getTextSize()} ${getDefaultColor()} ${
        className || ""
      }`}
    >
      {text}
    </div>
  );
};
