import React, { createContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "./redux/hooks";
import { setHasFetchedUser, setSession, setUser } from "./redux/core";
import { setActiveCompany, setUserCompanies } from "./redux/company";
import { userService } from "./service/userService";
import { supabase } from "./supabaseClient";
import { useLocation } from "react-router-dom";

export const AuthContext = createContext(null);

export const AuthProvider = ({ children }: any) => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(true);

  const { session, user, activeCompany } = useAppSelector((state) => ({
    session: state.core.session,
    user: state.core.user,
    activeCompany: state.company.activeCompany,
  }));

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const checkUser = async () => {
      setIsLoading(true);
      dispatch(setHasFetchedUser(false));
      if (session?.user?.id && !Object.keys(user || {})?.length) {
        try {
          const user: any = await userService.getUserById(session.user.id);

          /* 
            We only want to show the my events tab if there is a company associated with the user.
            Here, we also set the active company to the first company in the list. We'll let the user
            change it later in the UI if they have 2 companies

            If the user has created a company, we should default it to the active company over 
            any other company in the list
          */
          if (!activeCompany?.id && user?.user_companies?.length > 0) {
            const companiesCreatedByUser = user?.user_companies?.filter(
              (company: any) => company?.company?.created_by === user?.id
            );
            dispatch(setUserCompanies(companiesCreatedByUser));

            /* */
            dispatch(
              setActiveCompany(
                companiesCreatedByUser?.length
                  ? companiesCreatedByUser?.[0]?.company
                  : user?.user_companies[0]?.company
              )
            );
          }

          if (user?.active === false) {
            await userService.markUserAsActive(user.id);
          }
          if (user !== null || user?.id) {
            dispatch(setUser(user));
          }
          /* 
            Since we have multiple onboarding screens, we don't want to redirect if user is already going through it
            Since first name is required, we can just base onboarding off that for now in the case that a user was added 
            by someone else
          */
          if (
            (user === null ||
              !user?.id ||
              user?.first_name === null ||
              !user?.first_name) &&
            !location.pathname?.toLowerCase()?.includes("onboarding")
          ) {
            history.push("/onboarding/info");
          } else if (
            user !== null &&
            user?.id &&
            location.pathname?.toLowerCase()?.includes("onboarding")
          ) {
            history.push("/");
          }
        } catch (error) {
          console.error("Error fetching user:", error);
        }
      }
      dispatch(setHasFetchedUser(true));

      setIsLoading(false);
    };

    checkUser();
  }, [session, user, dispatch, history]);

  useEffect(() => {
    userService.getUserSession().then(({ session }) => {
      dispatch(setSession(session));
      setIsLoading(false);
    });

    const { data: authListener } = supabase.auth.onAuthStateChange(
      async (_event, session) => {
        dispatch(setSession(session));
        setIsLoading(false);
      }
    );
  }, [dispatch]);

  return (
    <AuthContext.Provider value={{ isLoading, session, user }}>
      {children}
    </AuthContext.Provider>
  );
};
