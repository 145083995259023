import {
  updateQueue,
  resetQueue,
  addQueue,
  removeQueue,
  setHasSubmitted,
  setQueue,
  setInitialStateInfo,
} from "../redux/queue";
import { useAppSelector, useAppDispatch } from "../redux/hooks";
import validateFields from "../utils/validation";
import { useEffect, useRef } from "react";
export const useQueue = () => {
  /* Redux */
  const dispatch = useAppDispatch();
  const { initialState, queue, queueInfo, hasSubmitted, activeCompany } =
    useAppSelector((state) => ({
      initialState: state.queue,
      queue: state.queue,
      queueInfo: state.queue.info,
      hasSubmitted: state.queue.hasSubmitted,
      activeCompany: state.company.activeCompany,
    }));

  /* Cache this in a ref so it doesn't take the updated values on the fly */
  const cachedInitialQueue = useRef<any>(initialState.info[0]);

  /* 
    If spanish is enbled, change the class on the title input, and add a new title_spanish input
  */

  useEffect(() => {
    if (activeCompany?.supports_spanish) {
      const newQueueInfo = queueInfo.map((singleQueue: any) => {
        const updatedQueue: any = {};

        Object.keys(singleQueue).forEach((key) => {
          if (key === "description") {
            updatedQueue[key] = {
              ...singleQueue[key],
              className: "rounded-b-none border-b-0",
            };
            updatedQueue["description_spanish"] = {
              label: "Queue Description (Spanish)",
              value: "",
              type: "text",
              valid: false,
              required: false,
              className: "mb-4 rounded-t-none",
            };
          } else {
            updatedQueue[key] = singleQueue[key];
          }
        });

        return updatedQueue;
      });

      cachedInitialQueue.current = newQueueInfo[0];

      dispatch(setInitialStateInfo(newQueueInfo));
    }
  }, [activeCompany?.supports_spanish]);

  const getQueueFieldsValid = (queueInfo: any) => {
    return !Object.keys(queueInfo).some(
      (key) => queueInfo[key].valid === false && !queueInfo[key].hidden
    );
  };

  /*
    This is a bit different than event validation because its an array of objects
    -- here we validate each one 
  */
  const validateQueue = () => {
    const validatedQueueInfo = queueInfo.map(validateFields);

    const areAllQueuesValid = validatedQueueInfo.every(getQueueFieldsValid);
    dispatch(setHasSubmitted(true));
    dispatch(setQueue(validatedQueueInfo));
    return areAllQueuesValid;
  };

  /*
    This is a generic name, but basically we're just checking for spanish to add that new field
    on each new queue, using a ref so that it gets cached to the initial state
  */
  const addQueueWithCondition = () => {
    const newQueue: any = { ...cachedInitialQueue?.current };

    if (activeCompany?.supports_spanish) {
      newQueue["description_spanish"] = {
        label: "Queue Description (Spanish)",
        value: "",
        type: "text",
        valid: false,
        required: false,
        className: "mb-4 rounded-t-none",
      };
    }

    dispatch(addQueue(newQueue));
  };

  return {
    updateQueue: (queueIndex: number, key: any, value: any) => {
      dispatch(updateQueue({ queueIndex, key, value }));
    },
    addQueue: () => dispatch(addQueue()),
    addQueueWithCondition,
    resetQueue: () => dispatch(resetQueue()),
    removeQueue: (queueIndex: number) => dispatch(removeQueue(queueIndex)),
    queue,
    queueInfo,
    hasSubmitted,
    setHasSubmitted: (e: boolean) => dispatch(setHasSubmitted(e)),
    validateQueue,
    getQueueFieldsValid,
  };
};
