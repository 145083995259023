import React from "react";
import { IonContent, IonPage } from "@ionic/react";
import CreateEventForm from "../components/event/CreateEvent";
import { Header } from "../components/ui/Header";

const CreateEvent: React.FC = () => {
  return (
    <IonPage>
      <Header
        title="Create Event"
        showBackButton={true}
        backButtonHref="/events/my"
      />

      <IonContent fullscreen className="ion-padding-page">
        <CreateEventForm />
      </IonContent>
    </IonPage>
  );
};

export default CreateEvent;
