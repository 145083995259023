import { IonContent, IonFooter, useIonAlert, useIonToast } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useAccount } from "../../hooks/useAccount";
import { useCore } from "../../hooks/useCore";
import { useHistory } from "react-router";
import { Input } from "../ui/Input";
import { Header } from "../ui/Header";
import { useAppSelector } from "../../redux/hooks";
import { Button } from "../ui/Button";
import { Text } from "../ui/Text";
import { Heading } from "../ui/Heading";
import addCompany from "../../assets/add-company.svg";
import { useTranslation } from "../../TranslationContext";

const Account: React.FC = () => {
  /* Translations */
  const { t }: any = useTranslation();

  /* Redux */
  const { user } = useAppSelector((state) => ({
    user: state.core.user,
  }));

  /* Hooks */
  const [hasSetDefaultEmail, setHasSetDefaultEmail] = useState(false);

  const history = useHistory();
  const [present] = useIonToast();

  const {
    accountInfo,
    updateAccount,
    validateAccount,
    createAccount,
    hasSubmitted,
    session,
    isLoading,
  } = useAccount();

  /* Helpers */
  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    updateAccount({ key: name, value: value });
  };

  const handleSubmit = async (accountType: string) => {
    try {
      if (accountType === "COMPANY") {
        validateAccount(() => {
          history.push("/onboarding/company");
        });
      } else {
        createAccount(() => {
          history.push("/");
        });
      }
    } catch (error) {
      console.log("Error:", error);
      present({
        message: t("onboarding.info.errorSaving"),
        duration: 3000,
        position: "bottom",
      });
    }
  };

  /* 
    We've already asked the user for their email
    to get the magic link, so we should auto-populate it here
  */
  useEffect(() => {
    if (session?.user?.email && !hasSetDefaultEmail) {
      setHasSetDefaultEmail(true);
      updateAccount({ key: "email", value: session?.user?.email });
    }
  }, [session, hasSetDefaultEmail]);

  return (
    <>
      <Header showBackButton={false} progressPct={50} type="ONBOARDING" />
      <IonContent className="ion-padding-page">
        <Heading
          text={t("onboarding.info.tellUsAboutYourself")}
          color="black"
          size="lg"
          className={`text-center mb-5`}
        />
        <div className="mt-2">
          {Object.keys(accountInfo).map(
            (key, index) =>
              !(accountInfo as any)?.[key as any].hidden && (
                <div className="block" key={index}>
                  <Input
                    label={`${t(`onboarding.info.${key}`)} ${
                      (accountInfo as any)[key].required ? "*" : ""
                    }`}
                    placeholder={t(`onboarding.info.${key}`)}
                    value={(accountInfo as any)[key].value}
                    onIonInput={handleInputChange}
                    name={key}
                    type={(accountInfo as any)?.[key].type}
                    className={(accountInfo as any)?.[key].className}
                    errorMessage={
                      !(accountInfo as any)?.[key]?.valid &&
                      (accountInfo as any)?.[key]?.required &&
                      hasSubmitted && (
                        <div className="text-xs text-red-100">
                          {t("account.info.enterValid")}{" "}
                          {t(`onboarding.info.${key}`)}
                        </div>
                      )
                    }
                  ></Input>
                </div>
              )
          )}
        </div>
        <div
          onClick={() => handleSubmit("COMPANY")}
          className={`mt-4 py-3 px-4 cursor-pointer w-full inline-flex items-center gap-2 border justify-center rounded-lg text-sm bg-black-5 text-black-100 border-black-10`}
        >
          <img src={addCompany} alt="Add company" className="h-8 w-8" />
          <div>
            <Text
              text={t("onboarding.info.addCompanyTitle")}
              color="black"
              className="font-semibold tracking-tight"
            />
            <Text
              text={t("onboarding.info.addCompanyDescription")}
              color="gray"
              size="sm"
            />
          </div>
        </div>
      </IonContent>
      <IonFooter>
        <div className="w-full text-black-100 flex justify-end items-center p-4 bg-appBg">
          <Button
            color="black"
            onClick={() => handleSubmit("CUSTOMER")}
            text={
              isLoading
                ? t("onboarding.info.creating")
                : t("onboarding.info.continue")
            }
            disabled={isLoading}
          />
        </div>
      </IonFooter>
    </>
  );
};

export default Account;
