import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { useIonViewDidEnter, useIonViewDidLeave } from "@ionic/react";

export const useCurrentPageActive = () => {
  const location = useLocation();
  const [isCurrentPageActive, setIsCurrentPageActive] = useState(false);

  useIonViewDidEnter(() => {
    // This will run when the view has entered and is now the active page.
    setIsCurrentPageActive(true);
  });

  useIonViewDidLeave(() => {
    // This will run when the current view has finished leaving.
    setIsCurrentPageActive(false);
  });

  return isCurrentPageActive;
};
