import React from "react";
import { IonItem, IonLabel, IonList, IonListHeader } from "@ionic/react";
import { isAfter, isToday, isBefore, format, endOfDay } from "date-fns";
import { useMemo } from "react";
import { Link, useHistory } from "react-router-dom";
import { Loader } from "../core/Loader";
import { ListContainer } from "../ui/ListContainer";
import { ListDisplayBox } from "../ui/ListDisplayBox";
import { ListItem } from "../ui/ListItem";
import { calculateSpotPrice, convertStripePriceToDollars } from "../../utils";
import { useTranslation } from "../../TranslationContext";

/* Helpers */
const isOngoing = (eventDate: any) => {
  const now = new Date();
  const startOfToday = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate()
  );
  return (
    isAfter(new Date(eventDate), startOfToday) &&
    isBefore(new Date(eventDate), now)
  );
};

const List = ({ data, isLoading, type }: any) => {
  /* Translations */
  const { t }: any = useTranslation();

  /* Hooks */
  const history = useHistory();
  const upcomingEvents = useMemo(() => {
    return data?.filter((event: any) => {
      const eventDate = new Date(event.queues?.[0]?.start_date_time);
      // check if the event is strictly after today
      return isAfter(eventDate, endOfDay(new Date()));
    });
  }, [data]);

  const todayEvents = useMemo(() => {
    return data?.filter((event: any) =>
      isToday(new Date(event.queues?.[0]?.start_date_time))
    );
  }, [data]);

  const previousEvents = useMemo(() => {
    return data?.filter((event: any) => {
      const eventDate = new Date(event.queues?.[0]?.start_date_time);
      return isBefore(eventDate, new Date()) && !isOngoing(eventDate);
    });
  }, [data]);

  /* Helpers */
  const getLineDetails = (event: any, queues: any, type: string): any => {
    let costItem;

    if (type === "MY_EVENTS") {
      /* Display the sum of all the stripe transactions for each queue */
      costItem = {
        value: `$${convertStripePriceToDollars(
          queues?.reduce(
            (acc: any, queue: any) => acc + queue?.total_stripe_amount,
            0
          )
        )} ${t("events.list.earned")}`,
        color: "green",
      };
    } else if (type === "ATTENDING") {
      costItem = {
        value: `${t("events.list.spot")} #${
          queues?.find((queue: any) => queue?.derived_spot_number > 0)
            ?.derived_spot_number
        }`,
        color: "green",
      };
    } else {
      /* Get the current price of the earliest queue at an event */
      /* 
        Depending on user feedback, we may want to show the current
        'spots available' number instead of the price
        (event.spot_count - event.purchased_spot_count) which would be something like this
      */
      const currentPrice = calculateSpotPrice(queues?.[0]);
      costItem = {
        value: `${
          currentPrice > 0 ? `$${currentPrice}` : t("events.list.free")
        }`,
        color: "green",
      };
    }

    return [
      costItem,
      {
        value:
          event.address_line_1 ||
          `${event?.city}, ${event?.state} ${event?.postal_code}`,
        color: "gray",
      },
    ];
  };

  /*
    For attending events, we explode out the queues into their own list items
  */
  const renderEvents = (events: any[], boxType = "DATE") => (
    <>
      {events?.map((event: any, index: any) =>
        type === "ATTENDING" ? (
          event.queues
            .filter((queue: any) => queue.derived_spot_number > 0)
            ?.map((queue: any, queueIndex: any) => (
              <ListItem
                key={`${index}-${queueIndex}`}
                line1={queue.event.title}
                line2Items={
                  type === "ATTENDING"
                    ? [
                        {
                          value: queue?.description,
                          color: "gray",
                        },
                      ]
                    : []
                }
                line3Items={getLineDetails(event, event.queues, type)}
                displayBox={
                  <ListDisplayBox
                    type={boxType}
                    label={
                      boxType === "DATE"
                        ? format(new Date(queue.start_date_time), "MMM")
                        : format(new Date(queue.start_date_time), "h:mm")
                    }
                    value={
                      boxType === "DATE"
                        ? format(new Date(queue.start_date_time), "d")
                        : format(new Date(queue.start_date_time), "a")
                    }
                  />
                }
                iconPlacement="TOP"
                onClick={() => {
                  if (type === "ATTENDING") {
                    history.push(`/event/${event.id}/redeem/${queue.id}`);
                  } else {
                    history.push(`/event/${queue.event.id}`);
                  }
                }}
              />
            ))
        ) : (
          <ListItem
            key={index}
            line1={event.title}
            line2Items={getLineDetails(event, event.queues, type)}
            displayBox={
              <ListDisplayBox
                type={boxType}
                label={
                  boxType === "DATE"
                    ? format(
                        new Date(event.queues?.[0]?.start_date_time),
                        "MMM"
                      )
                    : format(
                        new Date(event.queues?.[0]?.start_date_time),
                        "h:mm"
                      )
                }
                value={
                  boxType === "DATE"
                    ? format(new Date(event.queues?.[0]?.start_date_time), "d")
                    : format(new Date(event.queues?.[0]?.start_date_time), "a")
                }
              />
            }
            iconPlacement="TOP"
            onClick={() => history.push(`/event/${event.id}`)}
          />
        )
      )}
    </>
  );

  return (
    <IonList>
      {isLoading ? (
        <Loader className="min-h-[200px]" />
      ) : (
        <>
          {todayEvents.length > 0 && (
            <ListContainer
              title={t("events.list.today")}
              type="OPEN"
              className="list-gradient"
              showBorder
              removeTopPadding={true}
            >
              {renderEvents(todayEvents, "TIME")}
            </ListContainer>
          )}

          {upcomingEvents.length > 0 && (
            <ListContainer
              title={t("events.list.upcoming")}
              type="OPEN"
              showBorder
              removeTopPadding={todayEvents?.length === 0}
            >
              {renderEvents(upcomingEvents)}
            </ListContainer>
          )}

          {previousEvents.length > 0 && (
            <ListContainer
              title={t("events.list.past")}
              type="OPEN"
              showBorder
              removeTopPadding={
                upcomingEvents?.length === 0 && todayEvents?.length === 0
              }
            >
              {renderEvents(previousEvents)}
            </ListContainer>
          )}
        </>
      )}
    </IonList>
  );
};

export default List;
