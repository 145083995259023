import { IonContent, IonIcon, useIonAlert, useIonToast } from "@ionic/react";
import React, { useState } from "react";
import { useCompany } from "../../hooks/useCompany";
import { companyService } from "../../service/companyService";
import { Input } from "../ui/Input";
import { Header } from "../ui/Header";
import { Button } from "../ui/Button";
import { Text } from "../ui/Text";
import { add, closeCircleOutline } from "ionicons/icons";
import { useQuery } from "react-query";
import { Heading } from "../ui/Heading";

const Company: React.FC = () => {
  /* Hooks */
  const [present] = useIonToast();
  const [presentAlert] = useIonAlert();
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");

  const { userCompanies, user } = useCompany();

  const defaultUserCompany = userCompanies?.[0]?.company;

  /* Helpers */
  const handleSubmit = async () => {
    if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      setIsLoading(true);
      try {
        await companyService.addUserToCompany(
          email,
          defaultUserCompany.id,
          user?.id
        );
        setIsLoading(false);
        setEmail("");
        refetchUsers();
        present({
          message: "User successfully added",
          color: "success",
          duration: 3000,
          position: "bottom",
        });
      } catch (error) {
        setIsLoading(false);
        present({
          message: "There was an error adding the user to your company",
          duration: 3000,
          position: "bottom",
        });
      }
    } else {
      present({
        message: "Please enter a valid email",
        duration: 3000,
        position: "bottom",
      });
    }
  };

  const handleRemoveUser = async (userId: string) => {
    presentAlert({
      header: "Are you sure you want to remove this user?",
      message: `This user will no longer have access to your company's events.`,
      buttons: [
        { text: "Cancel", role: "cancel" },
        {
          text: "Remove",
          role: "delete",
          handler: async () => {
            await companyService.removeUserFromCompany(
              userId,
              defaultUserCompany.id
            );
            refetchUsers();
            present({
              message: "User successfully removed",
              color: "success",
              duration: 3000,
              position: "bottom",
            });
          },
        },
      ],
    });
  };

  /* React Query */
  const {
    data: users,
    refetch: refetchUsers,
    isLoading: isUsersLoading,
  } = useQuery(
    [user?.id],
    async () =>
      companyService
        .getCompanyUsers(defaultUserCompany.id)
        .then((res) =>
          res?.filter((userItem: any) => userItem.user_id !== user?.id)
        ),
    {
      enabled: !!user?.id,
      retry: 0,
    }
  );

  return (
    <>
      <Header
        title={"Manage Users"}
        showBackButton={true}
        backButtonHref="/account"
      />
      <IonContent className="ion-padding-page">
        <div className="mt-2">
          <Text
            text="If you want to allow employees to manage your events, you can add their emails here.  You can manage these users any time in your settings page."
            color="gray"
            className="mt-1 mb-5 text-center leading-5"
          />
          <Input
            label="Email Address"
            type="email"
            placeholder="name@example.com"
            value={email}
            required={true}
            onIonInput={(e: any) => {
              setEmail(e.target.value);
            }}
          />
          <Button
            color="gray"
            onClick={handleSubmit}
            icon={<IonIcon className="ion-icon-event-override" icon={add} />}
            className="mt-2"
            text={isLoading ? "Adding User..." : "Add User"}
            disabled={isLoading}
          />

          {users?.length ? (
            <div
              className={`mt-8 p-4 pt-5 pb-3 bg-white-100 rounded-2xl shadow-[0_1px_2px_-0px_rgba(0,0,0,0.1)]`}
            >
              {isUsersLoading  ? (
                <Text size="base" color="gray" text="Loading users..." />
              ) : (
                <>
                  <Heading size="lg" color="black" text="Authorized Users" />
                  {users?.map((user: any, index: number) => (
                    <div key={index}>
                      <div
                        className={`${
                          index < users?.length - 1
                            ? "border-b border-b-black-10"
                            : ""
                        } py-4 flex justify-between items-center cursor-pointer`}
                      >
                        <div>
                          <Text
                            size="base"
                            color="black"
                            text={
                              user?.user?.first_name !== null
                                ? `${user?.user?.first_name} ${user?.user?.last_name}`
                                : "New user"
                            }
                            className="font-medium"
                          />
                          <Text
                            size="base"
                            color="gray"
                            text={user?.user?.email}
                            className="-mt-1"
                          />
                        </div>
                        <div
                          className="ion-icon-color-override"
                          onClick={() => handleRemoveUser(user?.user_id)}
                        >
                          <IonIcon icon={closeCircleOutline} />
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              )}
            </div>
          ) : null}
        </div>
      </IonContent>
    </>
  );
};

export default Company;
