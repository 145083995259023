import React from "react";
import { GridChart } from "../ui/GridChart";
import { useTranslation } from "../../TranslationContext";

const calculateSpotPrice = (queue: any, day: number, freeTranslation: any) => {
  if (day <= 0) {
    return freeTranslation;
  }

  return `$${day * queue.spot_increment}`;
};

const PricingChart = ({ queue }: any) => {
  /* Translations */
  const { t }: any = useTranslation();

  if (
    !Object.keys(queue).length ||
    !queue.max_spot_cost ||
    !queue.num_spots ||
    !queue.spot_increment
  )
    return null;

  const maxDaysUntilStart = Math.floor(
    queue.max_spot_cost / queue.spot_increment
  );
  const days = new Array(Math.floor(maxDaysUntilStart) + 1)
    .fill(0)
    .map((_, i) => i);

  const items = days.map((day, index, array) => {
    const price = calculateSpotPrice(queue, day, t("queue.pricingChart.free"));
    let key = "";

    if (day === 0) {
      key = t("queue.pricingChart.dayOfEvent");
    } else if (day === 1) {
      key = t("queue.pricingChart.hrsBefore");
    } else if (index === array.length - 1) {
      key = `${day} ${t("queue.pricingChart.daysBefore")}`;
    } else {
      key = `${day} ${
        day > 1 ? t("queue.pricingChart.days") : t("queue.pricingChart.day")
      } ${t("queue.pricingChart.before")}`;
    }

    const value = price;

    return { key, value };
  });

  return (
    <GridChart title={t("queue.pricingChart.pricingGuide")} items={items} />
  );
};

export default PricingChart;
