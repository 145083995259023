import React from "react";

type ListDisplayBoxProps = {
  label?: string;
  value: string;
  type?: string | "DEFAULT" | "DATE" | "TIME";
};

export const ListDisplayBox = ({ label, value, type }: ListDisplayBoxProps) => (
  <div className="bg-bronze-10 text-bronze-100 capitalize aspect-square p-1 flex flex-col items-center justify-center rounded-lg">
    {label && (
      <div
        className={`${
          type === "TIME"
            ? "text-sm font-semibold -mb-1 tracking-tight"
            : "text-sm -mt-1"
        }  text-center tracking-wide`}
      >
        {label}
      </div>
    )}
    <div
      className={`${
        type === "TIME" ? "text-xs" : "text-xl font-semibold"
      } text-center leading-4 flex items-center justify-center`}
    >
      {value}
    </div>
  </div>
);
