import { supabase } from "../supabaseClient";
import { edgeService } from "./edgeService";

async function getCompanyById(companyId: string) {
  const { data: company, error } = await supabase
    .from("company")
    .select("*")
    .eq("id", companyId)
    .maybeSingle();

  if (error) {
    console.error("Error fetching company:", error);
    throw error;
  }

  return company;
}

async function insertCompany(sessionUserId: string, company: any) {
  const { data, error } = await supabase
    .from("company")
    .upsert([
      {
        ...company,
        created_by: sessionUserId,
      },
    ])
    .select("*");

  if (error) {
    console.error("Error inserting company:", error);
    throw error;
  }

  const { data: userCompany, error: userCompanyError } = await supabase
    .from("user_company")
    .insert({
      user_id: sessionUserId,
      company_id: data?.[0]?.id,
      role: "ADMIN", // when creating a company, the user is automatically an admin
    });

  if (userCompanyError) {
    console.error("Error inserting user_company:", userCompanyError);
    throw userCompanyError;
  }

  return data;
}

async function updateCompany(companyId: string, company: any) {
  const { data, error } = await supabase
    .from("company")
    .update(company)
    .eq("id", companyId)
    .select();

  if (error) {
    console.error("Error updating company:", error);
    throw error;
  }

  return data?.[0];
}

async function markCopanyAsDeleted(companyId: string) {
  const { data, error } = await supabase
    .from("company")
    .update({ active: false })
    .eq("id", companyId);

  if (error) {
    console.error("Error marking company as inactive:", error);
    throw error;
  }

  return data;
}

async function getCompaniesByUserId(userId: string) {
  const { data: companies, error } = await supabase
    .from("user_company")
    .select(
      `*,
      company:company_id (
        id,
        name,
        supports_spanish
      )`
    )
    .eq("user_id", userId)
    .eq("active", true);

  if (error) {
    console.error("Error fetching companies:", error);
    throw error;
  }

  return companies;
}

async function addUserToCompany(
  userEmail: string,
  companyId: string,
  createdBy: string
) {
  let user;
  const { data: userRes, error: userError } = await supabase
    .from("user")
    .select("*")
    .eq("email", userEmail)
    .maybeSingle();

  user = userRes;

  if (userError) {
    console.error("Error fetching user while adding to company:", userError);
    throw userError;
  }

  if (!user) {
    user = await await edgeService.createAuthUser(userEmail, createdBy);
  }

  const { data: userCompany, error: userCompanyError } = await supabase
    .from("user_company")
    .insert({
      user_id: user?.id,
      company_id: companyId,
      role: "AUTHORIZED_USER", // when adding a user to a company, the user is automatically an authorized user
    });

  if (userCompanyError) {
    console.error("Error inserting user_company:", userCompanyError);
    throw userCompanyError;
  }

  return userCompany;
}

async function removeUserFromCompany(userId: string, companyId: string) {
  const { data: userCompany, error: userCompanyError } = await supabase
    .from("user_company")
    .update({ active: false })
    .eq("user_id", userId)
    .eq("company_id", companyId);

  if (userCompanyError) {
    console.error("Error removing user_company:", userCompanyError);
    throw userCompanyError;
  }

  return userCompany;
}

async function getCompanyUsers(companyId: string) {
  const { data: users, error } = await supabase
    .from("user_company")
    .select(
      `
      *,
      user(first_name, last_name, email)
    `
    )
    .eq("company_id", companyId)
    .eq("active", true);

  if (error) {
    console.error("Error fetching users:", error);
    throw error;
  }

  return users;
}

export const companyService = {
  getCompanyById,
  insertCompany,
  updateCompany,
  markCopanyAsDeleted,
  getCompaniesByUserId,
  addUserToCompany,
  getCompanyUsers,
  removeUserFromCompany,
};
