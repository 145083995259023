import {
  IonCheckbox,
  IonContent,
  IonModal,
  IonRefresher,
  IonRefresherContent,
  useIonViewDidEnter,
  useIonViewDidLeave,
} from "@ionic/react";
import React, { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { useEvents } from "../../hooks/useEvents";
import List from "./List";
import { Text } from "../ui/Text";
import { useEvent } from "../../hooks/useEvent";
import { Button } from "../ui/Button";
import { Header } from "../ui/Header";
import { TextCallout } from "../ui/TextCallout";
import { useHistory } from "react-router";
import { useQueue } from "../../hooks/useQueue";
import { setActiveCompany } from "../../redux/company";
import { useTranslation } from "../../TranslationContext";

interface Props {
  isCurrentPageActive: boolean;
  type: "ATTENDING" | "MY_EVENTS" | "EXPLORE";
}

const EventList: React.FC<Props> = ({
  isCurrentPageActive,
  type = "ATTENDING",
}) => {
  /* Translations */
  const { t }: any = useTranslation();

  /* Redux */
  const { user, activeCompany } = useAppSelector((state) => ({
    user: state.core.user,
    activeCompany: state.company.activeCompany,
  }));
  const dispatch = useAppDispatch();
  /* Hooks */
  const {
    currentSegmentData,
    refreshData,
    clearExploreData,
    loadNextPage,
    postalCode,
    setPostalCode,
    userCompanies,
  } = useEvents({
    type,
    userId: user?.id,
    isCurrentPageActive,
  });

  const { resetEvent, setEventFields } = useEvent();
  const { resetQueue } = useQueue();
  const history = useHistory();
  const [isSelectModalOpen, setIsSelectModalOpen] = useState(false);
  const [hasMounted, setHasMounted] = useState(false);

  /* Refs */
  const selectorModal = useRef<HTMLIonModalElement>(null);

  /* Helpers */
  const handleInput = (ev: Event) => {
    let query = "";
    const target = ev.target as HTMLIonSearchbarElement;
    if (target) query = target.value!.toLowerCase();
    setPostalCode(query);
  };

  const handleRefresh = async (event: CustomEvent) => {
    refreshData();
    setTimeout(() => {
      event.detail.complete();
    }, 2000);
  };

  /* Ionic lifecycle */
  useIonViewDidEnter(() => {
    resetEvent();
    resetQueue();
    setHasMounted(true);
  });

  /* 
    We're using hasMounted to know when we should set our event fields (lang based)
    since reset always fires first
  */
  useIonViewDidLeave(() => {
    setHasMounted(false);
  });

  /* Once we know the view has entered, set the fields accordingly */
  useEffect(() => {
    if (hasMounted) {
      setEventFields();
    }
  }, [hasMounted]);

  /* Constants */
  const { isLoading, hasNextPage, data } = currentSegmentData;

  /*
    All Events vs My Events are different, moving up here just for readability 
  */
  const Events = () => {
    const allEvents = data?.pages?.flatMap((page: any) => page) || [];
    return (
      <React.Fragment>
        <List data={allEvents} isLoading={isLoading} type={type} />
      </React.Fragment>
    );
  };

  return (
    <>
      {/* 
        In all other places, we put the header in the Page component, but since this EventList is re-used in multiple places, it was less code to just
        put this one conditional instead of lifting our state up for all 3 pages components.
      */}
      {type === "EXPLORE" && (
        <Header
          title={t("events.explore")}
          type="SEARCH"
          searchHandler={(ev) => handleInput(ev)}
          searchValue={postalCode}
          searchPlaceholder={t("events.searchPlaceholder")}
          searchClearHandler={() => {
            setPostalCode("");
            clearExploreData();
          }}
        />
      )}
      {type === "MY_EVENTS" && (
        <Header
          title={
            <div
              className="grid grid-cols-[30px,auto] gap-2 items-center"
              onClick={() => setIsSelectModalOpen(true)}
            >
              <div className="w-full aspect-square border border-bronze-25 bg-bronze-10 text-bronze-100 text-center text-base flex items-center justify-center rounded-lg">
                {activeCompany?.name?.charAt(0)}
              </div>
              <div className="text-base text-ellipsis overflow-hidden">
                {activeCompany?.name || t("events.myEvents")}
              </div>
            </div>
          }
          type="DISPLAY"
          actionLabel={t("events.createEvent")}
          actionHandler={() => history.push("/create/event")}
        />
      )}
      <IonContent fullscreen>
        <IonRefresher
          slot="fixed"
          className="mt-8"
          onIonRefresh={handleRefresh}
        >
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        <Events />
        {/* Loading States */}
        <div className="p-4">
          {isLoading && (
            <Text
              color="gray"
              text={t("events.loading")}
              className="text-center"
              size="sm"
            />
          )}
          {hasNextPage && (
            <Button
              color="gray"
              text={t("events.loadMore")}
              onClick={loadNextPage}
            />
          )}
          {!hasNextPage && !isLoading && (
            <Text
              color="gray"
              text={
                data?.pages?.[0]?.length === 0
                  ? type === "EXPLORE"
                    ? t("events.noResults")
                    : ""
                  : t("events.endOfList")
              }
              className="text-center"
              size="sm"
            />
          )}
          {/* No Events CTAs -- */}
          {!isLoading &&
            !data?.pages?.[0]?.length &&
            type !== "EXPLORE" &&
            user?.id && (
              <>
                <TextCallout
                  title={`${t("events.noEvent.segment1")} ${
                    type === "MY_EVENTS"
                      ? t("events.noEvent.segment2")
                      : t("events.noEvent.segment3")
                  } ${t("events.noEvent.segment4")}`}
                  description={
                    type === "MY_EVENTS"
                      ? t("events.noEvent.description1")
                      : t("events.noEvent.description2")
                  }
                  className="!bg-transparent text-center"
                  titleClassName="!text-base"
                />
                <Button
                  color="gray"
                  text={
                    type === "MY_EVENTS"
                      ? t("events.createEvent")
                      : t("events.findNearMe")
                  }
                  onClick={() => {
                    if (type === "MY_EVENTS") {
                      history.push("/create/event");
                    } else {
                      history.push("/events/explore");
                    }
                  }}
                />
              </>
            )}

          {/* Selector Modal */}
          {type === "MY_EVENTS" && (
            <IonModal
              ref={selectorModal}
              isOpen={isSelectModalOpen}
              initialBreakpoint={0.25}
              breakpoints={[0, 0.25]}
              onDidDismiss={() => setIsSelectModalOpen(false)}
            >
              <div className="p-4">
                <div>
                  {userCompanies?.map((company: any, index: any) => (
                    <div
                      key={index}
                      onClick={() => {
                        dispatch(setActiveCompany(company?.company));
                        setIsSelectModalOpen(false);
                      }}
                    >
                      <div
                        className={`${
                          index < userCompanies?.length - 1
                            ? "border-b border-b-black-10"
                            : ""
                        } py-4 flex justify-between items-center cursor-pointer`}
                      >
                        <IonCheckbox
                          checked={activeCompany?.id === company?.company?.id}
                          labelPlacement="end"
                          color="dark"
                        >
                          <Text
                            size="base"
                            color="black"
                            text={company?.company?.name}
                            className="font-medium"
                          />
                        </IonCheckbox>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </IonModal>
          )}
        </div>
      </IonContent>
    </>
  );
};

export default EventList;
