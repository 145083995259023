import React from "react";
import { IonPage } from "@ionic/react";
import { useHistory } from "react-router";
import EventList from "../components/events/EventList";
import { useCurrentPageActive } from "../hooks/useCurrentPageActive";
import { Header } from "../components/ui/Header";

const ExploreEvents: React.FC = () => {
  /* Hooks */
  const isCurrentPageActive = useCurrentPageActive();
  const history = useHistory();

  return (
    <IonPage>
      <EventList isCurrentPageActive={isCurrentPageActive} type="EXPLORE" />
    </IonPage>
  );
};

export default ExploreEvents;
