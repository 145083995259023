import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface Input {
  label: string;
  value: string;
  type?: string;
  hidden?: boolean;
  options?: any[];
  valid: boolean;
  className?: string;
  required?: boolean;
}
interface Account {
  first_name: Input;
  last_name: Input;
  email: Input;
  phone: Input;
  city: Input;
  state: Input;
  postal_code: Input;
  stripe_customer_id: Input;
  stripe_connect_id: Input;
  stripe_subscription_id: Input;
}

interface AccountState {
  info: Account;
  isLoading: boolean;
  error: string | null;
  lastFetchTimestamp: number | null;
  hasSubmitted: boolean;
}

const initialState: AccountState = {
  info: {
    first_name: {
      value: "",
      label: "First Name",
      type: "text",
      valid: false,
      required: true,
      className: "rounded-b-none border-b-0",
    },
    last_name: {
      value: "",
      label: "Last Name",
      type: "text",
      valid: false,
      required: true,
      className: "rounded-t-none mb-4",
    },
    email: {
      value: "",
      label: "Email",
      type: "email",
      valid: false,
      required: true,
      className: "rounded-b-none border-b-0",
    },
    phone: {
      value: "",
      label: "Phone Number",
      type: "tel",
      valid: false,
      required: false,
      className: "rounded-t-none mb-4",
    },
    city: {
      value: "",
      label: "City",
      type: "text",
      valid: false,
      required: false,
      hidden: true,
    },
    state: {
      value: "",
      label: "State",
      type: "text",
      valid: false,
      required: false,
      hidden: true,
    },
    postal_code: {
      value: "",
      label: "Postal Code",
      type: "text",
      valid: false,
      required: false,
    },
    stripe_customer_id: {
      value: "",
      label: "Stripe Customer ID",
      hidden: true,
      valid: false,
      required: false,
    },
    stripe_connect_id: {
      value: "",
      label: "Stripe Connect ID",
      hidden: true,
      valid: false,
      required: false,
    },
    stripe_subscription_id: {
      value: "",
      label: "Stripe Subscription ID",
      hidden: true,
      valid: false,
      required: false,
    },
  },
  isLoading: false,
  error: null,
  lastFetchTimestamp: null,
  hasSubmitted: false,
};

export const account = createSlice({
  name: "account",
  initialState,
  reducers: {
    fetchAccountStart: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    fetchAccountSuccess: (state, action: PayloadAction<any[]>) => {
      state.info = {
        ...state.info,
        ...action.payload,
      };
      state.isLoading = false;
    },
    updateAccount: (
      state,
      action: PayloadAction<{ key: keyof Account; value: string }>
    ) => {
      state.info[action.payload.key].value = action.payload.value;
    },
    setAccount: (state, action: PayloadAction<any>) => {
      state.info = action.payload;
    },
    fetchAccountError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    resetAccount: (state) => {
      state.info = initialState.info;
    },
    saveAccountStart: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    saveAccountSuccess: (state, action: PayloadAction<any[]>) => {
      state.isLoading = false;
      state.error = null;
    },
    saveAccountError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    setLastFetchTimestamp: (state, action: PayloadAction<any>) => {
      state.lastFetchTimestamp = action.payload;
    },
    setHasSubmitted: (state, action: PayloadAction<boolean>) => {
      state.hasSubmitted = action.payload;
    },
  },
});

export const {
  fetchAccountStart,
  fetchAccountSuccess,
  fetchAccountError,
  resetAccount,
  setAccount,
  saveAccountStart,
  saveAccountSuccess,
  saveAccountError,
  setLastFetchTimestamp,
  updateAccount,
  setHasSubmitted,
} = account.actions;

export default account.reducer;
