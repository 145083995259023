import React from "react";
import { IonPage } from "@ionic/react";
import Company from "../components/onboarding/Company";

const OnboardingCompany: React.FC = () => {
  return (
    <IonPage>
      <Company />
    </IonPage>
  );
};

export default OnboardingCompany;
