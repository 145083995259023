import { configureStore } from "@reduxjs/toolkit";
import core from "./core";
import account from "./account";
import company from "./company";
import event from "./event";
import queue from "./queue";

import { createValidationMiddleware } from "./middleware/validation";

export const store = configureStore({
  reducer: {
    core,
    account,
    company,
    event,
    queue,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(createValidationMiddleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
