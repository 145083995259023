import React from "react";
import { IonPage } from "@ionic/react";
import AccountManageUsers from "../../components/account/ManageUsers";

const ManageUsers: React.FC = () => {
  return (
    <IonPage>
      <AccountManageUsers />
    </IonPage>
  );
};

export default ManageUsers;
