import React from "react";
import { Heading } from "./Heading";
import { Text } from "./Text";

type GridChartProps = {
  title: string;
  items: { key: string; value: string }[];
};

export const GridChart = ({ title, items }: GridChartProps) => (
  <div className="py-5">
    {title && <Heading size="lg" text={title} color="black" />}
    <div className={`grid gap-2 mt-3`}>
      {items.map((item, index) => (
        <div
          key={index}
          className={`grid grid-cols-[auto,auto] gap-2 items-center justify-between`}
        >
          <Text color="gray" text={item.key} />
          <Text color="gray" text={item.value} />
        </div>
      ))}
    </div>
  </div>
);
