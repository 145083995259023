import React from "react";
import { IonContent, IonPage } from "@ionic/react";
import ShareComponent from "../components/event/Share";
import { useLocation } from "react-router";
import { useCurrentPageActive } from "../hooks/useCurrentPageActive";
import { Header } from "../components/ui/Header";
import { useTranslation } from "../TranslationContext";

const Share: React.FC = () => {
  /* Translations */
  const { t }: any = useTranslation();

  /* Hooks */
  const location: any = useLocation();
  const isCurrentPageActive = useCurrentPageActive();

  /* Don't show the back button if its disabled (like after create) */
  return (
    <IonPage>
      <Header
        title={t("pages.share")}
        showBackButton={!location?.state?.disableBack}
        backButtonHref="/events"
      />
      <IonContent fullscreen className="ion-padding-page">
        <ShareComponent isCurrentPageActive={isCurrentPageActive} />
      </IonContent>
    </IonPage>
  );
};

export default Share;
