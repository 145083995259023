import { differenceInHours, parseISO, format } from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";
import { toPng } from "html-to-image";
import { Capacitor } from "@capacitor/core";
import { Media } from "@capacitor-community/media";
import { Directory, Filesystem } from "@capacitor/filesystem";

export const isValidPostalCode = (postalCode: string) => {
  const postalCodeRegex = /^\d{5}(-\d{4})?$/;
  return postalCodeRegex.test(postalCode);
};

export const convertToUTC = (date: any) => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  return zonedTimeToUtc(date, timezone);
};

const arrayBufferToBase64 = (buffer: any) => {
  let binary = "";
  const bytes = [].slice.call(new Uint8Array(buffer));
  bytes.forEach((b) => (binary += String.fromCharCode(b)));
  return window.btoa(binary);
};

export const downloadQR = async (qrRef: any, presentToast?: any) => {
  const dataUrl = await toPng(qrRef.current);
  const data = atob(dataUrl.split(",")[1]);
  const array = [];

  for (let i = 0; i < data.length; i++) {
    array.push(data.charCodeAt(i));
  }

  const blob = new Blob([new Uint8Array(array)], { type: "image/png" });
  const fileName = `priority-entry-qr-code-${new Date().toISOString()}.png`;

  if (presentToast) {
    presentToast();
  }

  if (Capacitor.getPlatform() === "web") {
    const link = document.createElement("a");
    link.download = fileName;
    link.href = dataUrl;
    link.click();
  } else if (Capacitor.getPlatform() === "ios") {
    const base64Data = dataUrl.split(",")[1];

    const writeResult = await Filesystem.writeFile({
      path: fileName,
      data: base64Data,
      directory: Directory.Cache,
    });

    // Save the image to the gallery
    const result = await Media.savePhoto({ path: writeResult.uri }).then(() => {
      if (presentToast) {
        presentToast();
      }
    });
  } else {
    // TODO: handle Android
  }
};

export const calculateSpotPrice = (queue: any) => {
  const today = new Date();
  const startDate = parseISO(queue.start_date_time);
  const hoursUntilStart = differenceInHours(startDate, today);
  const daysUntilStart = Math.floor(hoursUntilStart / 24);

  const maxDaysUntilStart = Math.floor(
    queue.max_spot_cost / queue.spot_increment
  );

  // If the event is within the next 24 hours or has passed, the cost is $0.
  if (hoursUntilStart <= 0) {
    return 0;
  }

  // If the event is more than maxDaysUntilStart away, the cost is max_spot_cost.
  if (daysUntilStart > maxDaysUntilStart) {
    return queue.max_spot_cost;
  }

  // Otherwise, we decrease the cost by the spot_increment each day.
  return daysUntilStart * queue.spot_increment;
};

export const convertStripePriceToDollars = (price: number) => {
  return price / 100;
};

export const getAddressDisplay = (address_available_date_time: string) => {
  const addressAvailableDateTime = address_available_date_time
    ? new Date(address_available_date_time)
    : null;

  if (addressAvailableDateTime === null) {
    return {
      displayText: "Address not available",
      showAddress: false,
    };
  }

  if (addressAvailableDateTime > new Date()) {
    return {
      displayText: `Full Address not available until ${format(
        new Date(addressAvailableDateTime),
        "EEE M/d·h:mm a"
      )}`,
      showAddress: false,
    };
  }

  // Address is available to be shown
  return {
    displayText: "",
    showAddress: true,
  };
};

/* Either get the date + time for single sessions or the date range for multiple sessions */
export const formatDateRange = (queues: any) => {
  if (!queues || queues.length === 0) return "";

  if (queues.length === 1) {
    return format(new Date(queues[0].start_date_time), "EEE M/d·h:mm a");
  }

  const firstQueueDate = format(new Date(queues[0].start_date_time), "EEE M/d");
  const lastQueueDate = format(
    new Date(queues[queues.length - 1].start_date_time),
    "EEE M/d"
  );

  return `${firstQueueDate} - ${lastQueueDate}`;
};

export const formatPhoneNumber = (phone: string) => {
  // Remove all characters that are not digits
  const cleaned = ("" + phone).replace(/\D/g, "");

  // Check if the cleaned number has country code or not
  const match = cleaned.match(/^1?(\d{10})$/);

  if (match) {
    return "+1" + match[1];
  }

  return null;
};
