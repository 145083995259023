import React from "react";
import { IonApp, setupIonicReact } from "@ionic/react";
import { Capacitor } from "@capacitor/core";
import { StatusBar, Style } from "@capacitor/status-bar";
import { IonReactRouter } from "@ionic/react-router";
import { Provider } from "react-redux";
import { QueryClient, QueryClientProvider } from "react-query";
import { AuthProvider } from "./AuthContext";
import AppUrlListener from "./AppUrlListener";
import { TranslationProvider } from "./TranslationContext";

import Routes from "./Routes";
import { store } from "./redux/store";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import "./theme/tailwind.css";
import "./theme/custom.css";

setupIonicReact({
  mode: "ios",
});

const isWeb = Capacitor.getPlatform() === "web";

const setStatusBarStyle = async () => {
  if (!isWeb) {
    await StatusBar.setStyle({
      style: Style.Light,
    });
  }
};

setStatusBarStyle();

const App: React.FC = () => {
  const queryClient = new QueryClient();

  return (
    <IonApp>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <TranslationProvider>
            <IonReactRouter>
              <AppUrlListener></AppUrlListener>
              <AuthProvider>
                <Routes />
              </AuthProvider>
            </IonReactRouter>
          </TranslationProvider>
        </QueryClientProvider>
      </Provider>
    </IonApp>
  );
};

export default App;
