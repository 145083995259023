import React from "react";
import { format } from "date-fns";
import { Heading } from "./Heading";
import { Text } from "./Text";
import { IonIcon } from "@ionic/react";
import {
  personCircleOutline,
  ticketOutline,
  calendarOutline,
  mapOutline,
  cashOutline,
} from "ionicons/icons";

type EventCardProps = {
  line1: string;
  line2?: string;
  company?: string;
  date?: string;
  address?: object;
  spot?: string | React.ReactNode;
  earned?: string;
  showAddress?: boolean;
  className?: string;
};

export const EventCard = ({
  line1,
  line2,
  company,
  date,
  address,
  spot,
  earned,
  showAddress,
  className,
}: EventCardProps) => {
  /* Helpers */
  const handleAddressClick = (data: any) => {
    if (showAddress) {
      const encodedAddress = encodeURIComponent(
        Object.values(data)
          .map((item: any) => item)
          .join(" ")
      );
      const googleMapsUrlScheme = `comgooglemaps://?q=${encodedAddress}`;
      const appleMapsUrl = `http://maps.apple.com/?address=${encodedAddress}`;
      const isIos = /iPad|iPhone|iPod/.test(navigator.userAgent);

      if (isIos) {
        window.open(appleMapsUrl, "_blank");
      } else {
        window.open(googleMapsUrlScheme, "_blank");
      }
    }
  };

  const GridItem = ({
    icon,
    label,
    className = "",
    color = "black",
    onClick,
  }: any) => {
    if (!label) return null;

    const isObject = typeof label === "object" && Object.keys(label)?.length;
    return (
      <div
        className={`grid grid-cols-[20px,auto] gap-3 ${
          isObject ? "items-start" : "items-center"
        } mt-3 mb-5 ${className}`}
        onClick={onClick}
      >
        <IonIcon icon={icon} className="ion-icon-event-override" />
        {isObject &&
        (label?.address_display_message !== undefined ||
          label?.line1 !== undefined) ? (
          <div>
            {Object.keys(label).map((key, index) => {
              return (
                <Text
                  key={index}
                  color={key === "address_display_message" ? "gray" : color}
                  size={key === "address_display_message" ? "sm" : "base"}
                  className={
                    key === "address_display_message"
                      ? "leading-4 max-w-[80%]"
                      : ""
                  }
                  text={label[key]}
                />
              );
            })}
          </div>
        ) : (
          <Text color={color} text={label} />
        )}
      </div>
    );
  };
  return (
    <div
      className={`p-4 pt-5 pb-3 bg-white-100 rounded-2xl shadow-[0_1px_2px_-0px_rgba(0,0,0,0.1)] ${className}`}
    >
      <div className="border-b border-b-black-10 pb-4">
        <Heading color="black" text={line1} size="lg" />
        <Text color="black" text={line2} size="sm" />
        <GridItem
          icon={personCircleOutline}
          label={company}
          className="!mb-0"
        />
      </div>
      <div className="pt-1">
        <GridItem icon={calendarOutline} label={date} />
        <GridItem
          icon={mapOutline}
          label={address}
          onClick={() => handleAddressClick(address)}
        />
        <GridItem icon={ticketOutline} label={spot} />
        <GridItem icon={cashOutline} label={earned} color="green" />
      </div>
    </div>
  );
};
