import { useEffect } from "react";
import {
  createEventStart,
  createEventSucess,
  createEventFailure,
  updateEvent,
  resetEvent,
  setHasSubmitted,
  setEvent,
  setInitialStateInfo,
} from "../redux/event";
import { eventService } from "../service/eventService";
import { useAppSelector, useAppDispatch } from "../redux/hooks";
import { edgeService } from "../service/edgeService";
import { useHistory } from "react-router";
import { useIonAlert } from "@ionic/react";
import validateFields from "../utils/validation";

export const useEvent = () => {
  /* Redux */
  const dispatch = useAppDispatch();
  const { user, activeCompany, event, eventInfo, hasSubmitted, queueInfo } =
    useAppSelector((state) => ({
      user: state.core.user,
      activeCompany: state.company.activeCompany,
      event: state.event,
      eventInfo: state.event.info,
      hasSubmitted: state.event.hasSubmitted,
      queueInfo: state.queue.info,
    }));

  /* Hooks */
  const history = useHistory();
  const [presentAlert] = useIonAlert();

  /* 
    If spanish is enbled, change the class on the title input, and add a new title_spanish input.
    This is in a function because we are resetting the state on event list page load, which always fires last.
    This way we can just call this at the same time to ensure its correct
  */
  const setEventFields = () => {
    let newInfo;

    if (activeCompany?.supports_spanish) {
      newInfo = Object.keys(eventInfo).reduce((acc: any, key) => {
        if (key === "title") {
          acc[key] = {
            ...eventInfo[key],
            className: "rounded-b-none border-b-0",
          };
          acc["title_spanish"] = {
            label: "Event Title (Spanish)",
            value: "",
            type: "text",
            valid: false,
            required: false,
            className: "mb-4 rounded-t-none",
          };
        } else {
          acc[key] = (eventInfo as any)[key];
        }
        return acc;
      }, {});
    } else {
      newInfo = { ...eventInfo };
      delete newInfo["title_spanish"];
    }

    dispatch(setInitialStateInfo(newInfo));
  };

  /* Helpers */
  const getInvalidEventFields = () => {
    return Object.keys(eventInfo).filter(
      (key) =>
        (eventInfo as any)[key].valid === false &&
        !(eventInfo as any)[key].hidden
    );
  };

  const validateEvent = () => {
    const updatedInfo = validateFields(eventInfo);
    dispatch(setEvent(updatedInfo));
    dispatch(setHasSubmitted(true));

    const invalidFields = getInvalidEventFields();
    return !invalidFields.length;
  };

  const createEvent = async () => {
    dispatch(setHasSubmitted(true));

    /*
      Turn out event and queue data into something that supabase can easily insert.
      We don't actually sale enable_addess_date to the DB, so we'll remove it here.
    */
    const transformedEventInfo = Object.keys(eventInfo).reduce(
      (acc: any, key: any) => {
        if (key !== "enable_address_date") {
          acc[key] = (eventInfo as any)[key].value;
        }
        return acc;
      },
      {}
    );

    const transformedQueueInfo = queueInfo.map((queue: any) => {
      return Object.keys(queue).reduce((acc: any, key: any) => {
        acc[key] = queue[key].value;
        if (
          key === "num_spots" ||
          key === "spot_increment" ||
          key === "max_spot_cost"
        )
          acc[key] = parseInt((queue as any)[key].value || 0);
        return acc;
      }, {});
    });

    /* Check for validity first */
    const invalidFields = getInvalidEventFields();

    if (!invalidFields.length) {
      dispatch(createEventStart());
      try {
        let coords = {};
        coords = { lat: "", lng: "" };

        if (transformedEventInfo) {
          coords = await edgeService.lookupAddressGeo(transformedEventInfo);
        }

        const insertedData = await eventService.insertEventWithQueues(
          user.id,
          activeCompany?.id,
          transformedEventInfo,
          transformedQueueInfo,
          coords
        );

        dispatch(createEventSucess());
        setTimeout(() => {
          history.push({
            pathname: `/event/${insertedData?.eventResult?.[0].id}/share`,
            state: { disableBack: true },
          });
        }, 0);
      } catch (error) {
        dispatch(createEventFailure(error.toString()));
        console.log("Error:", error);
        presentAlert({
          header: "There was an issue creating your event",
          message:
            "Make sure all of your form and address fields are valid and try again.",
          buttons: ["OK"],
        });
      }
    }
  };

  return {
    createEvent,
    updateEvent: (event: any) => dispatch(updateEvent(event)),
    resetEvent: () => dispatch(resetEvent()),
    setEvent: (event: any) => dispatch(setEvent(event)),
    event,
    eventInfo,
    hasSubmitted,
    validateEvent,
    setEventFields,
  };
};
