import axios from "axios";
import { supabase } from "../supabaseClient";
import { convertToUTC } from "../utils";

/* Helper for insertEventWithQueues */
const createTranslationGroupAndText = async (
  text: string,
  languageCode = "en",
  createdBy: string,
  existingTranslationGroupId?: string
) => {
  let translationGroupId = existingTranslationGroupId;

  if (!existingTranslationGroupId) {
    const { data: translationGroupResult, error: translationGroupError }: any =
      await supabase.from("translation_group").insert([{}]).select();

    if (translationGroupError) throw translationGroupError;
    translationGroupId = translationGroupResult[0].id;
  }

  const { data: translationResult, error: translationError } = await supabase
    .from("translation")
    .insert([
      {
        text,
        language_code: languageCode,
        translation_group_id: translationGroupId,
        created_by: createdBy,
      },
    ]);

  if (translationError) throw translationError;

  return translationGroupId;
};

// Utility Function to prepare data for insert
const prepareDataForInsert = async (
  textKey: string,
  textValue: string,
  sessionUserId: string,
  languageCode: string,
  existingTranslationGroupId?: string
) => {
  const translationGroupId = await createTranslationGroupAndText(
    textValue,
    languageCode,
    sessionUserId,
    existingTranslationGroupId
  );
  return { [`${textKey}_translation_group_id`]: translationGroupId };
};

async function insertEventWithQueues(
  sessionUserId: string,
  companyId: string,
  eventData: any,
  queueData: any,
  coords: any
) {
  const { title, title_spanish, ...restEventData } = eventData;

  const titleData = await prepareDataForInsert(
    "title",
    title,
    sessionUserId,
    "en"
  );
  if (title_spanish) {
    await prepareDataForInsert(
      "title",
      title_spanish,
      sessionUserId,
      "es",
      titleData["title_translation_group_id"]
    );
  }

  const eventInsertData = {
    ...restEventData,
    ...titleData,
    location: `POINT(${coords.lng} ${coords.lat})`,
    created_by: sessionUserId,
    address_available_date_time: convertToUTC(
      restEventData.address_available_date_time
    ),
    company_id: companyId,
  };

  const { data: eventResult, error: eventError } = await supabase
    .from("event")
    .insert([eventInsertData])
    .select();

  if (eventError) throw eventError;

  const queuesToInsert = await Promise.all(
    queueData.map(
      async ({ description, description_spanish, ...restQueue }: any) => {
        const descriptionData = await prepareDataForInsert(
          "description",
          description,
          sessionUserId,
          "en"
        );

        if (description_spanish) {
          await prepareDataForInsert(
            "description",
            description_spanish,
            sessionUserId,
            "es",
            descriptionData["description_translation_group_id"]
          );
        }

        return {
          ...restQueue,
          ...descriptionData,
          event_id: eventResult[0].id,
          created_by: sessionUserId,
          start_date_time: convertToUTC(restQueue.start_date_time),
        };
      }
    )
  );

  const { data: queueResult, error: queueError } = await supabase
    .from("queue")
    .insert(queuesToInsert)
    .select();

  if (queueError) throw queueError;

  return { eventResult, queueResult };
}

async function getEvents({
  postalCode,
  searchRadiusMiles,
  pageNum = 0,
  pageSize = 10,
  languageCode = "en",
}: any) {
  if (!postalCode) {
    const { data: events, error } = await supabase.rpc("get_explore_events", {
      page_size: pageSize,
      page_number: pageNum + 1,
      language_code_param: languageCode,
    });
    if (error) {
      console.error("Error fetching events:", error);
      throw error;
    }

    return events;
  } else {
    const postalCoords = await axios
      .get(`https://api.zippopotam.us/us/${postalCode}`)
      .then((res) => {
        return res.data.places[0];
      })
      .catch((error) => {
        console.error("Error fetching zip code data:", error);
        throw error;
      });

    const { data: events, error } = await supabase.rpc(
      "get_nearby_explore_events",
      {
        lat: postalCoords.latitude,
        long: postalCoords.longitude,
        search_radius_meters: searchRadiusMiles * 1609.34,
        page_size: pageSize,
        page_number: pageNum + 1,
        language_code_param: languageCode,
      }
    );

    if (error) {
      console.error("Error fetching events:", error);
      throw error;
    }
    return events;
  }
}

async function getEventsUserIsAttending(
  sessionUserId: string,
  pageNum = 0,
  pageSize = 10,
  languageCode = "en"
) {
  const { data: events, error } = await supabase.rpc("get_attending_events", {
    user_id: sessionUserId,
    page_size: pageSize,
    page_number: pageNum + 1,
    language_code_param: languageCode,
  });

  if (error) {
    console.error("Error fetching events:", error);
    throw error;
  }

  return events;
}

async function getEventsCreatedByMe(
  sessionUserId: string,
  pageNum = 0,
  pageSize = 10,
  companyId: string,
  languageCode = "en"
) {
  const { data: events, error } = await supabase.rpc("get_my_events", {
    page_size: pageSize,
    page_number: pageNum + 1,
    user_id_param: sessionUserId,
    company_id_param: companyId,
    language_code_param: languageCode,
  });

  if (error) {
    console.error("Error fetching events created by user:", error);
    throw error;
  }

  return events;
}

async function getEventsCreatedByMeCount(sessionUserId: string) {
  const { data, count, error } = await supabase
    .from("event")
    .select("*", { count: "exact", head: true })
    .eq("created_by", sessionUserId)
    .eq("active", true);

  if (error) {
    console.error("Error fetching events created by user:", error);
    throw error;
  }
  return count;
}

async function getEventById(
  eventId: string,
  userId?: string,
  languageCode = "en"
) {
  const { data: event, error } = await supabase.rpc("get_event", {
    event_id_param: eventId,
    user_id_param: userId || null,
    language_code_param: languageCode,
  });

  if (error) {
    console.error("Error fetching user:", error);
    throw error;
  }

  return event?.[0];
}

async function getQueue(queueId: string) {
  const { data: queue, error } = await supabase
    .from("spot")
    .select("*")
    .eq("id", queueId)
    .eq("active", true)
    .maybeSingle();

  if (error) {
    console.error("Error fetching queue:", error);
    throw error;
  }

  return queue;
}

export const eventService = {
  insertEventWithQueues,
  getEvents,
  getEventsCreatedByMe,
  getEventsCreatedByMeCount,
  getEventById,
  getEventsUserIsAttending,
  getQueue,
};
