import React from "react";
import { Heading } from "./Heading";

type ListContainerProps = {
  type?: string | "OPEN" | "CONTAINED";
  title?: string;
  showBoxShadow?: boolean;
  showBorder?: boolean;
  className?: string;
  children?: React.ReactNode;
  button?: React.ReactNode;
  removeTopPadding?: boolean;
};

export const ListContainer = ({
  children,
  type = "CONTAINED",
  title,
  showBoxShadow,
  showBorder,
  className,
  button,
  removeTopPadding = false,
}: ListContainerProps) => {
  const getContainerStyles = () => {
    let styles = `p-4 ${removeTopPadding ? "pt-0" : "pt-5"} pb-6`;
    if (type === "OPEN") {
      if (showBorder) {
        styles += ` border-b border-b-black-10`;
      }
    } else {
      if (showBorder) {
        styles += ` border border-black-10`;
      }
      if (showBoxShadow) styles += " shadow-[0_1px_2px_-0px_rgba(0,0,0,0.1)]";
      styles += ` bg-white-100 rounded-2xl`;
    }
    if (button) {
      styles += ` !pb-0`;
    }
    if (className) styles += ` ${className}`;
    return styles;
  };

  return (
    <div className={getContainerStyles()}>
      {title && <Heading size="lg" text={title} color="black" />}
      <div className={`grid gap-4 mt-3`}>{children}</div>
      {button && (
        <div className="w-[calc(100%+32px)] ml-[-16px] mt-4 border-t border-t-black-10">
          {button}
        </div>
      )}
    </div>
  );
};
