import React from "react";
import { Heading } from "./Heading";

type SpotCalloutProps = {
  line1: string;
  line2: string | React.ReactNode;
  number: number | string;
  className?: string;
};

export const SpotCallout = ({
  line1,
  line2,
  number,
  className = "",
}: SpotCalloutProps) => (
  <div
    className={`px-6 py-4 grid grid-cols-[auto,50px] items-center bg-white-100 rounded-sm shadow-[0_1px_2px_-0px_rgba(0,0,0,0.1)] ${className}`}
  >
    <div>
      <Heading color="black" text={line1} size="lg" />

      <div className="block -mt-1">
        <div className="max-w-[calc(100%-50px)] inline-flex items-center">
          <div className="truncate overflow-hidden text-black-60">{line2}</div>
        </div>
      </div>
    </div>
    <div className="font-bold text-4xl text-right">{number}</div>
  </div>
);
