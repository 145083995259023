export default {
  core: {
    qrCodeSaved: "QR Code Saved",
  },
  login: {
    invalidEmail: "Please enter a valid email address",
    invalidLoginCode: "Please enter a valid 6 digit login code",
    login: "Log in",
    email: "Email Address",
    emailPlaceholder: "name@example.com",
    sendingLoginCode: "Sending login code",
    loginWithEmailCode: "Login with email code",
    checkYourEmail: "Check your email",
    weSentCodeTo: "We sent a code to",
    yourCode: "Your Code",
    noCode: `Didn't get a code?`,
    resendCode: "Click here to try again",
    cancel: "Cancel",
    verifying: "Verifying",
    verify: "Verify",
    bySigningUp: "By pressing verify you agree to our",
    privacyPolicy: "Privacy Policy",
    and: "and",
    termsOfService: "Terms of Service",
  },
  404: {
    title: "Page Not Found",
    description: "Click the button below to head back home",
    button: "Go Home",
  },
  pages: {
    attendingEvents: "Your Tickets",
    event: "Event",
    queue: "Session",
    redeem: "Scan",
    scan: "Scan",
    share: "Share",
  },
  events: {
    explore: "Explore",
    searchPlaceholder: "Search by postal code",
    myEvents: "My Events",
    createEvent: "Create an Event",
    loading: "Loading...",
    loadMore: "Load More",
    noResults: "No results",
    endOfList: `You've reached the end of the list`,
    noEvent: {
      segment1: `You haven’t`,
      segment2: `created`,
      segment3: `signed up for`,
      segment4: `any events`,
      description1: "Once you create your first event, it will show up here.",
      description2: "Once you start reserving tickets, you'll see them here.",
    },
    findNearMe: "Find events near me",
    list: {
      earned: "earned",
      spot: "Spot",
      free: "Free",
      today: "Today",
      upcoming: "Upcoming",
      past: "Past",
    },
  },
  event: {
    earned: "earned",
    free: "Free",
    earnedCap: "Earned",
    sessionsTitle: "Sessions",
    defaultSessionTitle: "Session 1",
    shareEvent: "Share this event",
    share: {
      linkCopied: "Copied Link to clipboard",
      copyFailed: "Could not copy link: ",
      shareEvent: "Share this event",
      shareEventDescription:
        "Copy the link below or tap the QR code to download and let people scan the code to see this event.",
      copyButton: "Copy Link to event",
      backToEvents: "View my tickets",
    },
    payment: {
      success: "Payment succeeded!",
      processing: "Your payment is processing.",
      requiresPaymentMethod:
        "Your payment was not successful, please try again.",
      unexpectedError: "An unexpected error occurred, please try again.",
      payNow: "Pay Now",
      cancel: "Cancel",
    },
    redeem: {
      success: {
        title: "Scan successful!",
        segment1: "You checked in on",
        segment2: "at",
      },
      backToEvent: "View Event Page",
    },
  },
  queue: {
    freeCap: "Free",
    spotsReserved: "Spots reserved",
    removeReservationPrompt: "Remove Reservation?",
    removeReservationMessage: `Press OK to remove your reservation for this spot`,
    cancel: "Cancel",
    ok: "OK",
    scannerNotAvailable: "Scanner not available on web",
    openQrScanner: "Open QR Code Scanner",
    openQrTicket: "Open QR Code Ticket",
    scan: "scan",
    redeem: "redeem",
    signInToReserveSpot: "Sign in to reserve a spot",
    removeReservation: "Remove Reservation",
    reserveSpot: {
      segment1: "Reserve Spot",
      segment2: "for",
      segment3: "Free",
    },
    alreadyCheckedInTitle: "You already checked into this event",
    alreadyCheckedInDescription: "You checked in on",
    earnedCap: "Earned",
    paymentSuccess: "Payment succeeded",
    paymentCancelled: "Payment Cancelled",
    tapToOpenTicket: `Tap the button above to open your ticket`,
    tryAgain: "Please try again.",
    attendeesTitle: "Attendees",
    spot: "Spot",
    pricingChart: {
      dayOfEvent: "Day of Event",
      hrsBefore: "24 Hours Before Event",
      daysBefore: "Days or More Before Event",
      days: "Days",
      day: "Day",
      before: "Before Event",
      pricingGuide: "Pricing Guide",
      free: "Free",
    },
  },
  onboarding: {
    info: {
      errorSaving: "There was an error saving your account",
      tellUsAboutYourself: "Tell us about yourself",
      first_name: "First Name",
      last_name: "Last Name",
      email: "Email",
      phone: "Phone",
      city: "City",
      state: "State",
      postal_code: "Postal Code",
      enterValid: "Please enter a valid",
      addCompanyTitle: "Want to run your own events?",
      addCompanyDescription:
        "Click here to add your company information and run your own events.",
      creating: "Creating Account...",
      continue: "Continue",
    },
  },
  account: {
    home: {
      settingsTitle: "Account Settings",
      settingsDescription: "Manage your account",
      companyTitle: "Manage Company Info",
      companyDescription: "Change the name or address of your company",
      usersTitle: "Manage Users",
      usersDescription: "Add or remove access for authorized users",
      payments: {
        title: "Manage Payments",
        loading: "Loading Stripe info",
        viewDashboard: "View your Stripe Dashboard",
        continue: "Continue setting up your Stripe account",
        start: "Start accepting payments with Stripe",
      },
      createCompanyTitle: "Create a Company",
      createCompanyDescription: "Run your own events",
      signOut: "Sign Out",
    },
    info: {
      errorSaving: "There was an error saving your account",
      title: "Account",
      enterValid: "Please enter a valid",
      areYouSureTitle: "Are you sure you want to delete your account?",
      areYouSureMessage: `This will also delete all of your events and spots. If you would like to permanently delete your account, please contact us.`,
      cancel: "Cancel",
      deactivate: "Delete",
      deactivateAccount: "Delete Account",
      saving: "Saving...",
      save: "Save",
      first_name: "First Name",
      last_name: "Last Name",
      email: "Email",
      phone: "Phone",
      city: "City",
      state: "State",
      postal_code: "Postal Code",
    },
  },
};
