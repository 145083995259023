import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { states } from "../utils/data";

interface Input {
  label: string;
  value: string | number | boolean;
  type?: string;
  hidden?: boolean;
  options?: any[];
  valid: boolean;
  className?: string;
  autocomplete?: string;
  required?: boolean;
}
interface Company {
  name: Input;
  address_line_1: Input;
  address_line_2: Input;
  city: Input;
  state: Input;
  postal_code: Input;
  supports_spanish: Input;
}

interface ActiveCompany {
  id: string;
  name: string;
  supports_spanish: boolean;
}

interface CompanyState {
  info: Company;
  isLoading: boolean;
  error: string | null;
  lastFetchTimestamp: number | null;
  hasSubmitted: boolean;
  activeCompany?: ActiveCompany;
  userCompanies?: any;
}

const initialState: CompanyState = {
  info: {
    name: {
      value: "",
      label: "Company Name",
      type: "text",
      valid: false,
      required: true,
      hidden: false,
      className: "mb-4",
    },
    address_line_1: {
      label: "Address Line 1",
      value: "",
      type: "text",
      valid: false,
      required: true,
      autocomplete: "street-address",
      className: "rounded-b-none border-b-0",
    },
    address_line_2: {
      label: "Address Line 2",
      value: "",
      type: "text",
      valid: true,
      required: false,
      autocomplete: "address-line2",
      className: "rounded-none border-b-0",
    },
    city: {
      label: "City",
      value: "",
      type: "text",
      valid: false,
      required: true,
      autocomplete: "address-level2",
      className: "rounded-none border-b-0",
    },
    state: {
      label: "State",
      value: "",
      type: "select",
      options: states,
      valid: false,
      required: true,
      autocomplete: "address-level1",
      className: "rounded-none border-b-0",
    },
    postal_code: {
      label: "Postal Code",
      value: "",
      type: "text",
      valid: false,
      required: true,
      autocomplete: "postal-code",
      className: "rounded-t-none mb-4",
    },
    supports_spanish: {
      label: "Enable Spanish Inputs",
      value: false,
      type: "checkbox",
      valid: true,
    },
  },
  activeCompany: {
    id: "",
    name: "",
    supports_spanish: false,
  },
  isLoading: false,
  error: null,
  lastFetchTimestamp: null,
  hasSubmitted: false,
};

export const company = createSlice({
  name: "company",
  initialState,
  reducers: {
    fetchCompanyStart: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    fetchCompanySuccess: (state, action: PayloadAction<any[]>) => {
      state.info = {
        ...state.info,
        ...action.payload,
      };
      state.isLoading = false;
    },
    updateCompany: (
      state,
      action: PayloadAction<{ key: keyof Company; value: string }>
    ) => {
      state.info[action.payload.key].value = action.payload.value;
    },
    setCompany: (state, action: PayloadAction<any>) => {
      state.info = action.payload;
    },
    setActiveCompany: (state, action: PayloadAction<any>) => {
      state.activeCompany = action.payload;
    },
    setUserCompanies: (state, action: PayloadAction<any>) => {
      state.userCompanies = action.payload;
    },
    fetchCompanyError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    resetCompany: (state) => {
      state.info = initialState.info;
    },
    saveCompanyStart: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    saveCompanySuccess: (state, action: PayloadAction<any[]>) => {
      state.isLoading = false;
      state.error = null;
    },
    saveCompanyError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    setLastFetchTimestamp: (state, action: PayloadAction<any>) => {
      state.lastFetchTimestamp = action.payload;
    },
    setHasSubmitted: (state, action: PayloadAction<boolean>) => {
      state.hasSubmitted = action.payload;
    },
  },
});

export const {
  fetchCompanyStart,
  fetchCompanySuccess,
  fetchCompanyError,
  resetCompany,
  setCompany,
  setActiveCompany,
  setUserCompanies,
  saveCompanyStart,
  saveCompanySuccess,
  saveCompanyError,
  setLastFetchTimestamp,
  updateCompany,
  setHasSubmitted,
} = company.actions;

export default company.reducer;
