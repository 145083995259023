import React, { Fragment } from "react";

type ListItemProps = {
  displayBox?: React.ReactNode;
  line1: string;
  line2Items?: Array<{ value: string; color: string | "gray" | "green" }>;
  line3Items?: Array<{ value: string; color: string | "gray" | "green" }>;
  icon?: React.ReactNode;
  iconPlacement?: string | "TOP" | "BOTTOM";
  showBorder?: boolean;
  className?: string;
  errorMessage?: any;
  onClick?: () => void;
};

export const ListItem = ({
  displayBox,
  line1,
  line2Items,
  line3Items,
  icon,
  iconPlacement = "BOTTOM",
  showBorder = false,
  className = "",
  errorMessage,
  onClick,
}: ListItemProps) => {
  const getColor = (color: any) => {
    if (color === "green") {
      return "text-green-100";
    } else {
      return "text-black-60";
    }
  };

  const iconDiv = <div className="ml-1 ion-icon-color-override">{icon}</div>;

  return (
    <div
      onClick={onClick}
      className={`grid ${
        displayBox ? "grid-cols-[50px,auto]" : "grid-cols-1"
      } items-start gap-4 ${
        showBorder ? "border-b border-b-black-10" : ""
      } ${className}`}
    >
      {displayBox}
      <div>
        <div className="max-w-[calc(100%-50px)] inline-flex items-center">
          <div className="font-semibold truncate overflow-hidden">{line1}</div>
          {icon && iconPlacement === "TOP" && iconDiv}
        </div>
        {/* Line 2 items  */}
        {line2Items && (
          <div className="block -mt-0.5">
            <div className="max-w-[calc(100%-50px)] inline-flex items-center">
              <div className="truncate overflow-hidden">
                {line2Items.map((item, index) => (
                  <Fragment key={index}>
                    <span className={`${getColor(item.color)} `}>
                      {item.value}
                    </span>
                    {index < line2Items.length - 1 && (
                      <span className="inline-block mx-1 text-black-60 text-xs align-middle">
                        <div className="-mt-1">•</div>
                      </span>
                    )}
                  </Fragment>
                ))}
              </div>

              {icon && iconPlacement === "BOTTOM" && iconDiv}
            </div>
          </div>
        )}

        {/* Line 3 items  */}
        {line3Items && (
          <div className="block -mt-0.5">
            <div className="max-w-[calc(100%-50px)] inline-flex items-center">
              <div className="truncate overflow-hidden">
                {line3Items.map((item, index) => (
                  <Fragment key={index}>
                    <span className={`${getColor(item.color)} `}>
                      {item.value}
                    </span>
                    {index < line3Items.length - 1 && (
                      <span className="inline-block mx-1 text-black-60 text-xs align-middle">
                        <div className="-mt-1">•</div>
                      </span>
                    )}
                  </Fragment>
                ))}
              </div>
            </div>
          </div>
        )}
        {errorMessage && errorMessage}
      </div>
    </div>
  );
};
