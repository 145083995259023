import React from "react";
import { IonPage } from "@ionic/react";
import Instructions from "../components/onboarding/Instructions";

const OnboardingInstructions: React.FC = () => {
  return (
    <IonPage>
      <Instructions />
    </IonPage>
  );
};

export default OnboardingInstructions;
