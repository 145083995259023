import { IonContent, IonFooter, useIonAlert, useIonToast } from "@ionic/react";
import React, { useState } from "react";
import { useAccount } from "../../hooks/useAccount";
import { useCore } from "../../hooks/useCore";
import { userService } from "../../service/userService";
import { useTranslation } from "../../TranslationContext";

import { Input } from "../ui/Input";
import { Button } from "../ui/Button";
import { Header } from "../ui/Header";
import { useHistory } from "react-router";
import { setHasSubmitted } from "../../redux/account";

const Account: React.FC = () => {
  /* Translations */
  const { t }: any = useTranslation();

  /* Hooks */
  const [isLoading, setIsLoading] = useState(false);
  const [present] = useIonToast();
  const [presentAlert] = useIonAlert();
  const history = useHistory();

  const {
    accountInfo,
    updateAccount,
    updateAccountCall,
    updateUser,
    hasSubmitted,
    session,
    validateAccount,
  } = useAccount();

  const { resetCore } = useCore();

  /* Helpers */
  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    updateAccount({ key: name, value: value });
  };

  const handleSubmit = async () => {
    validateAccount(async () => {
      try {
        setIsLoading(true);
        await updateAccountCall();
        /* 
        Since the user may have updated their name, lets update the base user redux to reflect that
        so its not stale when they get routed back
      */
        updateUser();
        setIsLoading(false);
        setHasSubmitted(false);
        history.push("/account");
      } catch (error) {
        setIsLoading(false);
        console.log("Error:", error);
        present({
          message: t("account.info.errorSaving"),
          duration: 3000,
          position: "bottom",
        });
      }
    });
  };

  const signOut = async () => {
    const logoutRes = await userService.signUserOut();

    if (logoutRes.success) {
      resetCore();
    }
  };

  const deleteAccount = async () => {
    await userService.markUserAsDeleted(session?.user?.id);
    await signOut();
  };

  return (
    <>
      <Header
        title={t("account.info.title")}
        showBackButton={true}
        backButtonHref="/account"
      />
      <IonContent className="ion-padding-page">
        <div className="mt-2">
          {Object.keys(accountInfo).map(
            (key, index) =>
              !(accountInfo as any)?.[key as any].hidden && (
                <div className="block" key={index}>
                  <Input
                    label={`${t(`account.info.${key}`)} ${
                      (accountInfo as any)[key].required ? "*" : ""
                    }`}
                    placeholder={t(`account.info.${key}`)}
                    value={(accountInfo as any)[key].value}
                    onIonInput={handleInputChange}
                    name={key}
                    type={(accountInfo as any)?.[key].type}
                    className={(accountInfo as any)?.[key].className}
                    errorMessage={
                      !(accountInfo as any)?.[key]?.valid &&
                      (accountInfo as any)?.[key]?.required &&
                      hasSubmitted && (
                        <div className="text-xs text-red-100">
                          {t("account.info.enterValid")}{" "}
                          {t(`account.info.${key}`)}
                        </div>
                      )
                    }
                  ></Input>
                </div>
              )
          )}

          {/* Settings  */}
          <Button
            color="gray"
            onClick={() => {
              presentAlert({
                header: t("account.info.areYouSureTitle"),
                message: t("account.info.areYouSureMessage"),
                buttons: [
                  { text: t("account.info.cancel"), role: "cancel" },
                  {
                    text: t("account.info.deactivate"),
                    role: "delete",
                    handler: () => deleteAccount(),
                  },
                ],
              });
            }}
            text={t("account.info.deactivateAccount")}
            className="mt-2"
          />
        </div>
      </IonContent>
      <IonFooter>
        <div className="w-full text-black-100 flex justify-end items-center p-4 bg-appBg">
          <Button
            color="black"
            onClick={handleSubmit}
            className="mt-2"
            text={isLoading ? t("account.info.saving") : t("account.info.save")}
            disabled={isLoading}
          />
        </div>
      </IonFooter>
    </>
  );
};

export default Account;
