import React from "react";
import { format } from "date-fns";
import { useQuery } from "react-query";
import { EventCard } from "../ui/EventCard";
import { useHistory, useParams } from "react-router-dom";
import { eventService } from "../../service/eventService";
import { Loader } from "../core/Loader";
import { Button } from "../ui/Button";
import { ListDisplayBox } from "../ui/ListDisplayBox";
import { ListContainer } from "../ui/ListContainer";
import { ListItem } from "../ui/ListItem";
import { useAppSelector } from "../../redux/hooks";
import {
  calculateSpotPrice,
  convertStripePriceToDollars,
  getAddressDisplay,
  formatDateRange,
} from "../../utils";
import { language, ticketOutline } from "ionicons/icons";
import { IonIcon } from "@ionic/react";
import { useTranslation } from "../../TranslationContext";

interface Props {
  data?: any;
  isCurrentPageActive?: boolean;
}

type Params = {
  eventId: string;
};

const Event: React.FC<Props> = ({ isCurrentPageActive }) => {
  /* Translations */
  const { t, languageCode }: any = useTranslation();

  /* Redux */
  const { user, hasFetchedUser } = useAppSelector((state) => ({
    user: state.core.user,
    hasFetchedUser: state.core.hasFetchedUser,
  }));

  /* Hooks */
  const { eventId } = useParams<Params>();
  const history = useHistory();

  /* React Query */
  const {
    data: event,
    refetch: refetchEvent,
    isLoading: isEventLoading,
  } = useQuery(
    ["event", eventId, hasFetchedUser],
    () => eventService.getEventById(eventId, user?.id, languageCode),
    {
      enabled: isCurrentPageActive && !!eventId?.length && hasFetchedUser,
      staleTime: 1000 * 10,
    }
  );

  /* Helpers */
  const didUserCreateEvent = event?.created_by === user?.id;
  const isAuthorizedUser = event?.is_authorized_user;
  const addressDisplay = getAddressDisplay(event?.address_available_date_time);
  const totalEarned = event?.queues.reduce(
    (acc: any, queue: any) => acc + queue?.stripe_amount_total_sum,
    0
  );

  /* Get the current price or current earnings depending on the user type */
  const getLine2Value = (queue: any) => {
    const currentPrice = calculateSpotPrice(queue);
    if (didUserCreateEvent) {
      return `$${convertStripePriceToDollars(
        queue?.stripe_amount_total_sum
      )} ${t("event.earned")}`;
    } else {
      return `${currentPrice > 0 ? `$${currentPrice}` : t("event.free")}`;
    }
  };

  if (isEventLoading || !event?.id || !isCurrentPageActive)
    return <Loader className="min-h-[200px]" />;
  return (
    <div className="grid gap-4">
      <EventCard
        line1={event?.title}
        company={event?.user?.company_name}
        date={formatDateRange(event?.queues)}
        address={{
          line1: event?.address_line_1,
          line2: event?.address_line_2,
          city_state_postal: `${event?.city}, ${event?.state} ${event?.postal_code}`,
          address_display_message: addressDisplay.displayText,
        }}
        showAddress={addressDisplay?.showAddress}
        earned={
          (didUserCreateEvent || isAuthorizedUser) &&
          `${
            totalEarned ? `$${convertStripePriceToDollars(totalEarned)}` : "$0"
          } ${t("event.earnedCap")}`
        }
      />

      <ListContainer
        title={t("event.sessionsTitle")}
        type="CONTAINED"
        showBoxShadow
      >
        {event?.queues.map((queue: any, index: any) => (
          <ListItem
            key={index}
            line1={queue.description || t("event.defaultSessionTitle")}
            line2Items={[
              { value: getLine2Value(queue), color: "green" },
              {
                value: format(
                  new Date(queue?.start_date_time),
                  "EEE M/d·h:mm a"
                ),
                color: "gray",
              },
            ]}
            displayBox={
              <ListDisplayBox
                label={format(new Date(queue?.start_date_time), "MMM")}
                value={format(new Date(queue?.start_date_time), "d")}
              />
            }
            iconPlacement="TOP"
            icon={queue?.is_attending && <IonIcon icon={ticketOutline} />}
            onClick={() => history.push(`/event/${eventId}/queue/${queue.id}`)}
          />
        ))}
      </ListContainer>

      <Button
        color="gray"
        text={t("event.shareEvent")}
        onClick={() => history.push(`/event/${eventId}/share`)}
      />
    </div>
  );
};

export default Event;
