import React from "react";
import { IonContent, IonPage } from "@ionic/react";
import Auth from "../components/Auth";

const Login: React.FC = () => {
  return (
    <IonPage>
      <IonContent fullscreen className="ion-padding-page">
        <Auth />
      </IonContent>
    </IonPage>
  );
};

export default Login;
