import { useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { userService } from "../service/userService";
import {
  setAccount,
  fetchAccountStart,
  fetchAccountSuccess,
  fetchAccountError,
  setLastFetchTimestamp,
  updateAccount,
  setHasSubmitted,
} from "../redux/account";
import { useIonViewDidEnter } from "@ionic/react";
import { setUser } from "../redux/core";
import validateFields from "../utils/validation";
export const useAccount = () => {
  /* Redux */
  const dispatch = useAppDispatch();
  const {
    session,
    user,
    accountInfo,
    isLoading,
    error,
    lastFetchTimestamp,
    hasSubmitted,
  } = useAppSelector((state) => ({
    session: state.core.session,
    user: state.core.user,
    accountInfo: state.account.info,
    isLoading: state.account.isLoading,
    error: state.account.error,
    lastFetchTimestamp: state.account.lastFetchTimestamp,
    hasSubmitted: state.account.hasSubmitted,
  }));

  /* Refs */
  const stateRef = useRef<any>();

  /* 
    This is a bit weird, but we don't have access to hook data in ionic
    lifecycle hooks -- we can keep it forever cached in a ref, though.
*/
  stateRef.current = {
    session,
    user,
    accountInfo,
    isLoading,
    error,
    lastFetchTimestamp,
  };

  /* Helpers */
  const fetchAccount = async () => {
    const currentTime = new Date().getTime();
    dispatch(setLastFetchTimestamp(currentTime));
    dispatch(fetchAccountStart());
    try {
      if (user?.id) {
        const userRes = await userService.getUserInfoById(user.id);
        const updatedAccountInfo = Object.keys(accountInfo).reduce(
          (acc: any, key) => {
            acc[key] = { ...(accountInfo as any)[key], value: userRes[key] };
            return acc;
          },
          {}
        );

        const validatedUpdatedAccountInfo = validateFields(updatedAccountInfo);
        dispatch(setAccount(validatedUpdatedAccountInfo));
        dispatch(fetchAccountSuccess());
      } else {
        dispatch(fetchAccountError(error));
      }
    } catch (error: any) {
      dispatch(fetchAccountError(error));
    }
  };

  /* Every time the view loads */

  /* Refetch account data if its been 5 minutes since the last fetch */
  useIonViewDidEnter(() => {
    const lastFetched = stateRef.current.lastFetchTimestamp;
    const currentTime = new Date().getTime();

    const fiveMinutes = 1000 * 60 * 5;
    if (currentTime - lastFetched >= fiveMinutes) {
      fetchAccount();
    }
  });

  /* The first time the component mounts, once the user object is populated, fetch the data*/
  useEffect(() => {
    if (user?.id) {
      fetchAccount();
    }
  }, [user?.id]);

  const updateAccountCall = async () => {
    dispatch(setHasSubmitted(true));

    /* Check for validity first */
    const invalidFields = Object.keys(accountInfo).filter(
      (key) =>
        (accountInfo as any)[key].valid === false &&
        !(accountInfo as any)[key].hidden
    );

    if (!invalidFields.length) {
      dispatch(fetchAccountStart());
      try {
        const userData = Object.keys(accountInfo).reduce((acc: any, key) => {
          acc[key] = (accountInfo as any)[key].value;
          return acc;
        }, {});
        await userService.updateUser(user.id, userData);
        dispatch(fetchAccountSuccess());
      } catch (error: any) {
        dispatch(fetchAccountError(error));
      }
    }
  };

  const createAccount = async (onSuccess?: any) => {
    dispatch(setHasSubmitted(true));

    /* Check for validity first */
    const invalidFields = Object.keys(accountInfo).filter(
      (key) =>
        (accountInfo as any)[key].valid === false &&
        !(accountInfo as any)[key].hidden
    );

    if (!invalidFields.length) {
      dispatch(fetchAccountStart());
      try {
        const userData = Object.keys(accountInfo).reduce(
          (acc: any, key: any) => {
            acc[key] = (accountInfo as any)[key].value;
            return acc;
          },
          {}
        );
        const userRes = await userService.insertUser(session.user.id, userData);
        dispatch(setUser(userRes));
        dispatch(fetchAccountSuccess());
        setTimeout(() => {
          onSuccess();
        }, 1000);
      } catch (error: any) {
        dispatch(fetchAccountError(error));
      }
    }
  };

  const validateAccount = (onSuccess?: any) => {
    dispatch(setHasSubmitted(true));

    /* Check for validity first */
    const invalidFields = Object.keys(accountInfo).filter(
      (key) =>
        (accountInfo as any)[key].valid === false &&
        !(accountInfo as any)[key].hidden
    );

    if (!invalidFields.length) {
      onSuccess();
    }
  };

  const updateUser = () => {
    dispatch(
      setUser({
        ...user,
        ...{
          first_name: accountInfo.first_name.value,
          last_name: accountInfo.last_name.value,
        },
      })
    );
  };

  return {
    user,
    accountInfo,
    updateUser,
    setAccount: (info: any) => dispatch(setAccount(info)),
    isLoading,
    error,
    validateAccount,
    createAccount,
    updateAccountCall,
    updateAccount: (info: any) => dispatch(updateAccount(info)),
    hasSubmitted,
    session,
    fetchAccount,
  };
};
