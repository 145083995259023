import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { stripeService } from "../../service/stripeService";
import CheckoutForm from "./StripeCheckoutForm";
import { Loader } from "../core/Loader";

/* Call this outside of the components render */
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

export default function StripeContainer({
  event,
  activeQueue,
  currentSpotPrice,
  nextSpotAvailable,
  spotPurchasedByUser,
  isWeb,
  user,
  existingClientSecret,
  setIsPaymentModalOpen,
}: any) {
  const [clientSecret, setClientSecret] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  /*
    Since this is in a modal, we'll create the payment intent as soon as the modal opens.
    If the user already has a clientSecret from another payment intent, we'll use that 
  */
  useEffect(() => {
    if (!existingClientSecret && event?.id && spotPurchasedByUser.id) {
      setIsLoading(true);
      stripeService
        .createPaymentIntent({
          connected_account_id: event?.user?.stripe_connect_id,
          price: currentSpotPrice,
          spot_num: activeQueue?.num_spots - nextSpotAvailable + 1,
          event_metadata: event,
          metadata: {
            spot_id: spotPurchasedByUser.id,
            referringPlatform: isWeb ? "web" : "mobile",
          },
        })
        .then((data) => setClientSecret(data.clientSecret))
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setClientSecret(existingClientSecret);
    }
  }, [existingClientSecret, event?.id, spotPurchasedByUser.id]);

  const appearance = {
    theme: "stripe",
  };
  const options: any = {
    clientSecret,
    appearance,
  };

  return (
    <div>
      {isLoading && <Loader className="min-h-[200px]" />}
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm
            user={user}
            existingClientSecret={existingClientSecret}
            clientSecret={clientSecret}
            setIsPaymentModalOpen={setIsPaymentModalOpen}
          />
        </Elements>
      )}
    </div>
  );
}
