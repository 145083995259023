import React from "react";
type ButtonProps = {
  color: "white" | "black" | "gray";
  text: string;
  icon?: React.ReactNode;
  className?: string;
  showBorder?: boolean;
  transparent?: boolean;
  onClick?: (e?: any) => void;
  disabled?: boolean;
};

export const Button = ({
  color,
  text,
  className,
  icon,
  onClick,
  transparent = false,
  showBorder = true,
  disabled = false,
}: ButtonProps) => {
  const getDefaultColor = () => {
    const defaultColor = "bg-white-100 text-black-100 border-black-10";

    switch (color) {
      case "white":
        return defaultColor;
      case "black":
        return "bg-black-100 text-white-100 border-black-100";
      case "gray":
        return "bg-black-5 text-black-100  border-black-10";
      default:
        return defaultColor;
    }
  };

  return (
    <div
      onClick={onClick}
      className={`py-5 cursor-pointer w-full inline-flex items-center gap-2 ${
        showBorder ? "border" : ""
      } justify-center rounded-lg text-sm ${getDefaultColor()} ${
        transparent ? "bg-transparent" : ""
      } ${className || ""} ${
        disabled ? "opacity-50 cursor-not-allowed pointer-events-none	" : ""
      }`}
    >
      {icon}
      <span className="font-semibold">{text}</span>
    </div>
  );
};
