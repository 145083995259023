import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { formatISO } from "date-fns";
import { states } from "../utils/data";

interface Input {
  label: string;
  value: string | number | boolean;
  type?: string;
  options?: any[];
  valid: boolean;
  required?: boolean;
  autocomplete?: string;
  className?: string;
}

interface Event {
  title: Input;
  title_spanish?: Input;
  address_line_1: Input;
  address_line_2: Input;
  city: Input;
  state: Input;
  postal_code: Input;
  enable_address_date: Input;
  address_available_date_time: Input;
}

interface EventState {
  info: Event;
  isLoading: boolean;
  error: string | null;
  hasSubmitted: boolean;
}

const initialState: EventState = {
  info: {
    title: {
      label: "Event Title",
      value: "",
      type: "text",
      valid: false,
      required: true,
      className: "mb-4",
    },
    address_line_1: {
      label: "Address Line 1",
      value: "",
      type: "text",
      valid: false,
      required: true,
      autocomplete: "street-address",
      className: "rounded-b-none border-b-0",
    },
    address_line_2: {
      label: "Address Line 2",
      value: "",
      type: "text",
      valid: true,
      required: false,
      autocomplete: "address-line2",
      className: "rounded-none border-b-0",
    },
    city: {
      label: "City",
      value: "",
      type: "text",
      valid: false,
      required: true,
      autocomplete: "address-level2",
      className: "rounded-none border-b-0",
    },
    state: {
      label: "State",
      value: "",
      type: "select",
      options: states,
      valid: false,
      required: true,
      autocomplete: "address-level1",
      className: "rounded-none border-b-0",
    },
    postal_code: {
      label: "Postal Code",
      value: "",
      type: "text",
      valid: false,
      required: true,
      autocomplete: "postal-code",
      className: "rounded-t-none mb-4",
    },
    enable_address_date: {
      label: "Reveal address on chosen date",
      value: false,
      type: "checkbox",
      valid: true,
    },
    address_available_date_time: {
      label: "Address Available Date",
      value: formatISO(new Date()),
      type: "date",
      valid: false,
      required: false,
      className: "-mt-4 rounded-t-none",
    },
  },
  isLoading: false,
  error: null,
  hasSubmitted: false,
};

export const event = createSlice({
  name: "event",
  initialState,
  reducers: {
    setInitialStateInfo: (state, action: PayloadAction<Event>) => {
      state.info = action.payload;
    },
    createEventStart: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    createEventSucess: (state, action: PayloadAction<any[]>) => {
      state.isLoading = false;
      /* Immer makes us set these individually, the reset doesn't work otherwise */
      state.info = initialState.info;
      state.isLoading = false;
      state.error = null;
      state.hasSubmitted = false;
    },
    createEventFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    updateEvent: (
      state,
      action: PayloadAction<{ key: keyof Event; value: string }>
    ) => {
      state.info[action.payload.key].value = action.payload.value;
    },
    setEvent: (state, action: PayloadAction<Event>) => {
      state.info = action.payload;
    },
    resetEvent: (state) => {
      /* Immer makes us set these individually, the reset doesn't work otherwise */
      state.info = initialState.info;
      state.isLoading = false;
      state.error = null;
      state.hasSubmitted = false;
    },
    setHasSubmitted: (state, action: PayloadAction<boolean>) => {
      state.hasSubmitted = action.payload;
    },
  },
});

export const {
  setInitialStateInfo,
  createEventStart,
  createEventSucess,
  createEventFailure,
  updateEvent,
  resetEvent,
  setEvent,
  setHasSubmitted,
} = event.actions;

export default event.reducer;
