import { IonInput } from "@ionic/react";
import React from "react";

type InputProps = {
  label: string;
  type?: any;
  autocomplete?: any;
  autocapitalize?: string;
  placeholder: any;
  value: any;
  name?: any;
  className?: string;
  required?: boolean;
  onIonInput?: (arg: any) => void;
  disabled?: boolean;
  errorMessage?: any;
};

export const Input = ({
  label,
  type = "text",
  autocomplete = "off",
  placeholder = "",
  value,
  name,
  onIonInput,
  className = "",
  required = false,
  disabled = false,
  errorMessage = "",
}: InputProps) => {
  return (
    <div
      className={`bg-white-100 rounded-lg border border-black-10 pt-1 pb-2 px-3 ${className} w-full`}
    >
      <IonInput
        label={label}
        labelPlacement="stacked"
        type={type}
        autocomplete={autocomplete}
        autocapitalize={type === 'email' ? 'off' : 'on'}
        placeholder={placeholder}
        name={name}
        value={value}
        required={required}
        disabled={disabled}
        onIonInput={(e: any) => onIonInput(e)}
        className="ion-input-override"
      ></IonInput>
      {errorMessage}
    </div>
  );
};
