import {
  IonButton,
  IonItem,
  IonDatetime,
  IonDatetimeButton,
  IonModal,
  IonLabel,
  IonSelect,
  IonSelectOption,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonIcon,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  useIonViewDidEnter,
  IonTitle,
  IonContent,
  useIonAlert,
  useIonViewDidLeave,
  useIonViewWillLeave,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useQueue } from "../../hooks/useQueue";
import { add, trash } from "ionicons/icons";
import { useEvent } from "../../hooks/useEvent";
import { useHistory } from "react-router";
import { format, formatISO } from "date-fns";
import { ListContainer } from "../ui/ListContainer";
import { ListItem } from "../ui/ListItem";
import { Button } from "../ui/Button";
import { Text } from "../ui/Text";
import { Input } from "../ui/Input";
import stripeLogo from "../../assets/stripe-logo.png";
import { useAppSelector } from "../../redux/hooks";

const CreateQueue = () => {
  /* Redux */
  const { user } = useAppSelector((state) => ({ user: state.core.user }));

  /* Hooks */
  const {
    updateQueue,
    queue,
    addQueueWithCondition,
    queueInfo,
    removeQueue,
    validateQueue,
    hasSubmitted,
    setHasSubmitted,
    getQueueFieldsValid,
  } = useQueue();

  const history = useHistory();
  const [presentAlert] = useIonAlert();
  const { event, createEvent, validateEvent } = useEvent();
  const [selectedQueueIndex, setSelectedQueueIndex] = useState<number | null>(
    null
  );
  const [queuesValid, setQueuesValid] = useState<boolean>(true);

  /* Queues must be on the same or consecutive days */
  const validateQueueDates = (queues: any) => {
    // Extract dates and sort them in ascending order
    const dates = queues
      .map((queue: any) => new Date(queue.start_date_time.value))
      .sort((a: any, b: any) => a - b);

    for (let i = 0; i < dates.length - 1; i++) {
      const currentDate = dates[i];
      const nextDate = dates[i + 1];

      if (
        currentDate.getDate() === nextDate.getDate() &&
        currentDate.getMonth() === nextDate.getMonth() &&
        currentDate.getFullYear() === nextDate.getFullYear()
      ) {
        continue; // same day
      }

      currentDate.setDate(currentDate.getDate() + 1); // increment day

      if (
        currentDate.getDate() === nextDate.getDate() &&
        currentDate.getMonth() === nextDate.getMonth() &&
        currentDate.getFullYear() === nextDate.getFullYear()
      ) {
        continue; // next day
      }

      return false; // there is a gap
    }

    return true; // no gaps
  };

  /* Constants */
  const stripeEnabled =
    !!user?.id?.length && !!user?.stripe_connect_setup_complete;

  useEffect(() => {
    const dateRangeValid = validateQueueDates(queueInfo);
    setQueuesValid(dateRangeValid);
  }, [queueInfo]);

  /* If stripe isn't enabled, set the values to 0 (also do this for new queues that get added */
  useEffect(() => {
    if (!stripeEnabled) {
      queueInfo.forEach((queue, index) => {
        updateQueue(index, "max_spot_cost", 0);
        updateQueue(index, "spot_increment", 0);
      });
    }
  }, [stripeEnabled, queueInfo?.length]);

  /* Helpers */
  const handleInputChange = (queueIndex: number, key: string, value: any) => {
    updateQueue(queueIndex, key, value);
  };

  const handleDateChange = (queueIndex: number, key: any, e: CustomEvent) => {
    updateQueue(queueIndex, key, e.detail.value);
  };

  const handleSelectChange = (queueIndex: number, key: any, e: CustomEvent) => {
    updateQueue(queueIndex, key, e.detail.value);
  };

  const getInputType = (
    info: any,
    key: any,
    queueIndex: number,
    errorMessage: any
  ) => {
    /* Datepicker */
    if ((info as any)?.[key].type === "date") {
      return (
        <div
          className={`bg-white-100 rounded-lg border border-black-10 pt-3 pb-5 px-3 w-full ${
            (info as any)?.[key].className
          }`}
        >
          <IonLabel position="stacked">{(info as any)?.[key].label}</IonLabel>
          <div className="flex justify-start mt-1">
            <IonDatetimeButton
              datetime={`datetime-${(info as any)?.[key].label}`}
            ></IonDatetimeButton>
          </div>
          <IonModal keepContentsMounted={true}>
            <IonDatetime
              id={`datetime-${(info as any)?.[key].label}`}
              min={formatISO(new Date())}
              value={(info as any)?.[key].value}
              hourCycle="h12"
              onIonChange={(e: any) => handleDateChange(queueIndex, key, e)}
            >
              <span slot="title">Select a session start time</span>
            </IonDatetime>
          </IonModal>
          {errorMessage}
        </div>
      );
    } else if ((info as any)?.[key].type === "select") {
      /* Select */
      <div
        className={`bg-white-100 rounded-lg border border-black-10 !pt-2 !pb-2 px-3 w-full ${
          (info as any)?.[key].className
        }`}
      >
        <IonSelect
          aria-label={(info as any)?.[key]?.label}
          placeholder={(info as any)?.[key]?.label}
          value={(info as any)?.[key]?.value}
          label={(info as any)?.[key]?.label}
          labelPlacement="stacked"
          onIonChange={(e) => handleSelectChange(queueIndex, key, e)}
        >
          {(info as any)?.[key]?.options.map(
            (option: any, optionIndex: any) => (
              <IonSelectOption value={option.key} key={optionIndex}>
                {option.value}
              </IonSelectOption>
            )
          )}
        </IonSelect>
        {errorMessage}
      </div>;
    } else {
      /* Regular Input */

      /* We want to disable and set these fields to 0 if stripe isn't enabled */
      const isStripeInput = key === "max_spot_cost" || key === "spot_increment";

      return (
        <Input
          label={(info as any)?.[key]?.label}
          placeholder={(info as any)?.[key].label}
          value={
            !stripeEnabled && isStripeInput ? "0" : (info as any)?.[key].value
          }
          name={key}
          onIonInput={(e) => handleInputChange(queueIndex, key, e.detail.value)}
          type={(info as any)?.[key].type}
          className={(info as any)?.[key].className}
          errorMessage={errorMessage}
          disabled={!stripeEnabled && isStripeInput}
        ></Input>
      );
    }
  };
  /* Ionic Lifecycle */
  /* If there are invalid fields on load, we want to send the user back to the event page */
  useIonViewDidEnter(() => {
    const isValid = validateEvent();
    if (!isValid) {
      history.push("/create/event");
    }
  });

  useIonViewDidLeave(() => {
    setHasSubmitted(false);
  });

  return (
    <>
      <ListContainer type="CONTAINED" showBorder className="!gap-0 !p-0 !mt-0">
        {queueInfo.map((queue: any, index: any) => (
          <IonItemSliding
            disabled={index === 0}
            key={index}
            onClick={() => {
              setSelectedQueueIndex(index);
            }}
          >
            <IonItem
              className={`ion-item-sessions-override ${
                index < 5 ? "border-b border-b-black-10" : ""
              }`}
            >
              <IonLabel className="m-0 !w-full">
                <ListItem
                  className="px-4 !py-3 gap-0"
                  line1={
                    queue.description.value
                      ? queue.description.value
                      : `Queue #${index + 1}`
                  }
                  line2Items={[
                    {
                      value: format(
                        new Date(queue.start_date_time.value),
                        "EEE M/d·h:mm a"
                      ),
                      color: "gray",
                    },
                  ]}
                  line3Items={[
                    {
                      value: `${
                        queue.spot_increment?.value == 0
                          ? "Free"
                          : `$${queue.spot_increment?.value}-${queue?.max_spot_cost?.value}`
                      }`,
                      color: "green",
                    },
                    {
                      value: `${queue.num_spots.value || 0} spots`,
                      color: "gray",
                    },
                  ]}
                  errorMessage={
                    !getQueueFieldsValid(queueInfo[index]) &&
                    hasSubmitted && (
                      <div className="text-xs my-1 text-red-100">
                        Please fill out the required fields for this session
                      </div>
                    )
                  }
                  onClick={() => {
                    setSelectedQueueIndex(index);
                  }}
                />
              </IonLabel>
            </IonItem>

            <IonItemOptions side="end">
              <IonItemOption
                color="danger"
                onClick={(e) => {
                  e.stopPropagation();
                  removeQueue(index);
                }}
              >
                <IonIcon slot="start" icon={trash}></IonIcon>
                Delete
              </IonItemOption>
            </IonItemOptions>
          </IonItemSliding>
        ))}

        {queueInfo.length < 6 && (
          <Button
            color="white"
            text="Add Session"
            showBorder={false}
            transparent
            icon={<IonIcon className="ion-icon-event-override" icon={add} />}
            className="!bg-transparent"
            onClick={() => {
              addQueueWithCondition();
            }}
          />
        )}
      </ListContainer>

      <IonModal
        isOpen={selectedQueueIndex !== null}
        onDidDismiss={() => setSelectedQueueIndex(null)}
      >
        <IonHeader>
          <IonToolbar className="ion-padding pb-0 pr-0">
            <IonTitle>Edit Session</IonTitle>
            <IonButtons slot="end" className="-mr-3">
              <IonButton
                className="ion-back-button-override"
                onClick={() => setSelectedQueueIndex(null)}
              >
                Save
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <div className="p-4">
            {selectedQueueIndex !== null &&
              Object.keys(queueInfo[selectedQueueIndex]).map(
                (key: any, index: any) => (
                  <div className="block" key={index}>
                    <div>
                      {getInputType(
                        queueInfo[selectedQueueIndex],
                        key,
                        selectedQueueIndex,
                        !(queueInfo[selectedQueueIndex] as any)?.[key]?.valid &&
                          (queueInfo[selectedQueueIndex] as any)?.[key]
                            ?.required &&
                          hasSubmitted && (
                            <div className="text-xs text-red-100">
                              Please enter a valid{" "}
                              {
                                (queueInfo[selectedQueueIndex] as any)?.[key]
                                  ?.label
                              }
                            </div>
                          )
                      )}
                    </div>
                  </div>
                )
              )}
            {!stripeEnabled && (
              <>
                <Text
                  color="gray"
                  text="You can only create free events until you have setup Stripe."
                  className="text-center mt-4"
                  size="sm"
                />
                <Button
                  color="gray"
                  onClick={() =>
                    presentAlert({
                      header: "Are you sure?",
                      message: `If you setup Stripe now, you will lose the progress you have made creating this event. `,
                      buttons: [
                        { text: "Cancel", role: "cancel" },
                        {
                          text: "OK",
                          role: "ok",
                          handler: () => {
                            setSelectedQueueIndex(null);
                            history.push("/account");
                          },
                        },
                      ],
                    })
                  }
                  text="Enable Stripe for Payments (Optional)"
                  className="mt-4"
                  icon={<img src={stripeLogo} className="w-6 h-6 rounded-md" />}
                />
              </>
            )}

            <Button
              color="black"
              onClick={() => setSelectedQueueIndex(null)}
              text="Save Session"
              className="mt-4"
            />
          </div>
        </IonContent>
      </IonModal>

      {queueInfo.length >= 6 && (
        <Text
          color="gray"
          text="You have reached the max amount of sessions for this event"
          className="text-center mt-4"
        />
      )}

      {!queuesValid && hasSubmitted && (
        <Text
          color="gray"
          text="Queues must either be on the same day or consecutive days"
          className="text-center text-red-100 mt-4"
        />
      )}
      <Button
        color="black"
        onClick={() => {
          const isValid = validateQueue();

          if (isValid && queuesValid) {
            createEvent();
          }
        }}
        disabled={queue.isLoading || event.isLoading}
        text="Publish Event"
        className="mt-4"
      />
    </>
  );
};

export default CreateQueue;
