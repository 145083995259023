import React, { useState } from "react";
import { supabase } from "../supabaseClient";
import {
  IonButton,
  IonInput,
  useIonAlert,
  useIonViewDidLeave,
} from "@ionic/react";
import { useHistory } from "react-router";
import logoLogin from "../assets/logo-login.svg";
import { Heading } from "./ui/Heading";
import { Button } from "./ui/Button";
import { Text } from "./ui/Text";
import { Input } from "./ui/Input";
import { useTranslation } from "../TranslationContext";

type Props = { data?: any };

const Auth: React.FC<Props> = () => {
  /* Translations */
  const { t }: any = useTranslation();

  /* Hooks */
  const [loading, setLoading] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [email, setEmail] = useState("");
  const [loginCode, setLoginCode] = useState("");
  const history = useHistory();
  const [presentAlert] = useIonAlert();

  /* Constants */
  const url = new URL(window.location.href);
  const redirectUrl = url.searchParams.get("redirect");
  const canRedirect =
    redirectUrl?.length &&
    new URL(redirectUrl).origin === process.env.REACT_APP_BASE_URL;

  /* Helpers */
  const handleLogin = async (event: any) => {
    event.preventDefault();
    setLoginCode("");

    const emailPattern = /\S+@\S+\.\S+/;
    const isValid = emailPattern.test(email);

    if (isValid) {
      setLoading(true);
      const { error }: any = await supabase.auth.signInWithOtp({
        email,
        options: {
          emailRedirectTo: process.env.REACT_APP_BASE_URL,
        },
      });

      if (error) {
        alert(error.error_description || error.message);
      } else {
        setHasSubmitted(true);
      }
    } else {
      presentAlert({
        header: t("login.invalidEmail"),
        buttons: ["OK"],
      });
    }
    setLoading(false);
  };

  const handleValidateToken = async (event: any) => {
    event.preventDefault();

    if (loginCode.length > 0) {
      setLoading(true);

      const { error }: any = await supabase.auth.verifyOtp({
        email,
        token: loginCode,
        type: "email",
      });

      if (error) {
        alert(error.error_description || error.message);
      } else {
        if (canRedirect) {
          window.location.href = redirectUrl;
        } else {
          history.push("/");
        }
      }
      setLoading(false);
    } else {
      presentAlert({
        header: t("login.invalidLoginCode"),
        buttons: ["OK"],
      });
    }
  };

  /* Lifecycle hooks */
  useIonViewDidLeave(() => {
    setLoginCode("");
    setEmail("");
    setLoading(false);
    setHasSubmitted(false);
  });

  return (
    <div className="h-full flex flex-col items-center justify-start pt-28">
      <img
        src={logoLogin}
        alt="Priority Entry"
        className="h-auto w-full max-w-[180px] mb-12 block"
      />
      <div className="flex flex-col justify-start w-full">
        {!hasSubmitted ? (
          <div className="grid grid-cols-1 gap-2">
            <Heading size="2xl" color="black" text={t("login.login")} />
            <div className="mb-6">
              <Input
                label={t("login.email")}
                type="email"
                autocomplete="email"
                placeholder={t("login.emailPlaceholder")}
                value={email}
                required={true}
                onIonInput={(e: any) => {
                  setEmail(e.target.value);
                }}
              ></Input>
            </div>

            {/* <Button
              color="gray"
              onClick={(e) => alert("Coming soon, use email codes for now")}
              text={"Continue with password"}
            /> */}
            <Button
              color="black"
              disabled={loading}
              onClick={(e) => handleLogin(e)}
              text={
                loading
                  ? t("login.sendingLoginCode")
                  : t("login.loginWithEmailCode")
              }
            />
          </div>
        ) : (
          <div className="grid grid-cols-1 gap-2">
            <Heading
              size="2xl"
              color="black"
              text={t("login.checkYourEmail")}
            />
            <Text
              color="gray"
              size="sm"
              className="mt-2 mb-2"
              text={
                <div>
                  {t("login.weSentCodeTo")}{" "}
                  <span className="font-semibold">{email}</span>
                </div>
              }
            />
            <Input
              label={t("login.yourCode")}
              placeholder="0 0 0 0 0 0"
              value={loginCode}
              required={true}
              onIonInput={(e: any) => {
                setLoginCode(e.target.value);
              }}
            ></Input>

            <div onClick={() => setHasSubmitted(false)}></div>
            <Text
              color="gray"
              size="sm"
              className="mt-2 mb-6 text-center"
              text={
                <div>
                  {`${t("login.noCode")} `}
                  <span
                    className="underline underline-offset-2"
                    onClick={(e) => setHasSubmitted(false)}
                  >
                    {t("login.resendCode")}
                  </span>
                </div>
              }
            />

            <div className="grid grid-cols-[auto,auto] gap-2">
              <Button
                color="gray"
                onClick={(e) => setHasSubmitted(false)}
                text={t("login.cancel")}
              />
              <Button
                color="black"
                disabled={loading}
                onClick={handleValidateToken}
                text={loading ? t("login.verifying") : t("login.verify")}
              />
            </div>
            <Text
              color="gray"
              size="sm"
              className="mt-6 mb-6 text-center"
              text={
                <div>
                  {t("login.bySigningUp")}{" "}
                  <a
                    href="https://www.priorityentry.net/privacy-policy"
                    rel="noreferrer"
                    target="_blank"
                    className="underline"
                  >
                    {t("login.privacyPolicy")}
                  </a>{" "}
                  {t("login.and")}{" "}
                  <a
                    href="https://www.priorityentry.net/terms-of-service"
                    rel="noreferrer"
                    target="_blank"
                    className="underline"
                  >
                    {t("login.termsOfService")}
                  </a>
                </div>
              }
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Auth;
