function validateText(text: any) {
  return text?.trim() !== "";
}

function validateNumber(num: any) {
  const parsed = parseFloat(num);
  return !isNaN(parsed) && parsed >= 0;
}

function validateEmail(email: any) {
  const emailPattern = /\S+@\S+\.\S+/;
  return emailPattern.test(email);
}

function validatePhone(phone: any) {
  const phonePattern = /^\d{10}$/;
  return phonePattern.test(phone);
}

function validateSelect(value: any, options: any) {
  return value.length;
}

function validateDate(value: any) {
  return !isNaN(new Date(value).getTime());
}

function validateFields(info: any) {
  const updatedInfo = { ...info };

  const getIsValid = (value: any, required: boolean, func: any) => {
    return required ? func(value) : value ? func(value) : true;
  };

  for (const field in updatedInfo) {
    const { value, type, options, hidden, required } = updatedInfo[field];
    let isValid;

    if (hidden) {
      continue; // skip validation for hidden fields
    }

    switch (type) {
      case "text":
        isValid = getIsValid(value, required, validateText);
        break;
      case "email":
        isValid = getIsValid(value, required, validateEmail);
        break;
      case "tel":
        isValid = getIsValid(value, required, validatePhone);
        break;
      case "number":
        isValid = getIsValid(value, required, validateNumber);
        break;
      case "select":
        isValid = getIsValid(value, required, validateSelect);
        break;
      case "date":
        isValid = getIsValid(value, required, validateDate);
        break;
      default:
        isValid = true;
    }

    /* Specifically check postal codes*/
    if (field === "postal_code") {
      if (required) {
        const postalCodePattern = /^[0-9]{5}(?:-[0-9]{4})?$/;
        isValid = postalCodePattern.test(value);
      }
    }

    updatedInfo[field] = { ...updatedInfo[field], valid: isValid };
  }
  return updatedInfo;
}

export default validateFields;
