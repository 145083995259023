import React, { useEffect } from "react";
import { Route, useHistory, useLocation } from "react-router-dom";
import {
  IonIcon,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  setupIonicReact,
} from "@ionic/react";
import AttendingEvents from "./pages/AttendingEvents";
import MyEvents from "./pages/MyEvents";
import ExploreEvents from "./pages/ExploreEvents";
import CreateEvent from "./pages/CreateEvent";
import CreateQueue from "./pages/CreateQueue";
import AccountHome from "./pages/account/Home";
import AccountCompany from "./pages/account/Company";
import AccountManageUsers from "./pages/account/ManageUsers";
import AccountSettings from "./pages/account/Settings";
import Event from "./pages/Event";
import Queue from "./pages/Queue";
import Share from "./pages/Share";
import Scan from "./pages/Scan";
import Redeem from "./pages/Redeem";
import OnboardingInfo from "./pages/OnboardingInfo";
import OnboardingCompany from "./pages/OnboardingCompany";
import OnboardingInstructions from "./pages/OnboardingInstructions";
import Login from "./pages/Login";
import NotFound from "./pages/NotFound";
import ProtectedRoute from "./ProtectedRoute";
import {
  ticketOutline,
  ticketSharp,
  personCircleOutline,
  searchOutline,
} from "ionicons/icons";
import logo from "./assets/logo-bw.svg";
import logoSelected from "./assets/logo-bw-selected.svg";
import { useAppSelector } from "./redux/hooks";

const Routes: React.FC = () => {
  /* Redux */
  const { activeCompany } = useAppSelector((state) => ({
    activeCompany: state.company.activeCompany,
  }));

  const location = useLocation();
  const history = useHistory();

  // define routes where you don't want to display the tab bar
  const hideTabBarRoutes = [
    "/login",
    "/onboarding/info",
    "/onboarding/company",
    "/onboarding/instructions",
  ];

  const shouldDisplayTabBar = !hideTabBarRoutes.includes(location.pathname);

  /* Create icon/active mappings for the nav */
  const tabRoutes = [
    ...(activeCompany?.id
      ? [{ path: "/events/my", label: "My Events", icon: logo }]
      : []),
    {
      path: "/events/attending",
      label: "Tickets",
      icon: ticketOutline,
      otherPathIncludes: ["/event"],
      otherPathExcludes: ["/events/my", "/events/explore"],
    },
    { path: "/events/explore", label: "Explore", icon: searchOutline },
    {
      path: "/account",
      label: "Account",
      icon: personCircleOutline,
      otherPathIncludes: ["/event", "/"],
    },
  ];

  /* Disable swipe back on the scan page, since things get weird */
  useEffect(() => {
    if (location.pathname?.toLowerCase().includes("/scan/")) {
      setupIonicReact({
        swipeBackEnabled: false,
      });
    } else {
      setupIonicReact({
        swipeBackEnabled: true,
      });
    }
  }, [location]);

  /*
    On page load, if we're on the attending page (default) and there is an active company,
    redirect to the my events page
  */
  useEffect(() => {
    if (
      activeCompany?.id &&
      location?.pathname?.toLowerCase() !== "/events/my" &&
      (location?.pathname?.toLowerCase() === "/events/attending" ||
        location?.pathname?.toLowerCase() === "/")
    ) {
      history.push("/events/my");
    }
  }, [activeCompany?.id]);

  return (
    <IonTabs>
      <IonRouterOutlet>
        <ProtectedRoute
          exact
          path="/event/:eventId/scan/:queueId"
          component={Scan}
        />
        <ProtectedRoute
          exact
          path="/event/:eventId/redeem/:queueId"
          component={Redeem}
        />
        <Route exact path="/event/:eventId/queue/:queueId" component={Queue} />
        <Route exact path="/event/:eventId/share" component={Share} />
        <Route exact path="/event/:eventId" component={Event} />

        <ProtectedRoute exact path="/create/event" component={CreateEvent} />
        <ProtectedRoute exact path="/create/queue" component={CreateQueue} />

        <ProtectedRoute
          exact
          path={["/events/attending", "/events", ""]}
          component={AttendingEvents}
        />
        <ProtectedRoute exact path={["/events/my"]} component={MyEvents} />
        <ProtectedRoute
          exact
          path={["/events/explore"]}
          component={ExploreEvents}
        />

        <ProtectedRoute path="/account" component={AccountHome} exact />
        <ProtectedRoute
          path="/account/company"
          component={AccountCompany}
          exact
        />
        <ProtectedRoute
          path="/account/manage-users"
          component={AccountManageUsers}
          exact
        />

        <ProtectedRoute
          path="/account/settings"
          component={AccountSettings}
          exact
        />
        {/* No Tab Bar  */}

        <ProtectedRoute path="/onboarding/info" component={OnboardingInfo} />
        <ProtectedRoute
          path="/onboarding/company"
          component={OnboardingCompany}
        />
        <ProtectedRoute
          path="/onboarding/instructions"
          component={OnboardingInstructions}
        />

        <Route path="/login" component={Login} />
        <Route render={() => <NotFound />} />
      </IonRouterOutlet>
      <IonTabBar
        slot="bottom"
        className={`ion-tab-bar-override ${
          !shouldDisplayTabBar ? "hidden" : "flex"
        } border-t border-t-black-10 pt-3 pb-6 px-[10%]`}
      >
        {tabRoutes.map((tabRoute, index) => (
          <IonTabButton
            tab={tabRoute.path}
            href={tabRoute.path}
            key={index}
            selected={
              location.pathname.startsWith(tabRoute.path) ||
              (tabRoute.path === "/events/attending" &&
                location.pathname === "/") ||
              (tabRoute.otherPathIncludes &&
                tabRoute.otherPathIncludes.some((path) =>
                  location.pathname.startsWith(path)
                ) &&
                tabRoute.otherPathExcludes &&
                tabRoute.otherPathExcludes.every(
                  (path) => !location.pathname.startsWith(path)
                ))
            }
          >
            <IonIcon
              aria-hidden="true"
              aria-label={tabRoute.label}
              icon={
                tabRoute.path === "/events/my" &&
                location.pathname.startsWith("/events/my")
                  ? logoSelected
                  : tabRoute.path.startsWith("/event") &&
                    tabRoute.otherPathIncludes &&
                    !tabRoute.otherPathExcludes.some((path) =>
                      location.pathname.startsWith(path)
                    ) &&
                    location.pathname.startsWith("/event")
                  ? ticketSharp
                  : tabRoute.icon
              }
            />
          </IonTabButton>
        ))}
      </IonTabBar>
    </IonTabs>
  );
};

export default Routes;
