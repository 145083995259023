import React from "react";
import { IonPage } from "@ionic/react";
import { useHistory } from "react-router";
import EventList from "../components/events/EventList";
import { useCurrentPageActive } from "../hooks/useCurrentPageActive";
import { Header } from "../components/ui/Header";
import { useTranslation } from "../TranslationContext";

const AttendingEvents: React.FC = () => {
  /* Translations */
  const { t }: any = useTranslation();

  /* Hooks */
  const isCurrentPageActive = useCurrentPageActive();
  const history = useHistory();

  return (
    <IonPage>
      <Header title={t("pages.attendingEvents")} type="DISPLAY" />
      <EventList isCurrentPageActive={isCurrentPageActive} type="ATTENDING" />
    </IonPage>
  );
};

export default AttendingEvents;
