import { useEffect, useRef, useState } from "react";
import { eventService } from "../service/eventService";
import { useInfiniteQuery, useQuery } from "react-query";
import { useAppSelector } from "../redux/hooks";
import { companyService } from "../service/companyService";
import { useTranslation } from "../TranslationContext";

interface EventsFetchProps {
  userId?: string;
  isCurrentPageActive: boolean;
  type: string;
}

export const useEvents = ({
  userId,
  isCurrentPageActive,
  type,
}: EventsFetchProps) => {
  /* Translations */
  const { languageCode }: any = useTranslation();

  /* Constants */
  const searchRadiusMiles = 50;
  const pageSize = 10;

  const { activeCompany, session } = useAppSelector((state) => ({
    activeCompany: state.company.activeCompany,
    session: state.core.session,
  }));

  /* Refs */
  const currentSegmentData = useRef<any>({});
  const userCompaniesRef = useRef<any>({});

  /* Hooks */
  const [postalCode, setPostalCode] = useState<string>("");

  /* React Query Hooks */
  const exploreEventsQuery = useInfiniteQuery(
    ["exploreEvents", postalCode],
    ({ pageParam = 0 }) =>
      eventService.getEvents({
        postalCode: postalCode || "",
        searchRadiusMiles,
        pageNum: pageParam,
        pageSize,
        languageCode,
      }),
    {
      getNextPageParam: (lastPage, allPages) =>
        lastPage.length < pageSize ? undefined : allPages.length,
      enabled: type === "EXPLORE" && isCurrentPageActive,
      staleTime: 1000 * 30,
      retry: 0,
    }
  );

  const myEventsQuery = useInfiniteQuery(
    ["myEvents", userId, activeCompany?.id],
    ({ pageParam = 0 }) =>
      eventService.getEventsCreatedByMe(
        userId,
        pageParam,
        pageSize,
        activeCompany?.id,
        languageCode
      ),
    {
      getNextPageParam: (lastPage, allPages) =>
        lastPage.length < pageSize ? undefined : allPages.length,
      enabled:
        type === "MY_EVENTS" &&
        isCurrentPageActive &&
        !!activeCompany?.id &&
        !!userId,
      staleTime: 1000 * 30,
    }
  );

  const attendingEventsQuery = useInfiniteQuery(
    ["attendingEvents", userId],
    ({ pageParam = 0 }) =>
      eventService.getEventsUserIsAttending(
        userId,
        pageParam,
        pageSize,
        languageCode
      ),
    {
      getNextPageParam: (lastPage, allPages) =>
        lastPage.length < pageSize ? undefined : allPages.length,
      enabled: type === "ATTENDING" && isCurrentPageActive,
      staleTime: 1000 * 30,
    }
  );

  const {
    data: userCompanies,
    refetch: refetchUserCompanies,
    isLoading: userCompaniesLoading,
  } = useQuery(
    [session?.user?.id],
    () => companyService.getCompaniesByUserId(session?.user?.id),
    {
      enabled:
        session?.user?.id &&
        session?.user?.user_companies?.length &&
        type === "MY_EVENTS",
      staleTime: 1000 * 60,
      retry: 0,
    }
  );

  switch (type) {
    case "EXPLORE":
      currentSegmentData.current = exploreEventsQuery;
      break;
    case "MY_EVENTS":
      currentSegmentData.current = myEventsQuery;

      break;
    case "ATTENDING":
      currentSegmentData.current = attendingEventsQuery;

      break;
    default:
      currentSegmentData.current = exploreEventsQuery;
  }

  const clearExploreData = () => {
    exploreEventsQuery.remove();
  };

  const refreshData = () => {
    switch (type) {
      case "EXPLORE":
        exploreEventsQuery.remove();
        exploreEventsQuery.refetch();

        break;
      case "MY_EVENTS":
        myEventsQuery.remove();
        myEventsQuery.refetch();

        break;
      case "ATTENDING":
        attendingEventsQuery.remove();
        attendingEventsQuery.refetch();
        break;
      default:
        break;
    }
  };

  const refreshActiveFilter = () => {
    if (type === "EXPLORE") {
      exploreEventsQuery.refetch();
    } else if (type === "MY_EVENTS") {
      myEventsQuery.refetch();
    } else if (type === "ATTENDING") {
      attendingEventsQuery.refetch();
    }
  };

  /*
    If the page is active AND has already been fetched (i.e. we are navigating back)
    then we want to refresh the data
  */
  useEffect(() => {
    if (
      isCurrentPageActive &&
      currentSegmentData.current?.isFetchedAfterMount
    ) {
      refreshActiveFilter();
    }
  }, [isCurrentPageActive]);

  /* Save companies to a ref for navigating to different pages */
  useEffect(() => {
    if (userCompanies?.length) {
      userCompaniesRef.current = userCompanies;
    }
  }, [userCompanies]);

  const loadNextPage = () => {
    const nextPage = currentSegmentData?.current?.data?.pages.length || 0;
    currentSegmentData?.current?.fetchNextPage({ pageParam: nextPage });
  };

  return {
    currentSegmentData: currentSegmentData.current,
    refreshData,
    clearExploreData,
    loadNextPage,
    postalCode,
    setPostalCode,
    userCompanies: userCompaniesRef?.current?.length
      ? userCompaniesRef?.current
      : [],
    userCompaniesLoading,
  };
};
