import React, { useEffect, useRef } from "react";
import { useHistory, useParams } from "react-router";
import { useAppSelector } from "../../redux/hooks";
import { spotService } from "../../service/spotService";
import { eventService } from "../../service/eventService";
import { useQuery } from "react-query";
import { Loader } from "../core/Loader";
import useScanChanges from "../../hooks/realtime/useScanChanges";
import { QrCode } from "../ui/QrCode";
import { EventCard } from "../ui/EventCard";
import { SpotCallout } from "../ui/SpotCallout";
import { TextCallout } from "../ui/TextCallout";
import { format } from "date-fns";
import { getAddressDisplay } from "../../utils";
import { Button } from "../ui/Button";
import { useTranslation } from "../../TranslationContext";

type Params = {
  queueId: string;
  eventId: string;
};

interface Props {
  isCurrentPageActive?: boolean;
}

const Buyer: React.FC<Props> = ({ isCurrentPageActive }) => {
  /* Translations */
  const { t, languageCode }: any = useTranslation();

  /* Redux */
  const { user } = useAppSelector((state) => ({ user: state.core.user }));

  /* Hooks */
  const { queueId, eventId } = useParams<Params>();
  const [event, setEvent] = React.useState<any>({});
  const [checkin, setCheckin] = React.useState<any>({});
  const history = useHistory();

  /* React Query */
  const {
    data: eventRes,
    refetch: refetchEvent,
    isLoading: isEventLoading,
  } = useQuery(
    ["event", eventId, user?.id],
    () => eventService.getEventById(eventId, user?.id, languageCode),
    {
      enabled: isCurrentPageActive && !!eventId?.length && !!user?.id?.length,
    }
  );

  const {
    data: spotRes,
    refetch: refetchSpot,
    isLoading: isSpotLoading,
  } = useQuery(
    ["spotRes", user?.id],
    async () => {
      const validatedSpots = await spotService.validateSpot(
        user?.id,
        queueId,
        languageCode
      );
      return validatedSpots[0];
    },
    {
      enabled:
        !isEventLoading && !!user?.id && !!queueId && isCurrentPageActive,
    }
  );

  /* Realtime */
  useScanChanges(spotRes, setCheckin);

  /*
    Watchers
  */
  /* 
    When the event and spot have been fetched, take the description
    from the queue and add it to the event object
  */
  useEffect(() => {
    if (eventRes && spotRes) {
      setEvent({
        ...eventRes,
        ...{
          description: spotRes?.queue?.description,
          queue: eventRes?.queues?.find(
            (q: any) => q.id === spotRes?.queue?.id
          ),
        },
      });
    }
  }, [eventRes, spotRes]);

  /* Constants */
  const addressDisplay = getAddressDisplay(event?.address_available_date_time);

  if (isEventLoading || isSpotLoading || !isCurrentPageActive)
    return <Loader className="min-h-[200px]" />;

  return (
    <div className="grid gap-1">
      <QrCode
        url={`${process.env.REACT_APP_BASE_URL}?eventId=${eventId}&queueId=${queueId}&userId=${user?.id}`}
        className="!rounded-t-2xl"
      />

      {Object.keys(checkin).length > 0 && (
        <TextCallout
          title={t("event.redeem.success.title")}
          description={`${t("event.redeem.success.segment1")} ${format(
            new Date(new Date(checkin.created_at)),
            "EEE M/d·h:mm a"
          )}`}
          showBoxShadow
          className="rounded-sm"
          titleClassName="text-green-100"
        />
      )}

      <SpotCallout
        line1={`${spotRes?.purchased_by?.first_name} ${spotRes?.purchased_by?.last_name}`}
        line2={spotRes?.purchased_by?.email || spotRes?.purchased_by?.phone}
        number={spotRes?.derived_spot_num}
      />

      <EventCard
        line1={event?.queue?.description}
        line2={event?.title}
        company={event?.user?.company_name}
        date={
          event?.queue?.start_date_time &&
          format(new Date(event?.queue?.start_date_time), "EEE M/d·h:mm a")
        }
        address={{
          line1: event?.address_line_1,
          line2: event?.address_line_2,
          city_state_postal: `${event?.city}, ${event?.state} ${event?.postal_code}`,
          address_display_message: addressDisplay.displayText,
        }}
        showAddress={addressDisplay?.showAddress}
        className="!rounded-none"
      />
      <Button
        text={t("event.redeem.backToEvent")}
        color="gray"
        className="!rounded-t-none"
        onClick={() => {
          history.push(`/event/${eventId}`);
        }}
      />
    </div>
  );
};

export default Buyer;
