import { useEffect, useRef } from "react";
import { supabase } from "../../supabaseClient";
import { useIonViewDidLeave } from "@ionic/react";

const useScanChanges = (spot: any, setCheckin: any) => {
  const subscription = useRef<any>(null);

  useEffect(() => {
    if (spot?.id) {
      subscription.current = supabase
        .channel("scan_changes")
        .on(
          "postgres_changes",
          {
            event: "INSERT",
            schema: "public",
            table: "scan",
            filter: `spot_id=eq.${spot.id}`,
          },
          async (payload) => {
            setCheckin(payload?.new);
          }
        )
        .on(
          "postgres_changes",
          {
            event: "UPDATE",
            schema: "public",
            table: "scan",
            filter: `spot_id=eq.${spot.id}`,
          },
          (payload) => {
            setCheckin(payload?.new);
          }
        )
        .on(
          "postgres_changes",
          {
            event: "DELETE",
            schema: "public",
            table: "scan",
            filter: `spot_id=eq.${spot.id}`,
          },
          (payload) => {
            setCheckin(payload?.new);
          }
        )
        .subscribe();
    }
  }, [spot?.id]);

  useIonViewDidLeave(() => {
    subscription?.current?.unsubscribe();
  });
};

export default useScanChanges;
