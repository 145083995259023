import React from "react";
import { IonPage } from "@ionic/react";
import EventList from "../components/events/EventList";
import { useCurrentPageActive } from "../hooks/useCurrentPageActive";

const MyEvents: React.FC = () => {
  /* Hooks */
  const isCurrentPageActive = useCurrentPageActive();

  return (
    <IonPage>
      <EventList isCurrentPageActive={isCurrentPageActive} type="MY_EVENTS" />
    </IonPage>
  );
};

export default MyEvents;
