import { supabase } from "../supabaseClient";

async function createScanRecord(userId: string, spotId: string) {
  const { data, error } = await supabase
    .from("scan")
    .insert([{ user_id: userId, spot_id: spotId }]);

  if (error) {
    console.error("Error creating scan record:", error);
    throw error;
  }

  return data;
}

async function removeScanRecord(userId: string, spotId: string) {
  const { data, error } = await supabase
    .from("scan")
    .delete()
    .eq("user_id", userId)
    .eq("spot_id", spotId);

  if (error) {
    console.error("Error removing scan record:", error);
    throw error;
  }

  return data;
}

async function getScansByUserBySpot(userId: string, spotId: string) {
  const { data, error } = await supabase
    .from("scan")
    .select(
      `*,
        user:user_id (
            id
        )`
    )
    .eq("spot_id", spotId)
    .eq("user_id", userId)
    .eq("active", true)
    .order("created_at", { ascending: false });

  if (error) {
    console.error("Error fetching scans by event:", error);
    throw error;
  }

  return data;
}

export const scanService = {
  createScanRecord,
  removeScanRecord,
  getScansByUserBySpot,
};
