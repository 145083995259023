import React, { useRef } from "react";
import QRCode from "react-qr-code";
import { downloadQR } from "../../utils";
import { useIonToast } from "@ionic/react";
import { useTranslation } from "../../TranslationContext";

type QrCodeProps = {
  url: string;
  className?: string;
};

export const QrCode = ({ url, className = "" }: QrCodeProps) => {
  /* Translations */
  const { t }: any = useTranslation();

  /* Refs */
  const qrRef = useRef(null);

  /* Hooks */
  const [presentToast] = useIonToast();

  const toast = (message: any) => {
    presentToast({
      message: t("core.qrCodeSaved"),
      duration: 2000,
      position: "bottom",
      cssClass: "success-toast",
    });
  };
  return (
    <div
      className={`p-12 flex items-center justify-center bg-white-100 rounded-sm shadow-[0_1px_2px_-0px_rgba(0,0,0,0.1)] ${className}`}
      onClick={() => downloadQR(qrRef, toast)}
    >
      <div ref={qrRef}>
        <QRCode value={url} />
      </div>
    </div>
  );
};
