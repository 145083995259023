import React from "react";
import { IonContent, IonPage } from "@ionic/react";
import QueueComponent from "../components/event/Queue";
import { useCurrentPageActive } from "../hooks/useCurrentPageActive";
import { Header } from "../components/ui/Header";
import { useTranslation } from "../TranslationContext";

const Queue: React.FC = () => {
  /* Translations */
  const { t }: any = useTranslation();

  const isCurrentPageActive = useCurrentPageActive();

  return (
    <IonPage>
      <Header
        title={t("pages.queue")}
        showBackButton={true}
        backButtonHref="/events"
      />
      <IonContent fullscreen className="ion-padding-page">
        <QueueComponent isCurrentPageActive={isCurrentPageActive} />
      </IonContent>
    </IonPage>
  );
};

export default Queue;
