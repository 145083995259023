import { useIonAlert, IonIcon, IonModal } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { Capacitor } from "@capacitor/core";
import { useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import PricingChart from "../../components/core/PricingChart";
import { eventService } from "../../service/eventService";
import { spotService } from "../../service/spotService";
import { useAppSelector } from "../../redux/hooks";
import { scanService } from "../../service/scanService";
import { EventCard } from "../ui/EventCard";
import { Loader } from "../core/Loader";
import useSpotChanges from "../../hooks/realtime/useSpotChanges";
import {
  calculateSpotPrice,
  convertStripePriceToDollars,
  getAddressDisplay,
} from "../../utils";
import { format } from "date-fns";
import { Button } from "../ui/Button";
import {
  bookmarkOutline,
  checkmarkCircleOutline,
  qrCodeOutline,
  scanOutline,
} from "ionicons/icons";
import { TextCallout } from "../ui/TextCallout";
import { ListContainer } from "../ui/ListContainer";
import { ListItem } from "../ui/ListItem";
import { ListDisplayBox } from "../ui/ListDisplayBox";
import { Text } from "../ui/Text";
import useScanChanges from "../../hooks/realtime/useScanChanges";
import StripeContainer from "./StripeContainer";
import { useTranslation } from "../../TranslationContext";

interface Props {
  data?: any;
  isCurrentPageActive?: boolean;
}

type Params = {
  eventId: string;
  queueId: string;
};

const isWeb = Capacitor.getPlatform() === "web";

const Queue: React.FC<Props> = ({ isCurrentPageActive }) => {
  /* Translations */
  const { t, languageCode }: any = useTranslation();

  /* Redux */
  const { user } = useAppSelector((state) => ({ user: state.core.user }));

  /* Hooks */
  const { eventId, queueId } = useParams<Params>();
  const [isCreatedByMe, setIsCreatedByMe] = useState<boolean>(null);
  const [isAuthorizedUser, setIsAuthorizedUser] = useState<boolean>(false);
  const [initialSpots, setInitialSpots] = useState<any>([]);
  const [spots, setSpots] = useState<any>([]);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);

  const [activeQueue, setActiveQueue] = useState<any>({});
  const [presentAlert] = useIonAlert();
  const history = useHistory();

  /* React Query */
  const {
    data: event,
    refetch: refetchEvent,
    isLoading: isEventLoading,
  } = useQuery(
    ["event", eventId],
    () => eventService.getEventById(eventId, user?.id, languageCode),
    {
      enabled:
        isCurrentPageActive &&
        !!eventId?.length &&
        !!queueId?.length &&
        !!user?.id?.length,
      staleTime: 1000 * 10,
    }
  );

  const {
    data: attendeesRes,
    refetch: refetchAttendees,
    isLoading: isAttendeesLoading,
  } = useQuery(
    ["attendeesRes", queueId, user?.id, isCreatedByMe, isAuthorizedUser],
    () =>
      spotService.getAttendeesByQueue(
        queueId,
        isCreatedByMe || isAuthorizedUser,
        user?.id
      ),
    {
      enabled:
        !isEventLoading &&
        isCreatedByMe !== null &&
        isAuthorizedUser !== null &&
        isCurrentPageActive,
    }
  );

  const {
    data: spotRes,
    refetch: refetchSpot,
    isLoading: isSpotLoading,
  } = useQuery(
    ["spotRes", user?.id],
    async () => {
      const validatedSpots = await spotService.validateSpot(
        user?.id,
        queueId,
        languageCode
      );
      return validatedSpots[0];
    },
    {
      enabled:
        !isEventLoading && !!user?.id && !!queueId && isCurrentPageActive,
    }
  );

  /* Constants */
  const nextSpotAvailable = activeQueue?.num_spots - spots?.length;
  const addressDisplay = getAddressDisplay(event?.address_available_date_time);

  const hasPurchasedSpot = (userId: string) => {
    return !!spots?.find(
      (spot: any) => spot?.purchased_by === userId && spot?.active === true
    );
  };

  const getSpotPurchasedByUser = (userId: string) => {
    return spots?.find(
      (spot: any) => spot?.purchased_by === userId && spot.active === true
    );
  };

  const [spotPurchasedByUser, setSpotPurchasedByUser] = useState<any>({});

  useEffect(() => {
    if (spots?.length) {
      setSpotPurchasedByUser(getSpotPurchasedByUser(user?.id));
    }
  }, [spots]);

  const currentSpotPrice = calculateSpotPrice(activeQueue);
  const hasScanned = spotPurchasedByUser?.scans?.[0]?.updated_at?.length;

  const totalStripeAmount = spots?.reduce((acc: any, curr: any) => {
    return acc + curr?.stripe_amount_total;
  }, 0);

  const shouldHideButton = () => {
    const THIRTY_MINUTES = 30 * 60 * 1000; // 30 minutes in milliseconds
    const queueStartTime = new Date(activeQueue?.start_date_time);
    const cutoffTime = new Date(queueStartTime.getTime() - THIRTY_MINUTES);
    const currentTime = new Date();

    return currentTime > cutoffTime;
  };

  const getSpotInfo = () => {
    if (spotRes?.id) {
      return (
        <div>
          Spot #{spotRes?.derived_spot_num} ·
          <span className="text-green-100">
            {spotRes?.stripe_amount_total
              ? `$${convertStripePriceToDollars(spotRes?.stripe_amount_total)}`
              : t("queue.freeCap")}
          </span>
        </div>
      );
    } else if (isCreatedByMe || isAuthorizedUser) {
      const spotsSold = activeQueue?.num_spots - nextSpotAvailable;
      return `${spotsSold}/${activeQueue?.num_spots} ${t(
        "queue.spotsReserved"
      )}`;
    }
    return "";
  };

  /* Realtime */
  useSpotChanges(activeQueue.id, isCreatedByMe || isAuthorizedUser, setSpots);
  useScanChanges(spotRes, (scan: any) => {
    setSpotPurchasedByUser({ ...spotPurchasedByUser, scans: [scan] });
  });

  /* Helpers */
  const clientSecret = new URLSearchParams(window.location.search).get(
    "payment_intent_client_secret"
  );
  const redirectStatus = new URLSearchParams(window.location.search).get(
    "redirect_status"
  );

  /* Handlers */
  const reserveSpot = async (queueId: string) => {
    if (user?.id) {
      spotService
        .reserveSpot(user.id, queueId, activeQueue.num_spots, currentSpotPrice)
        .then((spotRes: any) => {
          /* 
            If the spot isn't free, opening the modal is enough to kickoff
            the stripe checkout process since it mounts when that component loads
          */
          if (currentSpotPrice > 0) {
            setIsPaymentModalOpen(true);
          }

          /* Update the UI */
          setSpots((prevSpots: any) => {
            if (prevSpots.find((spot: any) => spot.id === spotRes?.[0].id)) {
              return prevSpots;
            }
            return [...prevSpots, spotRes?.[0]];
          });
        });
    }
  };

  const removeReservation = (spotId: string) => {
    if (user?.id) {
      spotService.removeReservation(spotId).then((spotRes: any) => {
        setSpots(spots.filter((spot: any) => spot.id !== spotRes?.[0].id));
      });
    }
  };

  const checkUserIn = async (userId: string, spotId: string) => {
    if (userId && spotId) {
      const spotRes = await scanService.createScanRecord(userId, spotId);

      setSpots(
        spots.map((spot: any) => {
          if (spot.id === spotId) {
            spot.scans = [
              {
                updated_at: new Date().toISOString(),
              },
            ];
          }
          return spot;
        })
      );
    }
  };

  const removeUserCheckin = async (userId: string, spotId: string) => {
    if (userId && spotId) {
      const spotRes = await scanService.removeScanRecord(userId, spotId);

      setSpots(
        spots.map((spot: any) => {
          if (spot.id === spotId) {
            spot.scans = [];
          }
          return spot;
        })
      );
    }
  };

  const reserveOrRemoveSpot = async () => {
    if (hasPurchasedSpot(user?.id) && !hasScanned) {
      presentAlert({
        header: t("queue.removeReservationPrompt"),
        message: t("queue.removeReservationMessage"),
        buttons: [
          { text: t("queue.cancel"), role: "cancel" },
          {
            text: t("queue.ok"),
            role: "confirm",
            handler: () => removeReservation(spotPurchasedByUser.id),
          },
        ],
      });
    } else if (user?.id) {
      reserveSpot(activeQueue?.id);
    }
  };

  /* Watchers */
  useEffect(() => {
    if (event) {
      const didUserCreateEvent = event?.created_by === user?.id;
      setIsCreatedByMe(didUserCreateEvent);
      setIsAuthorizedUser(event?.is_authorized_user);
      setActiveQueue(event?.queues?.find((queue: any) => queue.id === queueId));
    }
  }, [event, user?.id]);

  useEffect(() => {
    if (attendeesRes) {
      setSpots(attendeesRes);
      setInitialSpots(attendeesRes);
    }
  }, [attendeesRes]);

  /*
    Stripe elements actually submits a form with a redirect, so it re-opens this page with a search param.
    If we go from not having a param to having one, we should refetch the event so we can ensure the spot is reserved.
  */
  useEffect(() => {
    if (redirectStatus === "succeeded") {
      refetchEvent();
    }
  }, [redirectStatus]);

  /*
    Since stripe gives us params, we should remove them once the user has loaded the page up. Otherwise 
    the success/failure message might show up again if the user navigates back to the page. Using popstate,
    this won't happen until they navigate away vs on the page load 
  */
  useEffect(() => {
    const handlePopState = () => {
      history.replace(window.location.pathname);
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [history]);

  /*
    A few things. If the modal is closed, OR if the modal is closed and payment was not successful, OR 
    if payment was just never successful, we should remove the reservation. This works, but be VERY careful if updating this
    because it could cause spots to be incorrectly removed (paid or unpaid). 

    We also clean up the spots via webhooks and cron jobs, but this is a safety net to do it immediately in the most common 
    use cases. 
  */
  useEffect(() => {
    if (
      !isAttendeesLoading &&
      currentSpotPrice > 0 &&
      spotPurchasedByUser?.id &&
      !isPaymentModalOpen &&
      redirectStatus !== "succeeded"
    ) {
      if (
        spotPurchasedByUser?.id &&
        spotPurchasedByUser?.active === true &&
        spotPurchasedByUser?.stripe_amount_total > 0 &&
        (spotPurchasedByUser.stripe_session_id === null ||
          spotPurchasedByUser.stripe_payment_status === null)
      ) {
        setSpotPurchasedByUser({ ...spotPurchasedByUser, active: false });
        spotService.removeReservation(spotPurchasedByUser.id);
        refetchSpot();
      }
    }
  }, [
    isAttendeesLoading,
    spots,
    initialSpots,
    spotPurchasedByUser?.id,
    isPaymentModalOpen,
  ]);

  /* Helper UI Elements */
  const renderQRCodeButton = () => {
    if (
      (isCreatedByMe || isAuthorizedUser || hasPurchasedSpot(user?.id)) &&
      !hasScanned
    ) {
      return (
        <Button
          color="black"
          disabled={(isCreatedByMe || isAuthorizedUser) && isWeb}
          text={
            isCreatedByMe || isAuthorizedUser
              ? isWeb
                ? t("queue.scannerNotAvailable")
                : t("queue.openQrScanner")
              : t("queue.openQrTicket")
          }
          icon={
            <IonIcon
              icon={
                isCreatedByMe || isAuthorizedUser ? scanOutline : qrCodeOutline
              }
              color="white"
              className="ion-icon-event-override"
            />
          }
          onClick={() =>
            history.push(
              `/event/${event?.id}/${
                isCreatedByMe || isAuthorizedUser
                  ? t("queue.scan")
                  : t("queue.redeem")
              }/${activeQueue?.id}`
            )
          }
        />
      );
    }
    return null;
  };

  const renderSignInButton = () => {
    return (
      <Button
        color="black"
        text={t("queue.signInToReserveSpot")}
        onClick={() => history.push(`/login?redirect=${window.location.href}`)}
        icon={
          <IonIcon
            icon={bookmarkOutline}
            color="white"
            className="ion-icon-event-override"
          />
        }
      />
    );
  };

  const renderReserveButton = () => {
    /* If there are no spots available, if the user has scaned, or reserved a paid spot, don't show this button */
    if (
      (nextSpotAvailable <= 0 && !hasPurchasedSpot(user?.id)) ||
      hasScanned ||
      (hasPurchasedSpot(user?.id) &&
        spotPurchasedByUser?.stripe_amount_total > 0) ||
      isCreatedByMe ||
      isAuthorizedUser
    )
      return null;
    return (
      <Button
        color="black"
        text={
          hasPurchasedSpot(user?.id)
            ? t("queue.removeReservation")
            : `${t("queue.reserveSpot.segment1")} #${
                activeQueue?.num_spots - nextSpotAvailable + 1
              } ${t("queue.reserveSpot.segment2")} ${
                currentSpotPrice
                  ? `$${currentSpotPrice}`
                  : t("queue.reserveSpot.segment3")
              }`
        }
        onClick={reserveOrRemoveSpot}
        icon={
          <IonIcon
            icon={bookmarkOutline}
            color="white"
            className="ion-icon-event-override"
          />
        }
      />
    );
  };

  const renderCheckInConfirmation = () => {
    return (
      <TextCallout
        title={t("queue.alreadyCheckedInTitle")}
        description={`${t("queue.alreadyCheckedInDescription")} ${format(
          new Date(spotPurchasedByUser?.scans?.[0]?.updated_at),
          "EEE M/d·h:mm a"
        )}`}
        showBoxShadow
      />
    );
  };

  if (
    isEventLoading ||
    isAttendeesLoading ||
    !event?.id ||
    !isCurrentPageActive
  )
    return <Loader className="min-h-[200px]" />;

  return (
    <div className="grid gap-4">
      <EventCard
        line1={activeQueue?.description}
        line2={event?.title}
        company={event?.user?.company_name}
        date={
          activeQueue?.start_date_time &&
          format(new Date(activeQueue?.start_date_time), "EEE M/d·h:mm a")
        }
        address={{
          line1: event?.address_line_1,
          line2: event?.address_line_2,
          city_state_postal: `${event?.city}, ${event?.state} ${event?.postal_code}`,
          address_display_message: addressDisplay.displayText,
        }}
        showAddress={addressDisplay?.showAddress}
        spot={hasPurchasedSpot(user?.id) && getSpotInfo()}
        earned={
          isCreatedByMe || isAuthorizedUser
            ? `${
                totalStripeAmount
                  ? `$${convertStripePriceToDollars(totalStripeAmount)}`
                  : "$0"
              } ${t("queue.earnedCap")}`
            : ""
        }
      />

      {/* Buttons  */}
      {renderQRCodeButton()}

      {(nextSpotAvailable > 0 || (hasPurchasedSpot(user?.id) && !hasScanned)) &&
        !shouldHideButton() &&
        (user?.id ? renderReserveButton() : renderSignInButton())}

      {/* TODO: handle processing, requires_payment_method and !succeeded (but still secret exists) */}
      {clientSecret && redirectStatus?.length && (
        <TextCallout
          title={
            redirectStatus === "succeeded"
              ? t("queue.paymentSuccess")
              : t("queue.paymentCancelled")
          }
          description={
            redirectStatus === "succeeded"
              ? t("queue.tapToOpenTicket")
              : t("queue.tryAgain")
          }
          showBoxShadow
          className="rounded-lg"
          titleClassName={
            redirectStatus === "succeeded" ? "text-green-100" : "text-red-100"
          }
        />
      )}

      {hasScanned && renderCheckInConfirmation()}

      {(isCreatedByMe || isAuthorizedUser) && (
        <ListContainer
          title={t("queue.attendeesTitle")}
          type="CONTAINED"
          showBoxShadow
        >
          {spots?.map((spot: any, index: number) => (
            <ListItem
              key={index}
              line1={`${spot?.user?.first_name} ${spot?.user?.last_name}`}
              line2Items={[
                {
                  value: `${
                    spot?.stripe_amount_total
                      ? `$${convertStripePriceToDollars(
                          spot?.stripe_amount_total
                        )}`
                      : t("queue.freeCap")
                  }`,
                  color: "green",
                },
                {
                  value: spot?.user?.email,
                  color: "gray",
                },
              ]}
              displayBox={
                <ListDisplayBox
                  label={t("queue.spot")}
                  value={`${index + 1}`}
                />
              }
              icon={
                spot?.scans?.[0]?.updated_at && (
                  <IonIcon icon={checkmarkCircleOutline} />
                )
              }
              iconPlacement={"TOP"}
              onClick={() => {
                if (spot?.scans?.[0]?.updated_at?.length) {
                  presentAlert({
                    header: "Remove check-in record?",
                    message: `${`${spot?.user?.first_name} ${spot?.user?.last_name}`} checked in on ${format(
                      new Date(spot?.scans?.[0]?.updated_at),
                      "EEE M/d·h:mm a"
                    )}. Press OK to remove their check-in record.`,
                    buttons: [
                      { text: "Cancel", role: "cancel" },
                      {
                        text: "OK",
                        role: "confirm",
                        handler: () =>
                          removeUserCheckin(spot?.user?.id, spot?.id),
                      },
                    ],
                  });
                } else {
                  presentAlert({
                    header: "Check this user in?",
                    message: `Press OK to manually check ${`${spot?.user?.first_name} ${spot?.user?.last_name}`} in at spot #${
                      index + 1
                    }`,
                    buttons: [
                      { text: "Cancel", role: "cancel" },
                      {
                        text: "OK",
                        role: "confirm",
                        handler: () => checkUserIn(spot?.user?.id, spot?.id),
                      },
                    ],
                  });
                }
              }}
            />
          ))}
          {spots?.length === 0 && (
            <Text color="black" text="Nobody has checked in to your session." />
          )}
        </ListContainer>
      )}
      {!isCreatedByMe && !isAuthorizedUser && (
        <PricingChart queue={activeQueue} />
      )}

      {/* Payment Modal */}
      <IonModal
        isOpen={isPaymentModalOpen}
        initialBreakpoint={1}
        breakpoints={[0, 1]}
        onDidDismiss={() => setIsPaymentModalOpen(false)}
      >
        <div className="py-12 px-6 overflow-y-auto">
          <StripeContainer
            event={event}
            activeQueue={activeQueue}
            currentSpotPrice={currentSpotPrice}
            nextSpotAvailable={nextSpotAvailable}
            spotPurchasedByUser={spotPurchasedByUser}
            isWeb={isWeb}
            user={user}
            existingClientSecret={clientSecret}
            setIsPaymentModalOpen={setIsPaymentModalOpen}
          />
        </div>
      </IonModal>
    </div>
  );
};

export default Queue;
