import { format } from "path";
import { supabase } from "../supabaseClient";
import { formatPhoneNumber } from "../utils";

/*
    When creating an account, we want to do 3 things
    - Create the account
    - Save that account ID to our database
    - Create an account link 
*/

async function createAccount({ email, userId, accountInfo, companyInfo }: any) {
  /*
    TODO: swap this out with real auto-fill information once we stop testing
    */

  const { data: stripeAccountData, error: stripeAccountDataError } =
    await supabase.functions.invoke("stripe/create-account", {
      body: {
        email,
        // business_type: "individual",
        individual: {
          first_name: accountInfo?.first_name,
          last_name: accountInfo?.last_name,
          phone: formatPhoneNumber(accountInfo?.phone),
          // these are commented out to use later for dev purposes, these auto-validate with stripe
          // dob: {
          //   day: 1,
          //   month: 1,
          //   year: 1901,
          // },
          // ssn_last_4: "2222",
          address: {
            postal_code: accountInfo,
            // line_1: 'address_full_match'
          },
        },
        company: {
          name: companyInfo?.name,
          address: {
            city: companyInfo?.city,
            country: "US",
            line1: companyInfo?.address_line_1,
            line2: companyInfo?.address_line_2,
            postal_code: companyInfo?.postal_code,
            state: companyInfo?.state,
          },
          phone: formatPhoneNumber(accountInfo?.phone),
        },
      },
    });

  if (stripeAccountDataError || !stripeAccountData?.account?.id) {
    console.error("Error creating stripe account:", stripeAccountDataError);
    throw stripeAccountDataError;
  }

  const { data: accountData, error: accountDataError } = await supabase
    .from("user")
    .update({ stripe_connect_id: stripeAccountData?.account?.id })
    .eq("id", userId)
    .select("*");

  if (accountDataError) {
    console.error("Error updating account with stripe ID:", accountDataError);
    throw accountDataError;
  }

  const { data: stripeAccountLink, error: stripeAccountLinkError } =
    await supabase.functions.invoke("stripe/create-account-link", {
      body: { accountId: stripeAccountData?.account?.id },
    });

  if (stripeAccountLinkError) {
    console.error(
      "Error creating stripe account link:",
      stripeAccountLinkError
    );
    throw stripeAccountLinkError;
  }

  return {
    accountData,
    stripeAccountLink: stripeAccountLink,
  };
}

async function getAccountLink(accountId: string) {
  const { data, error } = await supabase.functions.invoke(
    "stripe/create-account-link",
    {
      body: { accountId },
    }
  );

  if (error) {
    console.error("Error creating stripe account link:", error);
    throw error;
  }

  return { stripeAccountLink: data };
}

async function getAccount(accountId: string) {
  const { data, error } = await supabase.functions.invoke(
    `stripe/get-account/${accountId}`,
    {
      method: "GET",
    }
  );

  if (error) {
    console.error("Error getting stripe account details:", error);
    throw error;
  }

  return data?.account;
}

async function generateConnectLoginLink(accountId: string) {
  const { data, error } = await supabase.functions.invoke(
    `stripe/generate-connect-login-link/${accountId}`,
    {
      method: "GET",
    }
  );

  if (error) {
    console.error("Error generating stripe connect login link:", error);
    throw error;
  }

  return data;
}

async function createCheckoutSession({
  connected_account_id,
  event_id,
  queue_id,
  price,
  metadata,
  spot_num,
  queue_metadata,
  event_metadata,
}: any) {
  const { data, error } = await supabase.functions.invoke(
    `stripe/create-checkout-session`,
    {
      body: {
        connected_account_id,
        event_id,
        queue_id,
        price,
        metadata,
        spot_num,
        queue_metadata,
        event_metadata,
      },
    }
  );

  if (error) {
    console.error("Error generating stripe checkout session: ", error);
    throw error;
  }

  return data;
}

async function confirmStripeSetup(accountId: string) {
  const { data, error } = await supabase
    .from("user")
    .update({
      stripe_connect_setup_complete: true,
    })
    .eq("stripe_connect_id", accountId);

  if (error) {
    console.error("Error updating account from webhook:", error);
  }
}

async function createPaymentIntent({
  connected_account_id,
  price,
  metadata,
  spot_num,
  event_metadata,
}: any) {
  const { data, error } = await supabase.functions.invoke(
    `stripe/create-payment-intent`,
    {
      body: {
        connected_account_id,
        event_metadata,
        spot_num,
        price,
        metadata,
      },
    }
  );

  if (error) {
    console.error("Error generating stripe payment intent: ", error);
    throw error;
  }

  return data;
}

export const stripeService = {
  createAccount,
  getAccount,
  getAccountLink,
  generateConnectLoginLink,
  createCheckoutSession,
  confirmStripeSetup,
  createPaymentIntent,
};
