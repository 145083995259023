import React, { useContext } from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import { AuthContext } from "./AuthContext";
import { Loader } from "./components/core/Loader";

const ProtectedRoute = ({ children, ...rest }: any) => {
  const { session, isLoading } = useContext(AuthContext);
  const location = useLocation();

  if (isLoading) return <Loader className="min-h-[200px]" />;

  if (session === null) return <Redirect to="/login" />;

  if (!session && session === null && location.pathname !== "/login") {
    return <Redirect to="/login" />;
  } else if (session && session !== null && location.pathname === "/login") {
    return <Redirect to="/" />;
  }

  return <Route {...rest}>{children}</Route>;
};

export default ProtectedRoute;
