import React, { createContext, useContext, useEffect, useState } from "react";
import { Device } from "@capacitor/device";
import en from "./translations/en";
import es from "./translations/es";

const TranslationContext = createContext({});

export const useTranslation = () => {
  const context = useContext(TranslationContext);
  if (!context) {
    throw new Error("useTranslation must be used within a TranslationProvider");
  }
  return context;
};

const languageMapping: any = {
  en: en,
  es: es,
};

export const TranslationProvider = ({ children }: any) => {
  const [languageCode, setLanguageCode] = useState("en");
  const [translations, setTranslations] = useState<any>(
    languageMapping[languageCode]
  );

  /* Check for device language and set our translations based on it */
  useEffect(() => {
    const setDeviceLanguage = async () => {
      const deviceLanguageCode = await Device.getLanguageCode();

      if (deviceLanguageCode?.value) {
        setTranslations(languageMapping[deviceLanguageCode?.value] || en);
        setLanguageCode(deviceLanguageCode?.value);
      }
    };

    setDeviceLanguage();
  }, []);

  /* Use this to easily access keys in the translation files */
  const t = (key: any) => {
    return key
      .split(".")
      .reduce((obj: any, k: any) => (obj || {})[k], translations);
  };

  return (
    <TranslationContext.Provider value={{ t, languageCode, setLanguageCode }}>
      {children}
    </TranslationContext.Provider>
  );
};
